import { save as actionSave } from './actions';
import _ from "lodash";
import { LOAD, SAVE } from './constants';

function swallow() {
}

function warnAboutConfusingFiltering(blacklist: any, whitelist: any) {
    blacklist
        .filter((item: any) => whitelist.indexOf(item) !== -1)
        .forEach((item: any) => {
            console.warn( // eslint-disable-line no-console
                `[redux-storage] Action ${item} is on BOTH black- and whitelist.`
                + ` This is most likely a mistake!`
            );
        });
}

function isValidAction(action: any) {
    const isFunc = _.isFunction(action);
    const isObj = _.isObject(action);
    const hasType = isObj && action.hasOwnProperty('type');

    if (!isFunc && isObj && hasType) {
        return true;
    }

    if (process.env.NODE_ENV !== 'production') {
        if (isFunc) {
            console.warn( // eslint-disable-line no-console
                `[redux-storage] ACTION IGNORED! Actions should be objects`
                + ` with a type property but received a function! Your`
                + ` function resolving middleware (e.g. redux-thunk) must be`
                + ` placed BEFORE redux-storage!`
            );
        } else if (!isObj) {
            console.warn( // eslint-disable-line no-console
                `[redux-storage] ACTION IGNORED! Actions should be objects`
                + ` with a type property but received: ${action}`
            );
        } else if (!hasType) {
            console.warn( // eslint-disable-line no-console
                `[redux-storage] ACTION IGNORED! Action objects should have`
                + ` a type property.`
            );
        }
    }

    return false;
}

function handleWhitelist(action: any, actionWhitelist: any) {
    if (Array.isArray(actionWhitelist)) {
        return actionWhitelist.length === 0
            ? true // Don't filter if the whitelist is empty
            : actionWhitelist.indexOf(action.type) !== -1;
    }

    // actionWhitelist is a function that returns true or false
    return actionWhitelist(action);
}

export default (engine: any, actionBlacklist: string[] = [], actionWhitelist: string[] = [], options = {}) => {
    const opts = Object.assign({ disableDispatchSaveAction: false }, options);

    // Also don't save if we process our own actions
    const blacklistedActions = [...actionBlacklist, LOAD, SAVE];

    if (process.env.NODE_ENV !== 'production' && Array.isArray(actionWhitelist)) {
        warnAboutConfusingFiltering(actionBlacklist, actionWhitelist);
    }

    return (store: any) => {
        return (next: any) => (action: any) => {
            const result = next(action);

            if (!isValidAction(action)) {
                return result;
            }
            const isOnBlacklist = blacklistedActions.find(r => action.type.endsWith(r)) ? true : false;
            const isOnWhitelist = handleWhitelist(action, actionWhitelist);

            // Skip blacklisted actions
            if (!isOnBlacklist && isOnWhitelist) {
                const saveState = store.getState();
                const saveAction = actionSave(saveState) as any;

                if (process.env.NODE_ENV !== 'production') {
                    if (!saveAction.meta) {
                        saveAction.meta = {};
                    }
                    saveAction.meta.origin = action;
                }

                const dispatchSave = () => store.dispatch(saveAction);
                engine.save(saveState)
                    .then(() => {
                        if (opts.disableDispatchSaveAction === false) {
                            return dispatchSave();
                        }
                    })
                    .catch(swallow);
            }

            return result;
        };
    };
};
