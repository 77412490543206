import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { useHistory } from "react-router-dom";
import * as actionTypes from "../store/actions/template"
import routes from "../routes"
import { authProvider } from "../common/auth/AuthProvider";

const HistoryListener = ({
    children,
    changeAppMode,
    appMode,
}) => {
    const [isFirstRender, setIsFirstRender] = useState(true);
    const history = useHistory();    

    // locations listener
    useEffect(() => {

        const setAppMode = (pathname) => {        
            let route = routes.find(r => r.path === pathname)
            if (route) {            
                // Set app mode only for staff login
                const isStaffUser = !!authProvider.getAccountInfo()?.staffMember;
                let routeAppMode = (route.appMode ?? false) && isStaffUser;
                if (appMode !== routeAppMode)
                    changeAppMode(routeAppMode)
            }
        }

        setAppMode(history.location.pathname)
        setIsFirstRender(false);

        return history.listen((location) => {
            setAppMode(location.pathname)
        });
    }, [history, changeAppMode, appMode]);

    // if is the first time render, show loading
    if (isFirstRender) {
        return <p>Loading...</p>;
    }

    return children;
};

const mapStateToProps = state => {
    return {
        appMode: state.template.appMode,
    }
};

const mapDispatchToProps = (dispatch) => ({
    changeAppMode: (value) => dispatch({ type: actionTypes.APP_MODE, value: value }),
});

export default connect(mapStateToProps, mapDispatchToProps)(HistoryListener);
