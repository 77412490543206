import { SerializedError } from '@reduxjs/toolkit'
import { RoomRef } from './common';

export enum ScheduleTransactionTypes {
    Arrival = "arrival",
    Discharge = "discharge",
    Transfer = "transfer",
}

export interface AssignmentRef {
    id: string;
    description: string;
    startDate: string;
    endDate: string;
}



export interface ScheduleTransaction {
    id: string, 
    type: string;
    date: string;
    resident: {
        id: string;
        name: string;
        residentId: string;
    };
    room?: RoomRef;
    dischargeRoom?: RoomRef;
    arrivalClean?: AssignmentRef;
    dischargeClean?: AssignmentRef;
}
export interface FacilitySchedule {
    id: string, 
    tenantId: string,
    transactions: ScheduleTransaction[];
    facility: {
        id: string,
        name: string
    };
}

export interface FacilityScheduleState {
    schedules: Array<FacilitySchedule>
    loading: 'idle' | 'pending' | 'succeeded' | 'failed'
    error: SerializedError | null
}
