import * as React from 'react';
import cn from 'classnames';
import {Spinner} from 'react-bootstrap';
import {useTranslation} from 'react-i18next';

import apiService from '../../common/api.service';
import log from '../../common/logging';
import ImageUtils from '../../utils/ImageUtils';
import './image.scss';
import { imageCache } from "../../common/dataCache";

interface ImageProps extends React.HTMLAttributes<HTMLImageElement> {
    dataUri?: string;
    isFullscreen?: boolean;
    srcFile?: string;
    hideBorder?: boolean;
}

export const Image = ({ dataUri, isFullscreen, srcFile, hideBorder, ...imgProps }: ImageProps) => {   

    const {t} = useTranslation();
    const [isLoading, setIsLoading] = React.useState(false);
    const [imageSrc, setImageSrc] = React.useState<string | undefined>();    

    React.useEffect(() => {
        setImageSrc(dataUri);
        if(!dataUri && !!srcFile){
            
            const request = `files/${srcFile}`;

            imageCache.getText(request)
                .then(r => {                    
                    if(!!r){
                        setIsLoading(false);
                        setImageSrc(r);
                    } else {
                        setIsLoading(true);
                        apiService.request<any, any>(request, 'GET', undefined, {responseType: 'arraybuffer'})
                            .then((r: any) => {                    
                                setIsLoading(false);
                                const parts = srcFile.split(".");
                                const dataUri = ImageUtils.binaryToDataUri(r.data, parts.length === 2 ? parts[1] : "jpeg");
                                setImageSrc(dataUri);                                
                                imageCache.add(request, dataUri);
                            })
                            .catch(e => {                    
                                setIsLoading(false);
                                log.error("Couldn't load photo", srcFile, e)
                            })
                    }
                });
        }
        
    }, [srcFile, dataUri]);

    const image = 
        (<>
            {!!imageSrc &&<img className="image-max" alt="" src={imageSrc} {...imgProps}  />}
            {!imageSrc && !srcFile && !isLoading &&<p className="text-muted my-3">{t('No Photo')}</p>}
            {isLoading && <Spinner animation="border" variant="primary" />}
        </>);

    return (
        !hideBorder ? <div className={cn('image-preview border d-flex align-items-center justify-content-center', {'image-preview-fullscreen': isFullscreen})}>
            {image}
        </div>
        : image
    );
};

export default Image;
