import { v4 as uuidv4 } from "uuid";

class TracingContext {
    private TracingId: String | null;
    private User: String | null;
    private readonly TRACING_ID_KEY: string = "tracing_id";

    constructor() {
        this.TracingId = window.sessionStorage.getItem(this.TRACING_ID_KEY);
    }

    private generateTracingId(): String {
        return uuidv4().split("-").join("");
    }

    private generateParentId(): String {
        return this.generateTracingId().substring(0, 16);
    }

    public startNewSession(user: string): void {
        this.TracingId = this.generateTracingId();
        this.User = user;
        window.sessionStorage.setItem(this.TRACING_ID_KEY, this.TracingId as any);
    }

    public getTracingHeaders(): any {
        const state = `SessionId=${this.TracingId},User=${this.User}`;
        return {
            "traceparent": `00-${this.TracingId}-${this.generateParentId()}-01`,
            "tracestate": state,
            // Accessed by Activity.Baggage in .net core
            "Correlation-Context": state

        }
    }
}

const tracing = new TracingContext();

export default tracing;
