import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'
import { AxiosResponse } from 'axios';
import apiService from '../../../common/api.service';
import * as dom from "../../models/domain";

const initialState: dom.SyncState = {
    dataSources: [],
    loading: 'idle',
    error: null
}


export const loadDataSources = createAsyncThunk(
    'sync/loadDataSources',
    async () => {
        const response = await apiService.get('/sync/datasources') as AxiosResponse<dom.SyncDataSource[]>
        if (response) {
            return response.data
        } else {
            throw new Error('unknown error loading sync data source');
        }
    }
)

export const saveFacilityDataSource = createAsyncThunk(
    'sync/saveFacilityDataSource',
    async (data: dom.SaveFacilityDataSource) => {
        const response = await apiService.post<dom.SaveFacilityDataSource>('/sync/facilitydatasource', data) as AxiosResponse<dom.SyncDataSource>
        if (response) {
            return response.data
        } else {
            throw new Error('unknown error saving facility sync data source');
        }
    }
)

export const deleteFacilityDataSource = createAsyncThunk(
    'sync/deleteFacilityDataSource',
    async (id: string) => {
        await apiService.delete(`/sync/facilitydatasource/${id}`, {});
    }
)

const sliceReducer = createSlice({
    name: 'sync',
    initialState,
    reducers: {
    },
    extraReducers: builder => {

        // load
        builder.addCase(loadDataSources.fulfilled, (state, action) => {
            return {
                ...state,
                dataSources: action.payload || {},
                loading: 'succeeded',
                error: null,
            }
        })
        builder.addCase(loadDataSources.rejected, (state, action) => {
            return { ...state, loading: 'failed', error: action.error }
        }),
        
        // save
        builder.addCase(saveFacilityDataSource.fulfilled, (state, action) => {
            return {
                ...state,
                dataSources: [...state.dataSources.filter(ds => ds.id !== action.payload.id), action.payload]
            }
        })
        builder.addCase(saveFacilityDataSource.rejected, (state, action) => {
            return { ...state, loading: 'failed', error: action.error }
        })   
        
        // delete
        builder.addCase(deleteFacilityDataSource.fulfilled, (state, action) => {
            return {
                ...state,
                dataSources: [...state.dataSources.filter(ds => ds.id !== action.meta.arg)]
            }
        })
        builder.addCase(deleteFacilityDataSource.rejected, (state, action) => {
            return { ...state, loading: 'failed', error: action.error }
        })   
    }
}).reducer
export default sliceReducer

//https://redux-toolkit.js.org/usage/usage-with-typescript/
