import * as React from 'react';
import { withRouter } from 'react-router-dom';
import { withTranslation, WithTranslation } from 'react-i18next';
import { connect, ConnectedProps } from 'react-redux';
import _ from 'lodash';
import './housekeeping.scss'
import { HousekeepingState, FacilityState, FacilityData, TenantState, TenantData, StaffScheduleState } from '../../../store/models/domain'
import { authProvider } from '../../../common/auth/AuthProvider'
import * as hkReducer from './reducer'
import Dashboard from './Dashboard'
import CheckList from "./CheckList"


type StoreState = {
    housekeeping: HousekeepingState
    facilities: FacilityState
    tenants: TenantState
    staffSchedules: StaffScheduleState
}

const emptyFacility: FacilityData = { id: '', name: '', }
const emptyTenant: TenantData = { tenant: '', name: '', }

const mapState = (state: StoreState) => ({
        hkConfig: state.housekeeping.config,
        facility: state.facilities.facilities.find(r => r.id === authProvider.getAccountInfo()?.facility.id) ?? emptyFacility,
        hkReports: _.sortBy(Object.values(state.housekeeping.reports).filter(r => r.facility?.id === authProvider.getAccountInfo()?.facility.id), (r) => r.reportDate),
        markingReports: _.sortBy(state.housekeeping.auditMarkingReports.filter(r => r.facility?.id === authProvider.getAccountInfo()?.facility.id), (r) => r.reportDate),
        tenant: state.tenants.records.find(r => r.tenant === authProvider.getAccountInfo()?.tenantId) ?? emptyTenant,
        staffSchedules: state.staffSchedules.schedules.filter(r => r.facility?.id === authProvider.getAccountInfo()?.facility?.id && 
            r.staffMember?.staffRefId === authProvider.getAccountInfo()?.staffMember?.staffRefId) ?? [],
        cleans: state.housekeeping.cleans.filter(c => c.facility?.id === authProvider.getAccountInfo()?.facility.id)        
    });

const mapDispatch = {
}

interface Props extends PropsFromRedux, WithTranslation {
    history: any,
    match: any
}

const HousekeepingApp = (props: Props) => {

    const {
        //Translation
        t,
        i18n
    } = props;     

    const [state, dispatch] = React.useReducer(hkReducer.default, hkReducer.initialState)      

    React.useEffect(() => {
        
        // Start SignalR watcher
        dispatch(hkReducer.startWatchUpdate(dispatch));

        return () => {
            dispatch(hkReducer.stopWatchUpdate())
        }
    }, [props.facility, props.tenant])

    React.useEffect(() => {
        //Initial load data
        dispatch(hkReducer.loadData(props.facility, props.hkConfig, props.tenant, props.staffSchedules, props.hkReports, props.cleans, props.markingReports))        
    }, [props.facility, props.hkConfig, props.staffSchedules, props.hkReports, props.cleans, props.tenant, props.markingReports])

    return (
        <div className="app">
            {state.cleaningRecord
                ? <CheckList state={state} dispatch={dispatch} t={t} i18n={i18n} />
                : <Dashboard state={state} dispatch={dispatch} t={t} />
            }
        </div>
    );
}

const connector = connect(mapState, mapDispatch)
const translate = withTranslation()

type PropsFromRedux = ConnectedProps<typeof connector>
export default withRouter(translate(connector(HousekeepingApp)));
