import _ from "lodash";
import moment from "moment";

import { HKRoomType, StaffSchedule } from '../store/models/domain'

abstract class AssignmentService {  

    public static findAssignments(staffSchedules: StaffSchedule[], roomTypes: HKRoomType[], 
        cleanTags?: string[], roomNumber?: string, start?: Date, end?: Date) {

        const startDate = !start ? moment().add(-10, 'year') : moment(start);
        const endDate = !end ? moment().add(10, 'year') : moment(end);

        const cleanTypesWithTag = !!cleanTags && cleanTags.length 
            ? _.flatten(roomTypes.map(t => t.routines.map(r => ({...r, roomType: t.roomType})))).filter(r => cleanTags.some(t => (r.tags || []).includes(t)))
            : undefined;

        let assignments = _.flatten(staffSchedules.map(s => s.assignments))
            .filter(s => !roomNumber || (s.roomNumbers || []).includes(roomNumber))
            .filter(s => (startDate <= moment(s.startDate) && moment(s.startDate) <= endDate) ||
                        (startDate <= moment(s.endDate) && moment(s.endDate) <= endDate))
            .filter(s => !cleanTypesWithTag || cleanTypesWithTag.some(c => c.roomType === s.roomType && c.cleanType === s.cleanType));

        return assignments;        
    } 
    
    public static filterAssignmentsByDate(staffSchedules: StaffSchedule[], date: moment.Moment) {
        
        const assignments = _.sortBy(_.flatten(staffSchedules.map(s => s.assignments ?? []))
            .filter(a => moment(a.startDate) <= date && date <= moment(a.endDate).endOf('day')), (a) => a.startDate);

        return assignments;        
    }
}

export default AssignmentService;
