import moment from "moment";

import { ScheduleInfo } from './ScheduleInfo'
import { HKAbsoluteSchedule, HKSchedulePeriodType, HKScheduleType } from '../../store/models/domain';
import i18n from '../../locales/i18n';

interface TimeBucket {
    start: moment.Moment,
    end: moment.Moment,
    dueTime: moment.Moment,
}

export abstract class AbsoluteScheduleCalculator {

    public static calculateSchedule(schedule: HKAbsoluteSchedule, dateTime: moment.Moment, calcPrevBucket: boolean = false) : ScheduleInfo | undefined {     
        const startDate = moment(schedule.startDate);
        
        // Schedule has not started yet
        if(dateTime < startDate)
            return undefined;
        
        // Calc period duration
        let periodSeconds = AbsoluteScheduleCalculator.calculatePeriodDuration(schedule.periodType, schedule.periodCount, startDate, dateTime);
        
        // Find current period on the date date        
        const duration = moment.duration(moment(dateTime).diff(startDate));
        const periodsBetween = Math.floor(duration.asSeconds()/periodSeconds);
        let curPeriodStart = moment(startDate).add(periodsBetween*periodSeconds, 'seconds');
        let curPeriodEnd = moment(curPeriodStart).add(1*periodSeconds, 'seconds');
        if(schedule.periodType === HKSchedulePeriodType.Monthly) {
            curPeriodStart = moment(startDate).add(periodsBetween*schedule.periodCount, 'months');
            curPeriodEnd = moment(curPeriodStart).add(1*schedule.periodCount, 'months');
        } 
        else if(schedule.periodType === HKSchedulePeriodType.Yearly) {
            curPeriodStart = moment(startDate).add(periodsBetween*schedule.periodCount, 'year');
            curPeriodEnd = moment(curPeriodStart).add(1*schedule.periodCount, 'year');
        }

        // Search bucket on dateTime
        let prevPrevBucket: TimeBucket | null = null;
        let prevBucket: TimeBucket | null = null;
        let activeBucket: TimeBucket | null = null;
        for(const bucketDef of schedule.timeBuckets) {
            const curBucket: TimeBucket = {
                start: moment(curPeriodStart).add(bucketDef.startSeconds, 'seconds'),
                end: moment(curPeriodStart).add(bucketDef.endSeconds, 'seconds'),
                dueTime: moment(curPeriodStart).add(bucketDef.dueSoonSeconds, 'seconds'),
            }

            if(curBucket.start <= dateTime && dateTime <= curBucket.end) {
                activeBucket = curBucket;
                break;
            } 
            else if (dateTime < curBucket.start) {
                // dateTime is between buckets return prevBucket
                activeBucket = prevBucket;
                break;
            }

            prevPrevBucket = prevBucket;
            prevBucket = curBucket;
        }

        if(!activeBucket && !!prevBucket) {
            activeBucket = prevBucket;
            prevBucket = prevPrevBucket;
        }

        if(!prevBucket && !calcPrevBucket) {
           
            const prevPeriodSchedule = AbsoluteScheduleCalculator.calculateSchedule(schedule, moment(curPeriodStart).subtract(1, 'seconds'), true);
            if(!!prevPeriodSchedule && !!prevPeriodSchedule.scheduleBucket) {
                prevBucket = {
                    start: prevPeriodSchedule.scheduleBucket.start,
                    end: prevPeriodSchedule.scheduleBucket.end,
                    dueTime: prevPeriodSchedule.scheduleBucket.dueTime!
                };
            }
        }

        return !!activeBucket
        ? {
            prevScheduleBucket: !!prevBucket && prevBucket.end > startDate
                ? {
                    start: prevBucket.start,
                    end: prevBucket.end,
                    dueTime: prevBucket.dueTime
                }
                : undefined,

            scheduleBucket: {
                start: activeBucket.start,
                end: activeBucket.end,
                dueTime: activeBucket.dueTime
            },
            scheduleType: HKScheduleType.Absolute,
            periodStart: curPeriodStart,
            periodEnd: curPeriodEnd
        }
        : undefined;
    }

    public static calculatePeriodDuration(periodType: HKSchedulePeriodType, periodCount: number, startDate: moment.Moment, dateTime: moment.Moment) {
        let periodSeconds;
        let periodsBetween;
        let curPeriodStart;
        let curPeriodEnd;
        switch (periodType) {
            case HKSchedulePeriodType.Monthly:          
                // Find period on the dateTime and calc duration as end-start 
                // This is because of months have different count of days
                periodSeconds = moment.duration(periodCount * 30, 'days').asSeconds();
                periodsBetween = Math.floor(moment.duration(moment(dateTime).diff(startDate)).asSeconds()/periodSeconds);
                curPeriodStart = moment(startDate).add(periodsBetween*periodCount, 'months');
                curPeriodEnd = moment(curPeriodStart).add(periodCount*1, "month");
                periodSeconds = moment.duration(moment(curPeriodEnd).diff(curPeriodStart)).asSeconds();
                break;
            case HKSchedulePeriodType.Weekly:
                periodSeconds = moment.duration(periodCount * 7, 'days').asSeconds();
                break;
            case HKSchedulePeriodType.Yearly:
                // Find period on the dateTime and calc duration as end-start 
                // This is because of years have different count of days
                periodSeconds = moment.duration(periodCount * 365, 'days').asSeconds();
                periodsBetween = Math.floor(moment.duration(moment(dateTime).diff(startDate)).asSeconds()/periodSeconds);
                curPeriodStart = moment(startDate).add(periodsBetween*periodCount, 'years');
                curPeriodEnd = moment(curPeriodStart).add(periodCount*1, "years");
                periodSeconds = moment.duration(moment(curPeriodEnd).diff(curPeriodStart)).asSeconds();
                break;
            default:
                periodSeconds = moment.duration(periodCount, 'days').asSeconds();
                break;
        }
        return periodSeconds;
    }

    static getScheduleDescription(absoluteSchedule: HKAbsoluteSchedule): string | undefined {
        return `${i18n.t('Start on')} ${moment(absoluteSchedule.startDate).format('MM/DD/YYYY')} ` +
            (absoluteSchedule.periodCount > 1 
            ? `${i18n.t('repeat each')} ${absoluteSchedule} ${i18n.t(this.getPeriodNamePlural(absoluteSchedule.periodType))},`       
            : `${i18n.t('repeat ')} ${i18n.t(absoluteSchedule.periodType.toString())},`) + 
            ` ${absoluteSchedule.timeBuckets.length} ${i18n.t('Time buckets')}.`
    }  

    private static getPeriodNamePlural(periodType: HKSchedulePeriodType) {        
        switch (periodType) {
            case HKSchedulePeriodType.Monthly:
                return 'months';
            case HKSchedulePeriodType.Weekly:
                return "weeks";
            case HKSchedulePeriodType.Yearly:
                return "years";
            default:
                return "days"
        }
    }
}