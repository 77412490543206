import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'
import { AxiosResponse } from 'axios';
import apiService from '../../../common/api.service';
import * as dom from "../../models/domain";

const initialState: dom.TenantState = {
    records: [],
    systemUsers: [],
    loading: 'idle',
    error: null
}

const updateLogo = async (photoUri?: string, photo?: dom.PhotoInfo) => {
    if(!!photo?.dataUri) {
        await apiService.uploadPhoto(photoUri!, photo?.dataUri, "300x300");

    } else if(!!photo?.photoToDelete) {                            
        await apiService.deletePhoto(photo?.photoToDelete);
    }
}

export const addTenant = createAsyncThunk(
    'tenants/addTenant',
    // if you type your function argument here
    async (data: dom.CreateTenantData) => {
        try {
            await updateLogo(data.data.companyLogo, data.photo);
            data.photo = undefined;

            const response = await apiService.post<dom.CreateTenantData>('/tenant/create', data) as AxiosResponse<dom.TenantData>
            if (response) {
                return response.data
            } else {
                throw new Error('unknown error creating tenant');
            }
        } catch (error) {
            if (error.response?.data?.errors) throw new Error(error.response?.data?.errors)
            else throw error
        }
    }
)

export const updateTenant = createAsyncThunk(
    'tenants/updateTenant',
    // if you type your function argument here
    async (data: dom.UpdateTenantData) => {

        await updateLogo(data.data.companyLogo, data.photo);
        data.photo = undefined;

        const response = await apiService.post<dom.UpdateTenantData>('/tenant/update', data) as AxiosResponse<dom.TenantData>
        if (response) {
            return response.data
        } else {
            throw new Error('unknown error updating tenant');
        }
    }
)

export const loadTenants = createAsyncThunk(
    'tenants/loadTenants',
    async () => {
        const response = await apiService.get('/tenant?') as AxiosResponse<dom.TenantData[]>
        if (response) {
            return response.data
        } else {
            throw new Error('unknown error loading tenants');
        }
    }
)

export const saveStaff = createAsyncThunk(
    'tenants/saveStaff',
    // if you type your function argument here
    async (data: dom.SaveStaffRequest) => {
        const response = await apiService.post<dom.SaveStaffRequest>('/staff', data) as AxiosResponse<dom.StaffMember>
        if (response) {
            return response.data
        } else {
            throw new Error('unknown error saving staff');
        }
    }
)

export const saveAcknowledgements = createAsyncThunk(
    'tenants/saveAcknowledgements',
    // if you type your function argument here
    async (data: dom.SaveAcknowledgements) => {
        const response = await apiService.post<dom.SaveAcknowledgements>('/tenant/saveacknowledgements', data) as AxiosResponse<dom.Acknowledgement[]>
        if (response) {
            return response.data
        } else {
            throw new Error('unknown error saving tenant acknowledgements');
        }
    }
)

export const saveOrganizationAcknowledgements = createAsyncThunk(
    'tenants/saveOrganizationAcknowledgements',
    // if you type your function argument here
    async (data: dom.SaveOrganizationAcknowledgements) => {
        const response = await apiService.post<dom.SaveOrganizationAcknowledgements>('/tenant/saveaorganizationcknowledgements', data) as AxiosResponse<dom.Acknowledgement[]>
        if (response) {
            return response.data
        } else {
            throw new Error('unknown error saving tenant acknowledgements');
        }
    }
)

export const saveFacilityAcknowledgements = createAsyncThunk(
    'tenants/saveFacilityAcknowledgements',
    // if you type your function argument here
    async (data: dom.SaveFacilityAcknowledgements) => {
        const response = await apiService.post<dom.SaveFacilityAcknowledgements>('/tenant/savefacilityacknowledgements', data) as AxiosResponse<dom.Acknowledgement[]>
        if (response) {
            return response.data
        } else {
            throw new Error('unknown error saving facility acknowledgements');
        }
    }
)

export const loadSystemUsers = createAsyncThunk(
    'tenants/loadSystemUsers',
    async () => {
        const response = await apiService.get('/systemusers') as AxiosResponse<dom.SystemUser[]>        
        if (response) {
            return response.data
        } else {
            throw new Error('unknown error loading system users');
        }
    }
)

export const saveSystemUser = createAsyncThunk(
    'tenants/saveSystemUser',
    // if you type your function argument here
    async (data: dom.SaveSystemUser) => {
        const response = await apiService.post<dom.SaveSystemUser>('/systemusers', data) as AxiosResponse<dom.SystemUser>
        if (response) {
            return response.data
        } else {
            throw new Error('unknown error saving system user');
        }
    }
)

export const deleteSystemUser = createAsyncThunk(
    'tenants/deleteSystemUser',
    // if you type your function argument here
    async (data: dom.DeleteSystemUser) => {
        const response = await apiService.delete<dom.DeleteSystemUser>('/systemusers', data) as AxiosResponse<any>
        if (response) {
            return response.data
        } else {
            throw new Error('unknown error deleting system user');
        }
    }
)

const sliceReducer = createSlice({
    name: 'tenants',
    initialState,
    reducers: {
    },
    extraReducers: builder => {
        // add
        builder.addCase(addTenant.fulfilled, (state, action) => {
            return {
                ...state,
                records: [...state.records, action.payload],
                loading: 'succeeded',
                error: null,
            }
        })
        builder.addCase(addTenant.rejected, (state, action) => {
            return { ...state, loading: 'failed', error: action.error }
        })

        // update
        builder.addCase(updateTenant.fulfilled, (state, action) => {
            return {
                ...state,
                records: state.records.map(t => t.tenant === action.payload.tenant ? action.payload : t),
                loading: 'succeeded',
                error: null,
            }
        })
        builder.addCase(updateTenant.rejected, (state, action) => {
            return { ...state, loading: 'failed', error: action.error }
        })

        // load
        builder.addCase(loadTenants.fulfilled, (state, action) => {
            return {
                ...state,
                records: action.payload,
                loading: 'succeeded',
                error: null,
            }
        })
        builder.addCase(loadTenants.rejected, (state, action) => {
            return { ...state, loading: 'failed', error: action.error }
        })

        // Save staff
        builder.addCase(saveStaff.fulfilled, (state, action) => {
            return {
                ...state,
                records: state.records.map(t => t.tenant !== action.meta.arg.data.tenantId 
                    ?  t 
                    :  {...t, staffMembers: [...t.staffMembers!.filter(s => s.id !== action.payload.id), action.payload]}),
                loading: 'succeeded',
                error: null,
            }
        })
        builder.addCase(saveStaff.rejected, (state, action) => {
            return { ...state, loading: 'failed', error: action.error }
        })

        // Save tenant acknowledgements
        builder.addCase(saveAcknowledgements.fulfilled, (state, action) => {
            return {
                ...state,
                records: state.records.map(t => t.tenant !== action.meta.arg.data.tenantId 
                    ?  t 
                    :  {...t, staffAcknowledgements: action.payload }),
                loading: 'succeeded',
                error: null,
            }
        })
        builder.addCase(saveAcknowledgements.rejected, (state, action) => {
            return { ...state, loading: 'failed', error: action.error }
        })

        // Save organization acknowledgements
        builder.addCase(saveOrganizationAcknowledgements.fulfilled, (state, action) => {
            return {
                ...state,
                records: state.records.map(t => t.tenant !== action.meta.arg.data.tenantId 
                    ?  t 
                    :  {...t, staffAcknowledgements: action.payload }),
                loading: 'succeeded',
                error: null,
            }
        })
        builder.addCase(saveOrganizationAcknowledgements.rejected, (state, action) => {
            return { ...state, loading: 'failed', error: action.error }
        })

        // Save facility acknowledgements
        builder.addCase(saveFacilityAcknowledgements.fulfilled, (state, action) => {
            return {
                ...state,
                records: state.records.map(t => t.tenant !== action.meta.arg.data.tenantId 
                    ?  t 
                    :  {...t, staffAcknowledgements: action.payload}),
                loading: 'succeeded',
                error: null,
            }
        })
        builder.addCase(saveFacilityAcknowledgements.rejected, (state, action) => {
            return { ...state, loading: 'failed', error: action.error }
        })

        // load systemUsers
        builder.addCase(loadSystemUsers.fulfilled, (state, action) => {
            return {
                ...state,
                systemUsers: action.payload,
                loading: 'succeeded',
                error: null,
            }
        })
        builder.addCase(loadSystemUsers.rejected, (state, action) => {
            return { ...state, loading: 'failed', error: action.error }
        })
        
        // Save systemUser
        builder.addCase(saveSystemUser.fulfilled, (state, action) => {            
            return {
                ...state,
                systemUsers: 
                    [...state.systemUsers.filter(t => t.email !== action.meta.arg.data.systemUser.email),
                        action.payload],
                loading: 'succeeded',
                error: null,
            }
        })
        builder.addCase(saveSystemUser.rejected, (state, action) => {
            return { ...state, loading: 'failed', error: action.error }
        })   
        
        // Save systemUser
        builder.addCase(deleteSystemUser.fulfilled, (state, action) => {
            return {
                ...state,
                systemUsers: state.systemUsers.filter(t => t.email !== action.meta.arg.data.systemUser.email),
                loading: 'succeeded',
                error: null,
            }
        })
        builder.addCase(deleteSystemUser.rejected, (state, action) => {
            return { ...state, loading: 'failed', error: action.error }
        })    
        
    }
}).reducer
export default sliceReducer

//https://redux-toolkit.js.org/usage/usage-with-typescript/
