import { applyMiddleware, StoreEnhancerStoreCreator } from 'redux';
import type { Config } from './types';
import { createOfflineMiddleware } from './middleware';
import { enhanceReducer } from './updater';
import { applyDefaults } from './config';
import { networkStatusChanged } from './actions';
import offlineActionTracker from './offlineActionTracker';

const warnIfNotReduxAction = (config: Partial<Config>, key: string) => {
  const maybeAction = config[key as keyof Config] as any;

  const isNotReduxAction =
    maybeAction === null ||
    typeof maybeAction !== 'object' ||
    typeof maybeAction.type !== 'string' ||
    maybeAction.type === '';

  if (isNotReduxAction && console.warn) {
    const msg =
      `${key} must be a proper redux action, ` +
      `i.e. it must be an object and have a non-empty string type. ` +
      `Instead you provided: ${JSON.stringify(maybeAction, null, 2)}`;
    console.warn(msg);
  }
};

export const offline = (userConfig: Partial<Config> = {}) => (
  createStore: any
) => (reducer: any, preloadedState: any, enhancer: any = (x: any) => x) => {
  const config = applyDefaults(userConfig);

  warnIfNotReduxAction(config, 'defaultCommit');
  warnIfNotReduxAction(config, 'defaultRollback');

  // toggle experimental returned promises
  config.offlineActionTracker = config.returnPromises
    ? offlineActionTracker.withPromises
    : offlineActionTracker.withoutPromises;
  delete config.returnPromises;

  // wraps userland reducer with a top-level
  // reducer that handles offline state updating
  const offlineReducer = enhanceReducer(reducer, config);


  const offlineEnhancer = applyMiddleware(createOfflineMiddleware(config));

  // create store
  // TODO: need to review this as it sais can take only 2 parameters
  const store = (offlineEnhancer(createStore) as any)(
    offlineReducer,
    preloadedState,
    enhancer
  );

  const baseReplaceReducer = store.replaceReducer.bind(store);
  store.replaceReducer = function replaceReducer(nextReducer: any) {
    return baseReplaceReducer(enhanceReducer(nextReducer, config));
  };


  // launch network detector
  if (config.detectNetwork) {
    config.detectNetwork(online => {
      store.dispatch(networkStatusChanged(online));
    });
  }

  return store;
};

export const createOffline = (userConfig: Partial<Config> = {}) => {
  const config = applyDefaults(userConfig);

  // toggle experimental returned promises
  config.offlineActionTracker = config.returnPromises
    ? offlineActionTracker.withPromises
    : offlineActionTracker.withoutPromises;
  delete config.returnPromises;

  warnIfNotReduxAction(config, 'defaultCommit');
  warnIfNotReduxAction(config, 'defaultRollback');

  const enhanceStore = (next: StoreEnhancerStoreCreator) => (
    reducer: any,
    preloadedState: any,
    enhancer: any
  ) => {    
    const createStore = next;

    // create store
    const store = createStore(reducer, preloadedState)//, enhancer);

    const baseReplaceReducer = store.replaceReducer.bind(store);
    store.replaceReducer = function replaceReducer(nextReducer: any) {
      return baseReplaceReducer(enhanceReducer(nextReducer, config));
    };

    // launch network detector
    if (config.detectNetwork) {
      config.detectNetwork(online => {
        store.dispatch(networkStatusChanged(online));
      });
    }

    return store;
  };

  return {
    middleware: createOfflineMiddleware(config),
    enhanceReducer(reducer: any) {
      return enhanceReducer(reducer, config);
    },
    enhanceStore
  };
};
