interface Dictionary {
  [Key: number]: any;
}

const subscriptions = {} as Dictionary;

function registerAction(transaction: number) {
  return new Promise<void>((resolve, reject) => {
    subscriptions[transaction] = { resolve, reject };
  });
}

function resolveAction(transaction: number, value: any) {
  const subscription = subscriptions[transaction];
  if (subscription) {
    subscription.resolve(value);
    delete subscriptions[transaction];
  }
}

function rejectAction(transaction: number, error: Error) {
  const subscription = subscriptions[transaction];
  if (subscription) {
    subscription.reject(error);
    delete subscriptions[transaction];
  }
}

const withPromises = {
  registerAction,
  resolveAction,
  rejectAction
};

const withoutPromises = {
  registerAction() {},
  resolveAction() {},
  rejectAction() {}
};

export default {
  withPromises,
  withoutPromises
};
