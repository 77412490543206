import {
  OFFLINE_STATUS_CHANGED,
  OFFLINE_SCHEDULE_RETRY,
  OFFLINE_COMPLETE_RETRY,
  OFFLINE_BUSY,
  OFFLINE_ENQUEUE
} from './constants';
import {OfflineMetadata, EnqueueOfflineAction} from './types'

export const networkStatusChanged = (online: boolean) => {
  return {
    type: OFFLINE_STATUS_CHANGED,
    payload: { online }
  };
};

export const scheduleRetry = (delay = 0) => ({
  type: OFFLINE_SCHEDULE_RETRY,
  payload: {
    delay
  }
});

export const completeRetry = (action: any) => ({
  type: OFFLINE_COMPLETE_RETRY,
  payload: action
});

export const busy = (isBusy: boolean) => ({
  type: OFFLINE_BUSY,
  payload: { busy: isBusy },
});

export const enqueuOfflineAction = (description: string, offline: OfflineMetadata): EnqueueOfflineAction => ({
  type: OFFLINE_ENQUEUE,
  description: description,
  meta: {
    offline
  }
});
