import React from 'react';

import { IAccountInfo } from "./IAccountInfo";

type AuthContextProps = {
    isAuthenticated: boolean,
    isRegisteredDevice: boolean,
    account: IAccountInfo,
};

export const AuthContext = React.createContext<Partial<AuthContextProps>>({});

export function useAuth() {
    return React.useContext(AuthContext);
}

