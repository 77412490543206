import detectNetwork from './detectNetwork';
import effect from './effect';
import retry from './retry';
import discard from './discard';
import defaultCommit from './defaultCommit';
import defaultRollback from './defaultRollback';
import offlineStateLens from './offlineStateLens';
import queue from './queue';
import {Config} from '../types';

export default {
  detectNetwork,
  effect,
  retry,
  discard,
  defaultCommit,
  defaultRollback,
  offlineStateLens,
  queue,
  returnPromises: false
} as Config;
