import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'
import { AxiosResponse } from 'axios';
import apiService from '../../../common/api.service';
import * as dom from "../../models/domain";

const initialState: dom.CHSSystemState = {
    record: {},
    loading: 'idle',
    error: null
}

export const updateSystemAcknowledgements = createAsyncThunk(
    'chsSystem/updateAcknowledgements',
    // if you type your function argument here
    async (data: dom.UpdateSystemAcknowledgement) => {
        try {           

            const response = await apiService.post<dom.UpdateSystemAcknowledgement>('/chssystem/updateacknowledgements', data) as AxiosResponse<dom.CHSSystem>
            if (response) {
                return response.data
            } else {
                throw new Error('unknown error updating system acknowledgements');
            }
        } catch (error) {
            if (error.response?.data?.errors) throw new Error(error.response?.data?.errors)
            else throw error
        }
    }
)


export const loadCHSSystem = createAsyncThunk(
    'chsSystem/loadCHSSystem',
    async () => {
        const response = await apiService.get('/chssystem') as AxiosResponse<dom.CHSSystem>
        if (response) {
            return response.data
        } else {
            throw new Error('unknown error loading CHSSystem');
        }
    }
)

const sliceReducer = createSlice({
    name: 'chsSystem',
    initialState,
    reducers: {
    },
    extraReducers: builder => {

        // update Acknowledgements 
        builder.addCase(updateSystemAcknowledgements.fulfilled, (state, action) => {
            return {
                ...state,
                record: action.payload,
                loading: 'succeeded',
                error: null,
            }
        })
        builder.addCase(updateSystemAcknowledgements.rejected, (state, action) => {
            return { ...state, loading: 'failed', error: action.error }
        })

        // load
        builder.addCase(loadCHSSystem.fulfilled, (state, action) => {
            return {
                ...state,
                record: action.payload || {},
                loading: 'succeeded',
                error: null,
            }
        })
        builder.addCase(loadCHSSystem.rejected, (state, action) => {
            return { ...state, loading: 'failed', error: action.error }
        })     
    }
}).reducer
export default sliceReducer

//https://redux-toolkit.js.org/usage/usage-with-typescript/
