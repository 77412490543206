import React from 'react';
import ComponentLoader from './App/components/ComponentLoader'

const SignIn = React.lazy(() => ComponentLoader(() => import('./App/pages/SignIn')));
const ResetPassword = React.lazy(() => ComponentLoader(() => import('./App/pages/ResetPassword')));
const ResetPasswordConfirm = React.lazy(() => ComponentLoader(() => import('./App/pages/ResetPasswordConfirm')));
const PinSignIn = React.lazy(() => ComponentLoader(() => import('./App/pages/RegisteredSignIn')));

const route = [
    { path: '/login', exact: true, name: 'Login', component: SignIn },
    { path: '/reset-password', exact: true, name: 'Reset Password', component: ResetPassword },
    { path: '/confirm-password/:hash', name: 'Confirm Reset Password', component: ResetPasswordConfirm },
    { path: '/confirm-password', exact: true, name: 'Confirm Reset Password', component: ResetPasswordConfirm },

    //Housekeeping App
    { path: '/stafflogin', exact: true, name: 'Pin Login', component: PinSignIn },
];

export default route;
