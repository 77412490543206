import moment from "moment";
import cronparser from "cron-parser";
import cronstrue from 'cronstrue/i18n';

import CronUtils from '../../utils/CronUtils';
import { ScheduleInfo } from './ScheduleInfo'
import i18n from '../../locales/i18n';
import { HKScheduleType } from "../../store/models/domain";

export abstract class CronScheduleCalculator {   


    public static calculateSchedule(schedule: string, dateTime: moment.Moment) : ScheduleInfo  | undefined {
        
        if(!CronUtils.isExpressionValid(schedule))
            return undefined;

        // Treat cron expression  as duration sinse last clean. In order to handle durations that cross the month boundary, we are calculating all durations from Y2K
        const options = {
            currentDate: '2000-01-01 00:00:01'
        };
        const expression = cronparser.parseExpression(schedule, options)
        const prevDate = expression.next().toDate()
        const nextDate = expression.next().toDate()

        const duration = moment.duration(moment(nextDate).diff(moment(prevDate)));
        
        // Calc period respectively current date - for duration >= day make period * 12am - * 12am
        let startScheduleDate = duration.asDays() >= 1
            ? moment(dateTime).startOf('day').subtract(duration).add(1, 'day')
            : moment(dateTime).subtract(duration);

        return {
            scheduleBucket: {
                start: startScheduleDate,
                end: moment(startScheduleDate).add(duration)
            },
            // Treat it as not relative so that it works as before
            //relativeDuration: duration
            scheduleType: HKScheduleType.Cron            
        };
    }

    static validate(schedule: string): string | undefined {
        return CronUtils.isExpressionValid(schedule) ? undefined : "Crons expression is invalid";
    }  

    static getScheduleDescription(schedule: string): string | undefined {
        return cronstrue.toString(schedule, { locale: i18n.language })
    }
}