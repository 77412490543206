abstract class ErrorUtils {  

    public static async getErrorMessage(ex: any): Promise<string> {        
        let errorMessage = ex.message;        
        if(!!ex.response?.data) {
            if(ex.response.data instanceof Blob) {
                let bodyText = await (ex.response.data as Blob).text();
                try {
                    const errorObj: any = JSON.parse(bodyText ?? '');
                    if(errorObj.errors) {
                        errorMessage += ': ' + (errorObj.errors.Error || errorObj.errors);
                    }
                } catch(e) {}
            } else if(ex.response.data.errors) {
                errorMessage += ': ' + ex.response.data.errors;
            }
        }

        return errorMessage;
    }
}

export default ErrorUtils;