import {Assignment} from "./staffSchedule";
import {ScheduleTransaction} from "./facilitySchedule";
import {RoomTask } from "./facilities";
import {RoomRef} from "./common";

export enum NoteType {
    PreClean = "Pre-Clean",
    PostClean = "Post-Clean"
}

export interface Note {
    id: string;    
    notification: string;
    type: NoteType;
    createdDate?: string;
    createdById?: string;
    createdByName?: string;
}

export enum ResidentStatus {
    Resident = "Resident",
    Discharged = "Discharged"
}

export const namePrefixes = ['Mr', 'Miss', 'Mrs', 'Ms', 'Mx', 'M'];

export interface Resident {
    id?: string;
    status: ResidentStatus;
    residentId: string;
    firstName: string;
    lastName: string;
    namePrefix?: string;
    nickName?: string;
    photo?: string;
    createdDate?: string;
    modifiedDate?: string;
    notes?: Note[];
    room?: RoomRef;
    fallRisk?: boolean;
    exitSeeking?: boolean;
    sexuallyAbusive?: boolean;
    physicallyAggressive?: boolean;
    verballyAbusive?: boolean;    
    syncId?: string;
    syncFacilityId?: string;
}

export enum ChangeStatus {
    None = "None",
    New = "New",
    Updated = "Updated",
}

export interface ImportResident extends Resident {
    oldStatus: string;
    oldRoom?: RoomRef;
    changeStatus: ChangeStatus;
    hasPhoto?: boolean;
    hasCleanTask?: boolean;
    cleanTask?: RoomTask;
}

export class PhotoInfo {
    dataUri?: string;
    photoToDelete?: string
}

export interface SaveResidentRequest {
    data: {
        facilityId: string;        
        resident: Resident;
    },
    photo?: PhotoInfo,
    cleanTask?: {
        roomId: string;
        task: RoomTask;
    }
}

export interface DeleteResidentRequest {
    data: {
        facilityId: string;        
        id: string;
    },
    cleanTask?: {
        roomId: string;
        task: RoomTask;
    }
}

export interface AssignmentData {
    staffMemberId: string;
    assignment: Assignment;
}

export interface CheckInOutRequestData {
    data: {
        facilityId: string;
        resident: Resident;
        assignments: AssignmentData[];
        transactions: ScheduleTransaction[];
    },
    photo?: PhotoInfo
}


export interface GetResidentsRequest {
       tokenId?: string,
       sourceId?: string,
       facilityId: string
}

export interface ImportResidentsRequest {
    residents: ImportResident[],
    tokenId?: string,
    sourceId?: string,
    syncFacilityId?: string,
    importPhotos?: boolean,    
}