import React from 'react';
import Swal from 'sweetalert2'
import withReactContent from 'sweetalert2-react-content'

const ReactSwal = withReactContent(Swal);

export function runWithProgress(message: string | string[], action: () => void, additionalProps?: any | undefined) {
    additionalProps = additionalProps ?? {}
    ReactSwal.fire({
        ...additionalProps,
        title: (<div>
                <div className="spinner-border mr-1 text-primary" role="status" style={{
                    width: '3rem',
                    height: '3rem'
                }}>
                    <span className="sr-only">Loading...</span>
                </div><hr />
                {typeof message == "string" && <h5>{message}</h5>}
                {Array.isArray(message) && message.map((m, index) => (<h5 key={"alert_message_" + index}>{m}</h5>))}
            </div>),
        showConfirmButton: false,
        allowEnterKey: false,
        allowEscapeKey: false,
        allowOutsideClick: false,
        onOpen: () => action()
    })
}

export const SwalForm = withReactContent(Swal.mixin({            
    showConfirmButton: false,
    showCancelButton: false,
    allowEscapeKey: false,
    allowEnterKey: false,
    stopKeydownPropagation: false,
    allowOutsideClick: false,   
    width: "60%"
}));

export const Toast = Swal.mixin({
    toast: true,
    position: 'top-end',
    showConfirmButton: false,
    timer: 3000,
    timerProgressBar: true,
    onOpen: (toast) => {
      toast.addEventListener('mouseenter', Swal.stopTimer)
      toast.addEventListener('mouseleave', Swal.resumeTimer)
    }
  })


export default ReactSwal;
