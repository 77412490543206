import * as React from 'react';
import { Form, NavDropdown } from 'react-bootstrap';
import moment from "moment";
import classnames from "classnames";
import { withTranslation, WithTranslation } from 'react-i18next';
import { Room,HKReportStatus, Assignment, AssignmentTemplate } from '../../../../store/models/domain'
import { Dictionary } from "../../../../common/utilityTypes";
import RoomService, {RoomSchedule} from "../../../../services/RoomService";
import _ from "lodash";
import { DropdownSubmenu, NavDropdownMenu } from "react-bootstrap-submenu";

import '../housekeeping.scss'

interface Props extends WithTranslation {
    selected: any
    options: any[]
    onChange(selected: any): void
    defaultSelection?: any
    className?: string
}

export interface AreaFilter {
    level: number
    selected: string
    options: string[]
}

export interface RoomTypeFilter {
    selected: string
    options: string[]
}

export enum AssignmentsFilterOption {
    /** Show all rooms */
    All = "All",    
    /** Only shows assigned tasks */
    DailyList = "Daily List",
    /** Filter roombs selected Task (Assignment Template) */
    OtherSchedules = "Other Schedules",
    /** Show only incomplete rooms */
    Incomplete = "Incomplete",
    /** Only shows outstanding tasks */
    ToDo = "To Do",
}

export interface AssignmentsFilter {
    selected: AssignmentsFilterOption,
    options: AssignmentsFilterOption[],
    otherSchedules: AssignmentTemplate[],
    selectedSchedule?: AssignmentTemplate
}

const AreaFilterRenderItem = (props: Props) => {
    const {
        t, //Translation
        selected,
        options,
        onChange,
        defaultSelection,
        className
    } = props

    let optionNum = 0;
    return (
        <div className={classnames('areaFilter', className)}>
            <Form.Control as="select" id="group" value={selected} onChange={(e: any) => { onChange(e.target.value) }}>
                {!!defaultSelection && <option key="afdefault" value={defaultSelection}>{t(defaultSelection)}</option>}
                {options.map(option => (
                    <option key={"af_" + optionNum++} value={option}>{t(option)}</option>
                ))}
            </Form.Control>
        </div>
    );
}

interface AssignmentFilterProps extends WithTranslation {
    selected: AssignmentsFilterOption,
    options: AssignmentsFilterOption[],
    selectedSchedule?: AssignmentTemplate,
    otherSchedules: AssignmentTemplate[],
    onChange(selected: AssignmentsFilterOption, selectedSchedule?: AssignmentTemplate): void
    className?: string
}

const AssignmentFilterComp = (props: AssignmentFilterProps) => {
    const {
        t, //Translation
        selected,
        options,
        selectedSchedule,
        otherSchedules,
        onChange,
        className
    } = props

   
    return (
        <div className={classnames('areaFilter', className)}>
            <NavDropdownMenu id="assignmentFilter"
                title={selected !== AssignmentsFilterOption.OtherSchedules ? t(t('Assignment ' + selected)) : selectedSchedule?.description ?? selected} className="dropDownControl">
                     {options.map((option, index) => (
                        option !== AssignmentsFilterOption.OtherSchedules 
                            ? <React.Fragment key={`op_fr_${index}`}>
                                <NavDropdown.Item key={`op_${index}`} onClick={e => onChange(option)} active={selected === option} >{t('Assignment ' + option)}</NavDropdown.Item>
                                {option === AssignmentsFilterOption.All && <NavDropdown.Divider key={`divider_1_${index}`} />}
                            </React.Fragment>
                            : (
                                <React.Fragment key={`op_fr_${index}`}>
                                    <DropdownSubmenu key={`op_${index}`} title={t('Assignment ' + option)} >
                                        {otherSchedules.map((schedule, i) => 
                                            (<NavDropdown.Item key={`schedule_${i}`} onClick={e => onChange(option, schedule)} 
                                                active={selected === option && selectedSchedule?.id === schedule.id}>
                                                {schedule.description}
                                            </NavDropdown.Item>))}                                    
                                    </DropdownSubmenu>
                                    <NavDropdown.Divider key={`divider_2_${index}`} />
                                </React.Fragment>
                            )
                     )
                     )}
            </NavDropdownMenu>   
        </div>
    );
}

export const AssignmentFilterRenderItem = withTranslation()(AssignmentFilterComp);

export const getRoomTypeFilters = (rooms: Room[], filter: RoomTypeFilter): RoomTypeFilter => {

    let options = [...new Set(rooms.filter(r => !r.isArchived).map(r => r.roomType))].sort();
    let result: RoomTypeFilter = { selected: filter.selected === '' ? 'All Room Types' : filter.selected, options: options }
    return result
}

export const getAreaFilters = (rooms: Room[], areaFilters: AreaFilter[]): AreaFilter[] => {
    let results: AreaFilter[] = []
    let level = 0
    while (true) {
        //Remember filters between data refreshes
        let lastSelection = areaFilters[level]?.selected
        if (!lastSelection) lastSelection = 'Everything'

        let options = getAreaFilterOptions(rooms, level, results)
        if (options.length === 0) break;

        let filter: AreaFilter = {
            level: level,
            selected: lastSelection,
            options: options
        }
        results.push(filter)
        if (lastSelection === 'Everything') break;
        level++
    }
    return results;
}

export const getAreaFilterOptions = (rooms: Room[], level: number, filters: AreaFilter[]): string[] => {
    filters.forEach(filter => {
        rooms = rooms.filter(r => r.facilityArea[filter.level] === filter.selected)
    })
    var results = [...new Set(rooms.filter(r => !r.isArchived && r.facilityArea.length > level).map(r => r.facilityArea[level]))].sort()
    if (results.length > 0) return ['Everything', ...results]
    return results
}

export const filterRoomsByAssignments = (rooms: Room[], selectedFilter: AssignmentsFilterOption, assignments: Assignment[], scheduledClean: {
    byRoomId: Dictionary<RoomSchedule>
    allIds: string[]
}, selectedSchedule?: AssignmentTemplate): Room[] => {

    const today = moment().startOf("day");

    switch (selectedFilter) {
        case AssignmentsFilterOption.DailyList:
            return RoomService.filterByAssignmentsAndRequiredCleaning(rooms, assignments, scheduledClean);

        case AssignmentsFilterOption.OtherSchedules:
            return !!selectedSchedule
                 ? RoomService.filterByRecurrentSchedule(rooms, selectedSchedule, scheduledClean, today)
                 : [...rooms];

        case AssignmentsFilterOption.Incomplete:
            return rooms.filter(r => _.last(_.orderBy(r.housekeepingHistory ?? [], (h) => h.dateComplete))?.status === HKReportStatus.Incomplete);

        case AssignmentsFilterOption.ToDo:
            return rooms.filter(r => scheduledClean.allIds.includes(r.id) &&
                // There is requiring cleaning and room is not completed
                !(r.housekeepingHistory ?? []).some(h => moment(h.dateComplete) >= today && h.status === HKReportStatus.Complete
                    && (h.priority ?? 0) >= (scheduledClean.byRoomId[r.id].cleanType.priority ?? 0)));

        default:
            return [...rooms];
    }
}

const translate = withTranslation()
export default translate(AreaFilterRenderItem)
