import { SerializedError } from '@reduxjs/toolkit'
import { Resident } from './resident'
import { StaffMember, Acknowledgement } from './tenants'

interface Dictionary<T> {
    [Key: string]: T;
}

export interface HousekeepingReportQuery {
    ReportId?: string;
    Status?: string
    LatestRoomHistory?: boolean
    startDate?: string
    endDate?: string,
    allFacilities?: boolean,
    reload?: boolean,
}

export interface HKAuditMarkingReportQuery {
    reportId?: string;
    latestRoomHistory?: boolean
    startDate?: string
    endDate?: string,
    allFacilities?: boolean,
    reload?: boolean,
}

export interface HousekeepingCleanQuery {    
    facilityId?: string;
    status?: string;
    startDate?: string;
    endDate?: string
    allFacilities?: boolean
}

export interface HousekeepingCleansRequest {
    data: HousekeepingCleanQuery
}


export interface DailyStaffReportQuery {
    staffMemberId?: string;   
    latestReport?: boolean;
    facilityId?: string;
    startDate?: string;
    endDate?: string;
}

export interface HousekeepingState {
    config: HousekeepingData
    reports: Dictionary<HKReport>
    overdueReports: HKOverdueReport[],
    auditMarkingReports: HKAuditMarkingReport[],
    cleans: HKClean[],
    dailyStaffReports: DailyStaffReport[],
    loading: 'idle' | 'pending' | 'succeeded' | 'failed'
    error: SerializedError | null
}

export interface HousekeepingSettings {
    notificationSendingTime?: Date
    allowGroupSelect?: boolean
    notificationSettings?: HKNotificationSettings;
}

export interface HKNotificationSettings {
    allDays?: HKNotificationDay;
    dayOfWeek: HKNotificationDay[];    
    timeZoneName?: string;
}

export interface HKNotificationDay {
    enabled: boolean;
    /** Key Sunday - 0,...Saturday - 6 */
    dayOfWeek: number;
    /** Time in local timezone when overdue email notifications should be sent */
    sendingTime?: string;
    cleaningPriorities?: HKNotificationCleaning[];
}

export interface HKNotificationCleaning {
    priority: number;
    enabled: boolean;
}

export interface HousekeepingData {
    id: string
    roomTypes: HKRoomType[],
    dailyBriefings?: DailyBriefing[],
    settings?: HousekeepingSettings,
    operationImages?: CleanOperationImage[];
}

export interface HKRoomType {
    id: string
    roomType: string
    routines: HKCleanType[]
    roomStates?: HKRoomState[],
    roomEvents?: HKRoomEvent[]
}

export interface HKRoomState {
    stateName: string
    tags: string[]
    order: number
    color?: string
    borderColor?: string
    style?: string
}

export interface HKRoomEvent {
    id: string,
    eventName: string;
    eventType: HKRoomEventType,
    tags: string[];
    order?: number;
}

export enum HKRoomEventType {
    CleanDay = "CleanDay"
}

export enum HKCleanTypeTags {
    Arrival = "Arrival",
    Discharge = "Discharge",
}

export interface HKCleanType {
    cleanType: string
    description?: string
    color?: string
    priority?: number;
    isDefault?: boolean;
    /** Indicate that this clean is used only via  room status */
    stateClean?: boolean;
    schedule?: string;
    scheduleType?: HKScheduleType;
    operations: HKCleanOperation[]
    tags?: string[]
    qrCode?: string
    shortcut?: HKShortcut
}

export interface HKShortcut {
    icon?: string
    color?: string
    order: number
}

export enum HKAuditOperationStatus {
    NotObserved = "NotObserved",
    NotCleanedFailed = "NotCleanedFailed",
    CleanedFailed = "CleanedFailed",
    CleanedPass = "CleanedPass"
}

export interface HKAuditReport {   
    startDate?: string;
    reportDate?: string;
    /** Date of Pre-Audit Marking */
    markingDate?: string;
    auditor: StaffMember;
    directObservationMethod?: boolean;
    fluorescentGelMethod?: boolean;
    atpSystemMethod?: boolean;
    swabCulturesMethod?: boolean;
    agarSlideCulturesMethod?: boolean;
    comment?: string;
    photos?: HKPhoto[];
    commentsEmailed?: boolean;
}

export interface HKCleanOperation {
    group: string
    item: string
    icon?: string
    checked?: boolean
    skipped?: boolean
    timeStamp?: string
    optional?: boolean
    detailImages?: CleanOperationImageRef[];
    /** Set by auditor on audit step */
    auditStatus?: HKAuditOperationStatus
    /** If the item is marked on Pre-Audit Marking step */
    isMarked?: boolean
}

export interface CleanOperationImage {    
    /** Image path identifier */
    image: string;
    hash: string;
    createdDate?: string;
    createdBy?: string;
    isDeleted?: boolean;
    dataUri?: string;
}

export interface CleanOperationImageRef {
    /** Image path identifier */
    image: string;
    locale: string;
}

export interface HKPhoto {
    id: string;
    photo: string;
    createdDate?: string;
    createdBy?: string;    
    isDeleted?: boolean;
    dataUri?: string;
}

export interface HKReportBase {
    id: string
    /** Date when report was started */
    startDate: string;
    /** Date when report last saved */
    reportDate: string;
    room: {
        id: string
        number: string
    }
    status: HKReportStatus
    resident: Resident
    staffMember: StaffMember
    roomType: HKRoomType,
    facility: {
        id: string,
        name: string
    };
    comment?: string;
    supervisorNote?: string;
    photos?: HKPhoto[];
    auditReport?: HKAuditReport;
    residentFamilyConcern?: boolean;
    maintenance?: boolean;
    pestControl?: boolean;
    commentsEmailed?: boolean
}

export interface HKReport extends HKReportBase {
    markingReport?: HKAuditMarkingReportRef
}

/** This report is created before cleaning by marking items with fluorescent gel
    And if exist then checked after cleaning during audit */
export interface HKAuditMarkingReport extends HKReportBase {}

export interface HKAuditMarkingReportRef {
    id: string;
    cleanType: string;
    startDate: string;
    auditor: string;
}


export interface HKReportSupervisorProps {    
    id: string;
    supervisorNote?: string;
}

/** Represent lightweight dto for HKReport  */
export interface HKClean {
    id: string;
    status: HKReportStatus;
    /** Date when report was started */
    startDate: string;
    /** Date when report last saved */
    reportDate: string;
    room: {
        id: string,
        number: string,
    };
    cleanType?: string;
    priority?: number;
    resident?: {
        id: string;
        name: string;
        residentId?: string;
    };
    staffMember?: {
        staffRefId: string;
        role?: string;
        name: string;
    };
    facility: {
        id: string,
        name: string
    };
    color?: string;
}

export enum HKReportStatus {
    Initial = "Initial",
    Incomplete = "Incomplete",
    Complete = "Complete"
}

export interface DailyBriefingItem {
    message: string;
    icon: string;
    checkedDate?: string;
    order: number;
}

export interface DailyBriefing {
    name?: string,
    items: DailyBriefingItem[];
    staffPositions?: string[];
    isDefault?: boolean;
}

export interface AcknowledgementStatus {
    remaining: Acknowledgement[];
    acknowledgements: Acknowledgement[];
}

export interface DailyStaffReport  {
    id: string;
    date: string;
    lastReportDate?: string;
    staffMember: StaffMember;
    dailyBriefing: DailyBriefing;
    acknowledgementStatus: AcknowledgementStatus;
}

export enum HKScheduleType {
    /** Schedule based on CROS expressions */
    Cron = "Cron",
    /** Schedule based on timeframe period and multiple buckets inside it */
    Absolute = "Absolute",
    /** Schedule based on relative duration since last clean and start/end time when cleaning is active */
    Relative = "Relative"
}


export enum HKSchedulePeriodType {
    Daily = "Daily",
    Weekly = "Weekly",
    Monthly = "Monthly",
    Yearly = "Yearly"
}

/** Specify time bucket for AbsoluteSchedule */
export interface HKTimeBucket {    
    /** Bucket start relatively to Schedule Period start in seconds */
    startSeconds: number,
    /** Bucket end relatively to Schedule Period start in seconds */
    endSeconds: number,
    /** Time inside bucket when dueSoon event occurs relatively to Schedule Period start in seconds */
    dueSoonSeconds: number
}

export interface HKAbsoluteSchedule {  
    startDate?: string,  
    /**
     * If true then week starts on Sunday(Monday), Month on 1st day etc.
     * Otherwise period starts on startDate, Month has fixed duration 30 days, Year 365 days
     */
    boundToCalendar?: boolean,
    periodType: HKSchedulePeriodType,
    periodCount: number,
    timeBuckets: HKTimeBucket[]
}

export interface HKRelativeSchedule {
    /** Duration in seconds after last clean occurred when schedule should due */      
    durationSeconds: number,
    startTime: string,
    endTime: string
}

export interface HKOverdueReport {
    facility: {
        id: string,
        name: string
    },
    dateSent: string;
    startDate: string;
    endDate: string;
    recipients: {
        staffRefId: string;
        role?: string;
        name: string;
        email?: string;
    }[];
    message: string;
    room: {
        id: string
        number: string
    };
    resident: Resident;
    cleanType: string;
    staffMember: StaffMember;    
}

export interface HKOverdueReportQuery {
    reportId?: string;
    staffId?: string;
    residentId?: string;
    roomId?: string;
    startDate?: string
    endDate?: string,
    allFacilities?: boolean,
}
