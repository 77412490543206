import React from 'react'
import { Redirect, Route } from 'react-router-dom'
import { authProvider } from './AuthProvider';

const PrivateRouteComp = ({ component: Component, ...rest }) => {

    const isAuthenticated = authProvider.isAuthenticated()
    const isRegisteredDevice = authProvider.isRegisteredDevice()

    return (
        <Route
            {...rest}
            render={props =>
                isAuthenticated
                    ? (<Component {...props} />)
                    : isRegisteredDevice
                        ? (<Redirect to={{ pathname: '/staffLogin', state: { from: props.location } }} />)
                        : (<Redirect to={{ pathname: '/login', state: { from: props.location } }} />)
            }
        />
    )
}

export const PrivateRoute = PrivateRouteComp
