import { createAsyncThunk } from '@reduxjs/toolkit'
import { AxiosResponse } from "axios";

import apiService from "../../../common/api.service";
import * as dom from "../../models/domain";


export interface SaveFacilityScheduleRequest {
    staffSchedules: dom.StaffSchedule[]    
}

export interface AddTransactionRequest {
    facilityId: string,
    transaction: dom.ScheduleTransaction
}

export interface GetFacilityScheduleRequest {
    tenantId?: string;
    facilityId?: string;
}

export const saveFacilitySchedule = createAsyncThunk(
    'facilitySchedule/saveSchedule',   
    async (data: SaveFacilityScheduleRequest) => {                
        const response = await apiService.post<SaveFacilityScheduleRequest>('/facilitySchedule/update', data) as AxiosResponse<dom.FacilitySchedule>
        if (response) {
            return response.data
        } else {
            throw new Error('unknown error saving FacilitySchedule');
        }
    }
)


export const getFacilitySchedules = createAsyncThunk(
    'facilitySchedule/getSchedule',
    async (data: GetFacilityScheduleRequest) => {
        const response = await apiService.post('/facilitySchedule', data) as AxiosResponse<dom.FacilitySchedule[]>
        if (response) {
            return response.data
        } else {
            throw new Error('unknown error loading FacilitySchedule');
        }
    }
)

export const addTransaction = createAsyncThunk(
    'facilitySchedule/addTransaction',   
    async (data: AddTransactionRequest) => {                
        const response = await apiService.post<AddTransactionRequest>('/facilitySchedule/addTransaction', data) as AxiosResponse<dom.FacilitySchedule>        
        if (response) {
            return response.data
        } else {
            throw new Error('unknown error adding transaction');
        }
    }
)
