import * as React from 'react';
import {useTranslation} from 'react-i18next';
import {Col, Form, Button} from 'react-bootstrap';
import Camera, {IMAGE_TYPES, FACING_MODES} from 'react-html5-camera-photo';
import 'react-html5-camera-photo/build/css/index.css';
import styled from 'styled-components';


const FullScreenCamera = styled.div`
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    z-index: 20001;
`;

interface ImageSelectProps {
    handleTakePhoto: (dataUri: string, type: string) => void,
    dissableClear?: boolean,
    dissableFileSelect?: boolean,
    selectFileLabel?: string,
    readonly?: boolean
}

export const ImageSelect = ({ 
    handleTakePhoto, 
    dissableClear, 
    dissableFileSelect, 
    selectFileLabel,
    readonly
}: ImageSelectProps) => {   

    const {t} = useTranslation();

    const [showCamera, setShowCamera] = React.useState(false);
    const [cameraTimeout, setCameraTimeout] = React.useState<number | undefined>();

    function handleTakePhotoAnimationDone (dataUri: any, type: string) {        
        setShowCamera(false);
        handleTakePhoto(dataUri, type);
    }

    function clearPhoto () {        
        setShowCamera(false);
        handleTakePhoto("", "")
    }

    const switchCamera = (show: boolean) => {
        setShowCamera(show);
        if(show){
            // Close camera after timeout
            const timeout = window.setTimeout(() => {
                switchCamera(false);
              }, 30000);
            setCameraTimeout(timeout);
        } else {
            if(!cameraTimeout) {
                clearTimeout(cameraTimeout);
                setCameraTimeout(undefined);
            }
        }
    }

    const fileChangedHandler = (event: any) => {

        const parts = event.target.files[0].name.split(".");
        const type = parts.length > 1 ? parts[parts.length-1] : "jpeg";

        const reader = new FileReader();
        reader.onload = function()
        {
            handleTakePhotoAnimationDone(reader.result, type);
        }
        reader.readAsDataURL(event.target.files[0]);
        event.target.value = null;        
    }

    return (
        <>
            <Form.Row><Col>{showCamera && <FullScreenCamera>
                <Camera onTakePhotoAnimationDone = {(dataUri: string) => handleTakePhotoAnimationDone(dataUri, "jpeg")} isFullscreen={true} imageType = {IMAGE_TYPES.JPG} 
                    idealFacingMode={FACING_MODES.ENVIRONMENT} isImageMirror = {false}  />
                </FullScreenCamera>}</Col></Form.Row>
            <Form.Row>
                <Col>
                    {!readonly &&<div className="d-flex justify-content-start align-items-center mt-1 text-nowrap align-middle">
                        <Button className="" variant="primary" onClick={() => switchCamera(true)}>{t('Take Photo')}</Button>                        
                        {dissableClear !== true &&<Button className="" variant="primary" onClick={() => clearPhoto()}>{t('Clear')}</Button>}
                        {dissableFileSelect !== true &&<Form.File onChange={fileChangedHandler} label={selectFileLabel || t("Select photo from file")} data-browse={t("Browse")} custom accept="image/*" />}
                    </div>}
                </Col>
            </Form.Row>
        </>
    );
};

export default ImageSelect;