import _ from "lodash";
import moment from "moment";

import { Room, Resident, ScheduleTransaction, ScheduleTransactionTypes, StaffSchedule, Assignment, HKRoomType, HKCleanType, HKReportStatus,
    RoomTaskType, HKScheduleType, AssignmentTemplate, SyncFacilityId, FacilityData } 
    from '../store/models/domain';
import {Dictionary} from "../common/utilityTypes";
import {ScheduleCalculator, ScheduleInfo} from "./Schedule";


export interface RoomWithDates extends Room {
    start?: moment.Moment, 
    end?: moment.Moment
}

export interface ResidentWithRoom extends Resident {    
    /** Last scheduled room */
    scheduledRoom?: RoomWithDates,

    /** All future scheduled rooms */
    roomsHistory?: RoomWithDates[],

    syncFacilityRef?: SyncFacilityId
}

interface ScheduledCleanType { 
    cleanType: HKCleanType, 
    scheduleInfo?: ScheduleInfo
}

export interface RoomSchedule { 
    room: Room, 
    cleanType: HKCleanType,
    scheduleType?: HKScheduleType,
    dueSoon?: boolean,
    missed?: boolean,
    incomplete?: boolean,
    scheduleBucket?: {
        start: moment.Moment,
        end: moment.Moment,
    },
    prevScheduleBucket?: {
        start: moment.Moment,
        end: moment.Moment,
    }    
}

abstract class RoomService {  

    /** Find free rooms or room discharged in future */
    public static getFreeRooms(rooms: Room[], residents: Resident[], transactions: ScheduleTransaction[]) {
        
        const today = moment().startOf("day");

        const assignedRooms = residents.filter(r => !!r.room).map(r => r.room!.id);
        const transactinsFiltered = _.orderBy(transactions.filter(t => moment(t.date) >= today), "date");
        const freeRooms: RoomWithDates[] = [];
        
        for(const room of rooms) {
            let start: moment.Moment | undefined = assignedRooms.includes(room.id) 
                ? moment().add(-1, "day").startOf('day')
                : undefined;
            
            let end: moment.Moment | undefined = undefined;

            const roomTransactions = transactinsFiltered.filter(t => t.room?.id === room.id || (!!t.dischargeRoom && t.dischargeRoom.id === room.id));

            for(const tran of roomTransactions) {
                if((tran.type === ScheduleTransactionTypes.Arrival || 
                    tran.type === ScheduleTransactionTypes.Transfer) && tran.room?.id === room.id) {
                    
                    start = moment(tran.date).startOf('day');
                }

                if((tran.type === ScheduleTransactionTypes.Discharge ||
                    tran.type === ScheduleTransactionTypes.Transfer) && tran.dischargeRoom?.id === room.id) {
                    
                    end = moment(tran.date).startOf('day');
                }
            }

            if((!start && !end) || !!end){
                freeRooms.push({
                    ...room,
                    start: start,
                    end: end                   
                })
            }
        }

        return freeRooms;        
    }
    
    /** Returns resident with current all last scheduled room */
    public static getResidentsWithRoom(facility: FacilityData, rooms: Room[], residents: Resident[], transactions: ScheduleTransaction[]) {
    
        const today = moment().startOf("day");
        
        const transactionsFiltered = _.orderBy(transactions.filter(t => moment(t.date) >= today), "date");
        const residentsWithRoom: ResidentWithRoom[] = [];
        
        for(const resident of residents) {

            let start: moment.Moment | undefined = undefined;            
            let end: moment.Moment | undefined = undefined;
            let room = !!resident.room ? rooms.find(r => r.id === resident.room?.id) : undefined;

            const syncFacilityId = !!resident.syncId ? (resident.syncFacilityId || facility.syncId) : resident.syncFacilityId;

            const roomsHistory: RoomWithDates[] = [];
            const residentTransactions = transactionsFiltered.filter(t => t.resident.id === resident.id);

            for(const tran of residentTransactions) {
                if(tran.type === ScheduleTransactionTypes.Arrival || 
                    tran.type === ScheduleTransactionTypes.Transfer) {
                        
                    if(!!room) {
                        roomsHistory.push({...room, start: start, end: moment(tran.date).startOf('day')});
                    }
                    
                    start = moment(tran.date).startOf('day');
                    room = rooms.find(r => r.id === tran.room?.id);
                }

                if(tran.type === ScheduleTransactionTypes.Discharge) {
                    
                    end = moment(tran.date).startOf('day');
                }
            }

            if(!!room) {
                roomsHistory.push({...room, start: start, end: end});
            }

            residentsWithRoom.push({
                ...resident,
                syncFacilityRef: !!syncFacilityId ? JSON.parse(syncFacilityId) : undefined,
                scheduledRoom: !!room 
                    ? {
                        ...room,
                        start: start,
                        end: end                   
                    }
                    : undefined,
                roomsHistory
            });
        }

        return residentsWithRoom;        
    }
    
    public static filterByStaffSchedules(rooms: Room[], staffSchedules: StaffSchedule[], date: moment.Moment) {
        
        const assignments = _.flatten(staffSchedules.map(s => s.assignments ?? []))
            .filter(a => moment(a.startDate) <= date && date <= moment(a.endDate).endOf('day'))

        return this.filterByAssignments(rooms, assignments);
    }

    public static filterByAssignments(rooms: Room[], assignments: Assignment[]) {

        const filterRooms = rooms.filter(r =>
            assignments.some(s =>
                ((s.roomNumbers ?? []).includes(r.id) || (s.roomNumbers ?? []).length === 0)
                && (!s.roomType || s.roomType === r.roomType)
                && ((s.facilityAreas ?? []).length === 0 || RoomService.facilityAreaMatch(r.facilityArea ?? [], s.facilityAreas ?? []))
            )
        );

        return filterRooms;
    }

    public static filterByAssignmentsAndRequiredCleaning(rooms: Room[], assignments: Assignment[], scheduledClean: {
        byRoomId: Dictionary<RoomSchedule>
        allIds: string[]        
    }) {

        const filterRooms = rooms.filter(r =>
            assignments.some(s =>
                ((s.roomNumbers ?? []).includes(r.id) || (s.roomNumbers ?? []).length === 0)
                && ((s.facilityAreas ?? []).length === 0 || RoomService.facilityAreaMatch(r.facilityArea ?? [], s.facilityAreas ?? []))
                && (!s.roomType || s.roomType === r.roomType)
                && (!s.cleanType || (scheduledClean.allIds.includes(r.id) && scheduledClean.byRoomId[r.id].cleanType.cleanType === s.cleanType))
            )
        );

        return filterRooms;
    }

    public static filterByRecurrentSchedule(rooms: Room[], schedule: AssignmentTemplate, scheduledClean: {
        byRoomId: Dictionary<RoomSchedule>
        allIds: string[]        
    }, date: moment.Moment) {
       

        const dayOfWeek = date.day();
        if(!(schedule.scheduledDays ?? []).includes(dayOfWeek) && (schedule.scheduledDays ?? []).length > 0)
            return [];

        const filterRooms = rooms.filter(r =>            
                ((schedule.roomNumbers ?? []).includes(r.id) || (schedule.roomNumbers ?? []).length === 0)
                && ((schedule.facilityAreas ?? []).length === 0 || RoomService.facilityAreaMatch(r.facilityArea ?? [], schedule.facilityAreas ?? []))
                && (!schedule.roomType || schedule.roomType === r.roomType)
                && (!schedule.cleanType || (scheduledClean.allIds.includes(r.id) && scheduledClean.byRoomId[r.id].cleanType.cleanType === schedule.cleanType))
        );

        return filterRooms;
    }

    public static filterAssignmentsByRoom(assignments: Assignment[], room: Room) {        
     
        const filteredAssignments = assignments.filter(a =>
            ((a.roomNumbers ?? []).includes(room.id) || (a.roomNumbers ?? []).length === 0)
            && (!a.roomType || a.roomType === room.roomType)
            && ((a.facilityAreas ?? []).length === 0 || RoomService.facilityAreaMatch(room.facilityArea ?? [], a.facilityAreas ?? [])));

        return filteredAssignments;
    }

     /** 
      * Calculate requiring cleaning only by room.outstandingTasks and room type schedules so each user see the same rooms state,
      * Calculate on current time
      */
     public static calculateRoomsRequiringCleaning(rooms: Room[], roomTypes: HKRoomType[]) : RoomSchedule[] {  
        
        return this.calculateRoomsRequiringCleaningOnDate(rooms, roomTypes, moment());
     }

    /** 
     * Calculate requiring cleaning only by room.outstandingTasks and room type schedules so each user see the same rooms state,
     * Calculate scheduling on provided dateTime to help in testing
    */
    public static calculateRoomsRequiringCleaningOnDate(rooms: Room[], roomTypes: HKRoomType[], dateTime: moment.Moment) : RoomSchedule[] {      
        

        // Build map of scheduled clean types with all scheduled info
        const scheduledCleanMap = new Map<String, ScheduledCleanType[]>();
        const roomTypesMap = new Map<String, HKRoomType>();
        for(const roomType of roomTypes) {

            roomTypesMap.set(roomType.roomType, roomType);
            
            const scheduleCleans: ScheduledCleanType[] = [];
            scheduledCleanMap.set(roomType.roomType, scheduleCleans);            

            for(const cleanType of (roomType.routines ?? []).filter(c => !!c.schedule)) {              

                // Calculate general schedule for cleanType
                const scheduleInfo = ScheduleCalculator.calculateSchedule(
                    cleanType.scheduleType || HKScheduleType.Cron, cleanType.schedule!, dateTime);
                
                if(!!scheduleInfo)
                    scheduleCleans.push({cleanType: cleanType, scheduleInfo: scheduleInfo})
            }
        }

        const today = moment(dateTime).startOf("day");
               
        const scheduledRooms: RoomSchedule[] = []

        for(const room of rooms) {

            let isRoomScheduled = false;

            // TODO: need to treat outstandingTasks as schedule and take cleanType with highest priority
            // among outstandingTasks and schedules

            // Search scheduled assignment by explicit included room or by outstandingTasks
            const curRoomTasks = (room.outstandingTasks || [])
                .filter(a => !!a.cleanType && moment(a.startDate || moment(dateTime).add(-1, "year")) <= dateTime && dateTime <= moment(a.endDate || moment(dateTime).add(1, "year")).subtract(1, 'second').endOf('day'));
            if(curRoomTasks.length > 0) {

                let roomType = roomTypes.find(t => (room.roomType ?? "").toLowerCase() === (t.roomType ?? "").toLowerCase());
                if(!!roomType) {

                    let cleanTypes = roomType.routines.filter(t => curRoomTasks.some(a => (a.cleanType ?? "").toLowerCase() === (t.cleanType ?? "").toLowerCase()
                        // Check that there is no cleaning of that clean type or with hight priority in assignment period
                        && !(room.housekeepingHistory ?? []).some(h =>  h.status === HKReportStatus.Complete && moment(a.startDate) <= moment(h.dateComplete) && 
                            (h.priority ?? 0) >= (t.priority ?? 0))));

                    if(cleanTypes.length > 0) {

                        let scheduledClean = _.last(_.sortBy(cleanTypes, (t) => t.priority ?? 0));
                        scheduledRooms.push({room, cleanType: scheduledClean!});
                        isRoomScheduled = true;
                    }
                }
            }

            // Search scheduled clean types
            if(!isRoomScheduled) {

                const roomType = roomTypesMap.get(room.roomType);
                const roomState = !!room.roomState 
                    ? roomType?.roomStates?.find(s => s.stateName === room.roomState)
                    : undefined;

                // Filter clean types accordingly to that if room has state
                let scheduledCleans = scheduledCleanMap.get(room.roomType);
                if(!!roomState) {
                    scheduledCleans = scheduledCleans?.filter(c => c.cleanType.stateClean && c.cleanType.cleanType === room.stateCleanType)
                } else {                    
                    scheduledCleans = scheduledCleans?.filter(c => !c.cleanType.stateClean)
                }
                
                if (!!scheduledCleans) {  
                    
                    // Re-Calculate relative schedules based on last room clean
                    scheduledCleans = scheduledCleans.map(s => !s.scheduleInfo?.relativeDuration 
                        ? s
                        : {
                            ...s!, 
                            scheduleInfo: ScheduleCalculator.ReCalculateRelativeSchedule(s.scheduleInfo!, 
                                _.last(_.sortBy((room.housekeepingHistory ?? []).filter(h => h.status == HKReportStatus.Complete && !!h.dateComplete 
                                    && (h.priority ?? 0) >= (s.cleanType.priority ?? 0)).map(h => moment(h.dateComplete!)),
                                    (d) => d)) ?? moment().subtract(10, "year"))
                        }).filter(s => !!s.scheduleInfo);

                    // Check that there is no cleaning of that clean type or with hight priority in scheduled period
                    scheduledCleans = scheduledCleans!.filter(s => 
                        !(room.housekeepingHistory ?? [])
                            .some(h => h.status === HKReportStatus.Complete && 
                                s.scheduleInfo!.scheduleBucket!.start <= moment(h.dateComplete) && 
                                (h.priority ?? 0) >= (s.cleanType.priority ?? 0))
                    );

                    if(scheduledCleans!.length > 0) {

                        let scheduledClean = _.last(_.sortBy(scheduledCleans, (t) => t.cleanType.priority ?? 0)); 
                        const lastReport = _.last(_.orderBy((room.housekeepingHistory ?? []), (h) => h.dateComplete));                                            

                        scheduledRooms.push({
                            room, 
                            cleanType: scheduledClean!.cleanType,
                            scheduleBucket: {
                                start: scheduledClean!.scheduleInfo!.scheduleBucket!.start,
                                end: scheduledClean!.scheduleInfo!.scheduleBucket!.end
                            },

                            prevScheduleBucket: !! scheduledClean!.scheduleInfo!.prevScheduleBucket 
                                ? {
                                start: scheduledClean!.scheduleInfo!.prevScheduleBucket!.start,
                                end: scheduledClean!.scheduleInfo!.prevScheduleBucket!.end
                            }
                                : undefined,

                            dueSoon: !!scheduledClean!.scheduleInfo!.scheduleBucket!.dueTime && 
                                        scheduledClean!.scheduleInfo!.scheduleBucket!.dueTime! < dateTime &&
                                        scheduledClean!.scheduleInfo!.scheduleBucket!.end! > dateTime,

                                        // Don't check that bucket is finished for cron
                            incomplete: (scheduledClean!.scheduleInfo!.scheduleType === HKScheduleType.Cron || scheduledClean!.scheduleInfo!.scheduleBucket!.end > dateTime) && !!lastReport &&
                                            lastReport.status == HKReportStatus.Incomplete 
                                            && (lastReport.priority ?? 0) >= (scheduledClean!.cleanType.priority ?? 0)                                            
                                            && moment(lastReport.startDate ?? lastReport.dateComplete) > scheduledClean!.scheduleInfo!.scheduleBucket!.start,

                            // Current time bucket is finished but there is not any clean - show only for absolute as relative bucket always lasts to now
                            // check that in previous bucket there was no cleans and set it when new (current) bucket start
                            missed: (scheduledClean!.scheduleInfo!.scheduleType == HKScheduleType.Absolute &&
                                    !!scheduledClean!.scheduleInfo!.prevScheduleBucket && // prev bucket exists                                    
                                        !(room.housekeepingHistory ?? []).some(h => h.status === HKReportStatus.Complete &&
                                            (h.priority ?? 0) >= (scheduledClean!.cleanType.priority ?? 0)
                                            && !!h.startDate && moment(h.startDate) > scheduledClean!.scheduleInfo!.prevScheduleBucket!.start)
                                            )
                                            ,

                            scheduleType: scheduledClean!.scheduleInfo!.scheduleType
                        });
                    }
                }
            }
        }

        return scheduledRooms;
    }
    
    private static facilityAreaMatch(roomArea: string[], assignmentsAreas: string[][]){

        for(const area of assignmentsAreas) {
            if(!area || area.length === 0) {
                continue;
            }

            const min = Math.min(roomArea.length, (area ?? []).length);
            let isMatch = true;
            for(let i = 0; i < min; i++) {
                if(roomArea[i] !== area[i]) {
                    isMatch = false;
                    break;
                }
            }

            if(isMatch) {
                return true;
            }
        }

        return false;
    }    
}

export default RoomService;
