import localforage from 'localforage'

function rejectWithMessage(error?: any) {
    return Promise.reject(error?.message ?? error);
}

export default (key: string, replacer?: (this: any, key: string, value: any) => any, reviver?: (this: any, key: string, value: any) => any) => ({
    load() {
        return localforage.getItem(key).then((jsonState: unknown) => {
            if (!!jsonState && typeof jsonState === 'string') {
                return JSON.parse(jsonState, reviver) ?? {}
            }
            return {}
        }).catch(rejectWithMessage)
    },

    save(state: any) {
        const jsonState = JSON.stringify(state, replacer)
        return localforage.setItem(key, jsonState).catch(rejectWithMessage)
    }
});
