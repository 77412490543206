import moment from "moment";

import { HKScheduleType, HKAbsoluteSchedule, HKRelativeSchedule } from '../../store/models/domain';
import { ScheduleInfo } from './ScheduleInfo'
import {CronScheduleCalculator} from './CronScheduleCalculator'
import {AbsoluteScheduleCalculator} from './AbsoluteScheduleCalculator'
import {RelativeScheduleCalculator} from './RelativeScheduleCalculator'
import log from '../../common/logging';

export abstract class ScheduleCalculator { 

    /** 
     * Return general information about schedule on the dateTime
     * For relative schedule it should be also calculated for each specific room
     */
    public static calculateSchedule(scheduleType: HKScheduleType, schedule: string, dateTime: moment.Moment) : ScheduleInfo | undefined {
        
        try {
            switch(scheduleType) {
                case HKScheduleType.Cron:
                    return CronScheduleCalculator.calculateSchedule(schedule, dateTime);
                case HKScheduleType.Absolute:
                    var absoluteSchedule = ScheduleCalculator.parseJson<HKAbsoluteSchedule>(schedule);
                    return AbsoluteScheduleCalculator.calculateSchedule(absoluteSchedule, dateTime);
                case HKScheduleType.Relative:
                    var relativeSchedule = ScheduleCalculator.parseJson<HKRelativeSchedule>(schedule);
                    return RelativeScheduleCalculator.calculateSchedule(relativeSchedule, dateTime);
                default:
                    return undefined;
            }
        }
        catch(ex){
            log.error(`Could not calculate schedule - Type: ${scheduleType}, Schedule: ${schedule}, Date: ${dateTime}`, ex);
        }
        return undefined;
    }

    /**
     * Recalculate relative schedule to absolute based on lastCleanDate
     */
    static ReCalculateRelativeSchedule(scheduleInfo: ScheduleInfo, lastCleanDate?: moment.Moment): ScheduleInfo | undefined {         
        const scheduleStart = moment(lastCleanDate || moment().subtract(10, "year")).add(scheduleInfo.relativeDuration);        

        // Schedule not started yet
        if(scheduleStart > moment()) 
            return undefined;

        // Recalculate time bucket on current date
        return {
            scheduleBucket: {
                start: moment(scheduleInfo.scheduleBucket?.start.format('HH:mm'), [moment.ISO_8601, 'HH:mm']),
                end: moment(scheduleInfo.scheduleBucket?.end.format('HH:mm'), [moment.ISO_8601, 'HH:mm'])
            },
            scheduleType: scheduleInfo.scheduleType
        }
    }
    
    /** 
     * Validate if schedule definition is correct
     */
        public static validateSchedule(scheduleType: HKScheduleType, schedule: string) : string | undefined {

        if(!schedule)
            return undefined;
    
        try {
            switch(scheduleType) {
                case HKScheduleType.Cron:
                    return CronScheduleCalculator.validate(schedule);
                case HKScheduleType.Absolute:
                    var absoluteSchedule = ScheduleCalculator.parseJson<HKAbsoluteSchedule>(schedule);
                    return undefined;
                    //return AbsoluteScheduleCalculator.calculateSchedule(absoluteSchedule, dateTime);
                case HKScheduleType.Relative:
                    var relativeSchedule = ScheduleCalculator.parseJson<HKRelativeSchedule>(schedule);
                    return undefined;
                    //return RelativeScheduleCalculator.calculateSchedule(relativeSchedule, dateTime);
                default:
                    return undefined;
            }
        }
        catch(ex){
            return ex.message;
        }
        return undefined;
    }

    /** 
     * Return description for schedule
     */
    public static getScheduleDescription(scheduleType: HKScheduleType, schedule: string) : string | undefined {

        if(!schedule)
            return undefined;
    
        try {
            switch(scheduleType) {
                case HKScheduleType.Cron:
                    return CronScheduleCalculator.getScheduleDescription(schedule);
                case HKScheduleType.Absolute:
                    var absoluteSchedule = ScheduleCalculator.parseJson<HKAbsoluteSchedule>(schedule);                    
                    return AbsoluteScheduleCalculator.getScheduleDescription(absoluteSchedule);
                case HKScheduleType.Relative:
                    var relativeSchedule = ScheduleCalculator.parseJson<HKRelativeSchedule>(schedule);
                    return RelativeScheduleCalculator.getScheduleDescription(relativeSchedule);
                default:
                    return undefined;
            }
        }
        catch(ex){
            return `Invalid schedule: ${ex.message}` ;
        }
        return undefined;
    }

    static parseSchedule<T>(schedule: string) : T | undefined {

        try {
            return ScheduleCalculator.parseJson<T>(schedule);
        }
        catch(ex) {
            log.error(`Could not parse schedule - Schedule: ${schedule}`, ex);
        }

        return undefined;
    }

    private static parseJson<T>(schedule: string) : T {
        return JSON.parse(schedule) as T;
    }
}