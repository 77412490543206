
import moment from "moment";
import i18n from "../locales/i18n";

abstract class MomentUtils {  

    public static humanizeDuration(duration: moment.Duration): string {
        const durationSec = duration.asSeconds();

        const daysValue = Math.floor(durationSec/(24*3600));       
        const hoursValue = Math.floor((durationSec-daysValue*24*3600)/3600);        
        const minutesValue = Math.floor((durationSec-daysValue*24*3600-hoursValue*3600)/60);

        let description = "";
        if(daysValue > 0)
            description += `${daysValue} ${daysValue > 1 ? i18n.t('days') : i18n.t('day')}`

        if(hoursValue > 0)
            description += ` ${hoursValue} ${hoursValue > 1 ? i18n.t('hours') : i18n.t('hour')}`

        if(minutesValue > 0)
            description += ` ${minutesValue} ${minutesValue > 1 ? i18n.t('minutes') : i18n.t('minute')}`

        return description.trim();
    }
}

export const DATETIME_FORMAT = 'YYYY-MM-DDTHH:mm:ss';
export const DATE_FORMAT = 'YYYY-MM-DD';
export const DATETIME_VISIBLE_FORMAT = 'MM/DD/YYYY HH:mm A';

export default MomentUtils;
