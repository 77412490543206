import React, { Component, Suspense } from 'react';
import { Switch, Route } from 'react-router-dom';
import Loadable from 'react-loadable';
import '../../node_modules/font-awesome/scss/font-awesome.scss';
import Loader from './layout/Loader'
import ScrollToTop from './layout/ScrollToTop';
import routes from "../route";
import { authProvider, AuthContext, PrivateRoute } from "../common/auth";


const AdminLayout = Loadable({
    loader: () => import('./layout/AdminLayout'),
    loading: Loader
});

class App extends Component {
    render() {
        const menu = routes.map((route, index) => {
          return (route.component) ? (
              <Route
                  key={index}
                  path={route.path}
                  exact={route.exact}
                  name={route.name}
                  render={props => (
                      <route.component {...props} />
                  )} />
          ) : (null);
        });

        const isAuthenticated = authProvider.isAuthenticated()
        const isRegisteredDevice = authProvider.isRegisteredDevice()
        const AccountInfo = authProvider.getAccountInfo()

        return (
            <AuthContext.Provider value={{
                isAuthenticated: isAuthenticated,
                isRegisteredDevice: isRegisteredDevice,
                account: AccountInfo
            }}>
                <ScrollToTop>
                    <Suspense fallback={<Loader />}>
                        <Switch>
                            {menu}
                            {<PrivateRoute path="/" component={AdminLayout} />}
                        </Switch>
                    </Suspense>
                </ScrollToTop>
            </AuthContext.Provider>
        );
    }
}

export default App;
