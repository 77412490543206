import { createSlice, createAsyncThunk, createAction } from '@reduxjs/toolkit'
import { AxiosResponse, AxiosError } from "axios";
import _ from 'lodash';
import moment from "moment";
import ErrorUtils from "../../../utils/ErrorUtils";

import * as dom from "../../models/domain";
import apiService from "../../../common/api.service";


const initialState: dom.FacilityState = {
    facilities: [],
    importResidents: [],
    pendingUpdates: {},
    loading: 'idle',
    error: null
}

export class ResidentPhotoNotFoundError extends Error {
    constructor(message: string) {
        super(message);
        this.name = "ResidentPhotoNotFoundError";
    }
}


export const saveFacility = createAsyncThunk(
    'facilities/saveFacility',   
    async (data: dom.SaveFacilityRequest) => {        
        const response = await apiService.post<dom.SaveFacilityRequest>('/facility', data) as AxiosResponse<dom.FacilityData>
        if (response) {
            return response.data
        } else {
            throw new Error('unknown error saving facility');
        }
    }
)

export const generateApiKey = createAsyncThunk(
    'facilities/generateApiKey',   
    async (data: dom.GenerateApiKeyRequest) => {        
        const response = await apiService.post<dom.GenerateApiKeyRequest>('/facility/generateapikey', data) as AxiosResponse<dom.FacilityData>
        if (response) {
            return response.data
        } else {
            throw new Error('unknown error generating ApiKey site');
        }
    }
)

export const generateDataSourceApiKey = createAsyncThunk(
    'facilities/generateDataSourceApiKey',   
    async (data: dom.GenerateApiKeyRequest) => {        
        const response = await apiService.post<dom.GenerateApiKeyRequest>('/facility/generatedatasourceapikey', data) as AxiosResponse<dom.FacilityData>
        if (response) {
            return response.data
        } else {
            throw new Error('unknown error generating Data Source ApiKey Site');
        }
    }
)

export const saveRooms = createAsyncThunk(
    'facilities/saveRooms',   
    async (data: dom.SaveRoomsRequest) => {        
        const response = await apiService.post<dom.SaveRoomsRequest>('/facility/rooms', data) as AxiosResponse<dom.FacilityData>
        if (response) {
            return response.data
        } else {
            throw new Error('unknown error saving facility');
        }
    }
)

export const saveAssignmentTemplates = createAsyncThunk(
    'facilities/assignmentTemplates',   
    async (data: dom.SaveAssignmentTemplatesRequest) => {        
        const response = await apiService.post<dom.SaveAssignmentTemplatesRequest>('/facility/assignmentTemplates', data) as AxiosResponse<dom.FacilityData>
        if (response) {
            return response.data
        } else {
            throw new Error('unknown error saving facility');
        }
    }
)

export const savePbiReports = createAsyncThunk(
    'facilities/pbireports',   
    async (data: dom.SavePbiReportsRequest) => {        
        const response = await apiService.post<dom.SavePbiReportsRequest>('/facility/pbireports', data) as AxiosResponse<dom.FacilityData>
        if (response) {
            return response.data
        } else {
            throw new Error('unknown error saving pbireports');
        }
    }
)

export const saveCalendarImportProfiles = createAsyncThunk(
    'facilities/calendarimportprofiles',   
    async (data: dom.SaveCalendarImportProfilesRequest) => {        
        const response = await apiService.post<dom.SaveCalendarImportProfilesRequest>('/facility/calendarimportprofiles', data) as AxiosResponse<dom.FacilityData>
        if (response) {
            return response.data
        } else {
            throw new Error('unknown error saving CalendarImportProfiles');
        }
    }
)

export const getFacilities = createAsyncThunk(
    'facilities/getFacilities',
    async () => {        
        const response = await apiService.get('/facility') as AxiosResponse<dom.FacilityData[]>
        if (response) {
            return response.data
        } else {
            throw new Error('unknown error loading Sites');
        }
    }
)

export const getFacility = createAsyncThunk(
    'facilities/getFacility',
    async (id: string) => {          
        const response = await apiService.get(`/facility/${id}`) as AxiosResponse<dom.FacilityData>       
        if (response) {
            return response.data
        } else {
            throw new Error('unknown error loading Sites');
        }
    }
)

export const deleteFacility = createAsyncThunk(
    'facilities/deleteFacility',
    async (id: string) => {          
        const response = await apiService.delete(`/facility/${id}`, {}) as AxiosResponse<dom.FacilityData[]>        
        if (response) {
            return response.data
        } else {
            throw new Error('unknown error delete Site');
        }
    }
)

const savePhoto = async (resident: dom.Resident, photo?: dom.PhotoInfo) => {
    if(!!photo?.dataUri) {
        await apiService.uploadPhoto(resident.photo!, photo?.dataUri, "600x600");

    } else if(!!photo?.photoToDelete) {                            
        await apiService.deletePhoto(photo?.photoToDelete);
    }
}

export const saveResident = createAsyncThunk(
    'facility/saveResident',
    // if you type your function argument here
    async (data: dom.SaveResidentRequest) => {
        
        await savePhoto(data.data.resident, data.photo);
        data.photo = undefined;

        const response = await apiService.post<dom.SaveResidentRequest>('/resident', data) as AxiosResponse<dom.Resident>
        if (response) {
            return response.data
        } else {
            throw new Error('unknown error saving resident');
        }
    }
)

export const deleteResident = createAsyncThunk(
    'facility/deleteResident',
    // if you type your function argument here
    async (data: dom.DeleteResidentRequest) => {
        const response = await apiService.delete<dom.DeleteResidentRequest>('/resident', data) as AxiosResponse<void>
        if (response) {
            return response.data
        } else {
            throw new Error('unknown error delete resident');
        }
    }
)


export const checkInOutResident = createAsyncThunk(
    'facilities/checkInOutResident',   
    async (data: dom.CheckInOutRequestData) => {  
        
        await savePhoto(data.data.resident, data.photo);
        data.photo = undefined;
        
        const response = await apiService.post<dom.CheckInOutRequestData>('/resident/checkinout', data) as AxiosResponse<dom.FacilityData>
        if (response) {
            return response.data
        } else {
            throw new Error('unknown error check-in or check-out resident');
        }
    }
)

export const getImportResidents = createAsyncThunk(
    'facilities/getImportResidents',
    async (data: dom.GetResidentsRequest) => {        
        try {
            const response = await apiService.post('/sync/residents', data) as AxiosResponse<dom.ImportResident[]>
            if (response) {
                return response.data
            } else {
                throw new Error('unknown error get import residents');
            }
        } catch (error) {
            // Rethrow exception to keep error from response
            const errorMes = await ErrorUtils.getErrorMessage(error);
            throw new Error(errorMes);
        }
    }
)

export const importResidents = createAsyncThunk(
    'facilities/importResidents',
    async (data: dom.ImportResidentsRequest) => {   
        try {     
            const response = await apiService.post('/sync/importresidents', data) as AxiosResponse<dom.FacilityData>
            if (response) {
                return response.data
            } else {
                throw new Error('unknown error import residents');
            }
        } catch (error) {
              // Rethrow exception to keep error from response
              const errorMes = await ErrorUtils.getErrorMessage(error);
              throw new Error(errorMes);
        }        
    }
)

export const addPendingUpdates = createAsyncThunk(
    'facilities/addPendingUpdates',
    async (data: dom.PendingUpdatesNotification) => {        
        return data;
    }
)

export const updateRoomHistory = createAction<dom.HKReport>('facilities/updateRoomHistory');

export const updateLastMarkingReport = createAction<dom.HKAuditMarkingReport>('facilities/updateLastMarkingReport');

const sliceReducer = createSlice({
    name: 'facilities',
    initialState,
    reducers: {
    },
    extraReducers: builder => {
        // save
        builder.addCase(saveFacility.fulfilled, (state, action) => {
            return {
                ...state,
                facilities: [...state.facilities.filter(f => f.id !== action.payload.id), action.payload],
                loading: 'succeeded',
                error: null,
            }
        })
        builder.addCase(saveFacility.rejected, (state, action) => {
            return { ...state, loading: 'failed', error: action.error }
        })

        // generateApiKey
        builder.addCase(generateApiKey.fulfilled, (state, action) => {
            return {
                ...state,
                facilities: [...state.facilities.filter(f => f.id !== action.payload.id), action.payload],
                loading: 'succeeded',
                error: null,
            }
        })
        builder.addCase(generateApiKey.rejected, (state, action) => {
            return { ...state, loading: 'failed', error: action.error }
        })    
        
        // generateDataSourceApiKey
        builder.addCase(generateDataSourceApiKey.fulfilled, (state, action) => {
            return {
                ...state,
                facilities: [...state.facilities.filter(f => f.id !== action.payload.id), action.payload],
                loading: 'succeeded',
                error: null,
            }
        })
        builder.addCase(generateDataSourceApiKey.rejected, (state, action) => {
            return { ...state, loading: 'failed', error: action.error }
        })    

        // save rooms
        builder.addCase(saveRooms.fulfilled, (state, action) => {
            return {
                ...state,
                facilities: [...state.facilities.filter(f => f.id !== action.payload.id), action.payload],
                loading: 'succeeded',
                error: null,
            }
        })
        builder.addCase(saveRooms.rejected, (state, action) => {
            return { ...state, loading: 'failed', error: action.error }
        })

        // save assignmentTemplates
        builder.addCase(saveAssignmentTemplates.fulfilled, (state, action) => {
            return {
                ...state,
                facilities: [...state.facilities.filter(f => f.id !== action.payload.id), action.payload],
                loading: 'succeeded',
                error: null,
            }
        })
        builder.addCase(saveAssignmentTemplates.rejected, (state, action) => {
            return { ...state, loading: 'failed', error: action.error }
        })
        
        // save pbiReports
        builder.addCase(savePbiReports.fulfilled, (state, action) => {
            return {
                ...state,
                facilities: [...state.facilities.filter(f => f.id !== action.payload.id), action.payload],
                loading: 'succeeded',
                error: null,
            }
        })
        builder.addCase(savePbiReports.rejected, (state, action) => {
            return { ...state, loading: 'failed', error: action.error }
        })

        // save CalendarImportProfiles
        builder.addCase(saveCalendarImportProfiles.fulfilled, (state, action) => {
            return {
                ...state,
                facilities: [...state.facilities.filter(f => f.id !== action.payload.id), action.payload],
                loading: 'succeeded',
                error: null,
            }
        })
        builder.addCase(saveCalendarImportProfiles.rejected, (state, action) => {
            return { ...state, loading: 'failed', error: action.error }
        })

        // load facility
        builder.addCase(getFacility.fulfilled, (state, action) => {
            return {
                ...state,
                facilities: state.facilities.filter(f => f.id !== action.payload.id).concat([action.payload]),
                pendingUpdates: {...state.pendingUpdates, [action.payload.id]: []},
                loading: 'succeeded',
                error: null,
            }
        })
        builder.addCase(getFacility.rejected, (state, action) => {
            return { ...state, loading: 'failed', error: action.error }
        })

        // load all
        builder.addCase(getFacilities.fulfilled, (state, action) => {
            return {
                ...state,
                facilities: action.payload,
                pendingUpdates: {},
                loading: 'succeeded',
                error: null,
            }
        })
        builder.addCase(getFacilities.rejected, (state, action) => {
            return { ...state, loading: 'failed', error: action.error }
        })

        // delete
        builder.addCase(deleteFacility.fulfilled, (state, action) => {
            return {
                ...state,
                facilities: state.facilities.filter((f) => f.id !== action.meta.arg),
                loading: 'succeeded',
                error: null,
            }
        })
        builder.addCase(deleteFacility.rejected, (state, action) => {
            return { ...state, loading: 'failed', error: action.error }
        })

        // Save resident
        builder.addCase(saveResident.fulfilled, (state, action) => {   
            let facility = state.facilities.find(f => f.id === action.meta.arg.data.facilityId);
            if(!!facility) {
                facility = {...facility, residents: [...(facility.residents || []).filter(s => s.id !== action.payload.id), action.payload]};

                if(!!action.meta.arg.cleanTask) {
                    let room = (facility.rooms || []).find(r => r.id === action.meta.arg.cleanTask?.roomId);

                    if(!!room) {
                        room = {...room, outstandingTasks: [...(room.outstandingTasks ?? []), action.meta.arg.cleanTask.task]}

                        facility = {
                            ...facility,
                            rooms: (facility.rooms ?? []).map(r => r.id !== room!.id ? r : room!)
                        }
                    }                    
                }
            }

            return {
                ...state,                 
                facilities: state.facilities.map(f => f.id !== facility!.id ? f : facility!),             
                loading: 'succeeded',
                error: null,
            }
        })
        builder.addCase(saveResident.rejected, (state, action) => {
            return { ...state, loading: 'failed', error: action.error }
        })

        // Delete resident
        builder.addCase(deleteResident.fulfilled, (state, action) => {
            return {
                ...state,
                facilities: state.facilities.map(f => f.id !== action.meta.arg.data.facilityId 
                    ?  f 
                    :  {...f, residents: (f.residents || []).filter(s => s.id !== action.meta.arg.data.id)}),                
                loading: 'succeeded',
                error: null,
            }
        })
        builder.addCase(deleteResident.rejected, (state, action) => {
            return { ...state, loading: 'failed', error: action.error }
        })

        // check-in or check-out resident
        builder.addCase(checkInOutResident.fulfilled, (state, action) => {
            return {
                ...state,
                facilities: [...state.facilities.filter(f => f.id !== action.payload.id), action.payload],
                loading: 'succeeded',
                error: null,
            }
        })
        builder.addCase(checkInOutResident.rejected, (state, action) => {
            return { ...state, loading: 'failed', error: action.error }
        })

        // get import residents
        builder.addCase(getImportResidents.fulfilled, (state, action) => {
            return {
                ...state,
                importResidents: action.payload,
                loading: 'succeeded',
                error: null,
            }
        })
        builder.addCase(getImportResidents.rejected, (state, action) => {
            return { ...state, loading: 'failed', error: action.error }
        })

        // get import residents
        builder.addCase(importResidents.fulfilled, (state, action) => {
            return {
                ...state,
                facilities: [...state.facilities.filter(f => f.id !== action.payload.id), action.payload],
                loading: 'succeeded',
                error: null,
            }
        })
        builder.addCase(importResidents.rejected, (state, action) => {
            return { ...state, loading: 'failed', error: action.error }
        })

        // Update room history
        builder.addCase(updateRoomHistory, (state, action) => {           
           
            let facility = state.facilities.find(f => f.id === action.payload.facility.id);
            let room = facility?.rooms?.find(r => r.id === action.payload.room.id);
            const routine = _.first(action.payload.roomType.routines);
            if(!room || !routine) {
                return {...state};
            }

            let history: dom.HousekeepingHistory = {
                reportId: action.payload.id,
                cleanType: routine.cleanType,
                priority: routine.priority,
                startDate: moment(action.payload.startDate).toJSON(),
                dateComplete: moment(action.payload.reportDate).toJSON(),
                status: action.payload.status,
                staffMember: `${action.payload.staffMember?.firstName} ${action.payload.staffMember?.lastName}`,
                comment: action.payload.comment
            } 
            room = {
                ...room, 
                housekeepingHistory: [...(room.housekeepingHistory ?? []).filter(h => h.cleanType !== routine.cleanType), history]
            };
            facility = {...facility!, rooms: (facility?.rooms ?? []).map(r => r.id !== room?.id ? r : room)}; 

            return {                 
                ...state,
                facilities: [...state.facilities.filter(f => f.id !== facility!.id), facility!],
                loading: 'succeeded',
                error: null
            };
        })

        // Update last marking report
        builder.addCase(updateLastMarkingReport, (state, action) => {           
    
            let facility = state.facilities.find(f => f.id === action.payload.facility.id);
            let room = facility?.rooms?.find(r => r.id === action.payload.room.id);
            const routine = _.first(action.payload.roomType.routines);
            if(!room || !routine) {
                return {...state};
            }
          
            room = {
                ...room, 
                lastMarkingReport: {
                    id: action.payload.id,
                    startDate: action.payload.startDate,
                    cleanType: routine.cleanType,
                    auditor: `${action.payload.staffMember?.firstName} ${action.payload.staffMember?.lastName}`,
                }
            };
            facility = {...facility!, rooms: (facility?.rooms ?? []).map(r => r.id !== room?.id ? r : room)}; 

            return {                 
                ...state,
                facilities: [...state.facilities.filter(f => f.id !== facility!.id), facility!],
                loading: 'succeeded',
                error: null
            };
        }),

        // add pending updates
        builder.addCase(addPendingUpdates.fulfilled, (state, action) => {
            return {
                ...state,
                pendingUpdates: {
                    ...state.pendingUpdates, 
                    [action.payload.facilityId]: [...(state.pendingUpdates[action.payload.facilityId] || []), ...action.payload.notifications]
                },
            }
        })
        builder.addCase(addPendingUpdates.rejected, (state, action) => {
            return { ...state, loading: 'failed', error: action.error }
        })
    }
}).reducer
export default sliceReducer

//https://redux-toolkit.js.org/usage/usage-with-typescript/
