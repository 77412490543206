import React from 'react';
import ReactDOM from 'react-dom';

import { Provider } from 'react-redux';
import { BrowserRouter } from 'react-router-dom';

import { I18nextProvider } from 'react-i18next';
import {ErrorBoundary} from 'react-error-boundary'
import {Button, Card} from 'react-bootstrap';

import i18n from './locales/i18n'
import HistoryListener from './common/HistoryListener'
import App from './App/index';
import * as serviceWorker from './serviceWorker';

import config from './config';
import log from './common/logging';
import store from './store/store'

const componentErrorHandler = (error, info) => {    
    log.error('Error render application', error.message, info.componentStack);
}

function ErrorFallback({error, resetErrorBoundary}) {   
    return (
        <div className="d-flex justify-content-center align-items-center flex-column" style={{height: '100vh'}}>
            <div className='d-flex flex-column justify-content-center align-items-center'>
                <Card style={{ width: '80vw', fontSize: '2rem', color: 'black' }}>
                    <Card.Header className='d-flex flex-column justify-content-center align-items-center'>
                        <div>{i18n.t('The server encountered a temporary error.')}</div>
                        <br/>
                        <div>{i18n.t('Please click the button below to reconnect.')}</div>
                        <br/>
                        <Button onClick={resetErrorBoundary} variant="primary" style={{ fontSize: '2rem' }}>{i18n.t('Try again')}</Button>
                    </Card.Header>                    
                </Card>                
            </div>            
        </div>
    )
  }

const app = (
    <ErrorBoundary
    FallbackComponent={ErrorFallback}
    onError={componentErrorHandler}
    onReset={() => {
        window.location.reload();      
    }}
    >
        <Provider store={store}>
            <BrowserRouter basename={config.basename}>
                <HistoryListener>
                    <I18nextProvider i18n={i18n}>
                        <App />
                    </I18nextProvider>
                </HistoryListener>
            </BrowserRouter>
        </Provider>
    </ErrorBoundary>
);

ReactDOM.render(app, document.getElementById('root'));

if (process.env.NODE_ENV !== 'production') {
    // Make sure that we get all error messages properly displayed
    // as long as we are not in production mode
    window.onerror = (message, source, lineno, colno, error) => {

        const ignored = [];

        if (ignored.some(i => message.toString().includes(i))) {
            // Ignore some error
            return;
        }
        log.error(`Message: ${message}, ${error} at ${source} ${lineno}:${colno}`);
    };
}

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: http://bit.ly/CRA-PWA
serviceWorker.unregister();
