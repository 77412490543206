import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'
import { AxiosResponse } from 'axios';
import apiService from '../../../common/api.service';
import {enqueuOfflineAction} from '../../../common/offline/actions';
import _ from "lodash";

import log from '../../../common/logging';
import * as dom from "../../models/domain";
import {updateRoomHistory, updateLastMarkingReport} from "./facilities";
import ImageUtils from "../../../utils/ImageUtils";
import { imageCache } from "../../../common/dataCache";
import { ImageData } from "../../../common/offline/types";
import { HKClean } from '../../models/domain';
import i18n from "../../../locales/i18n";

const initialState: dom.HousekeepingState = {
    config: {
        id: "",
        roomTypes: []
    },
    reports: {},
    auditMarkingReports: [],
    cleans: [],
    overdueReports: [],
    dailyStaffReports: [],
    loading: 'idle',
    error: null
}

export const loadHousekeepingConfig = createAsyncThunk(
    'housekeeping/loadHousekeepingConfig',
    async () => {
        const response = await apiService.get('/housekeeping?') as AxiosResponse<dom.HousekeepingData[]>
        if (response) {
            if (response.data && response.data.length > 0) return response.data[0]
            else return initialState.config
        } else {
            throw new Error('unknown error loading housekeeping config')
        }
    }
)

export const updateHousekeepingConfig = createAsyncThunk(
    'housekeeping/updateHousekeepingConfig',
    // if you type your function argument here
    async (data: dom.HousekeepingData) => {
        
        for(const image of (data.operationImages ?? []).filter(p => !!p.dataUri)) {
            
            const blobData = ImageUtils.dataUriToBlob(image.dataUri!);
            const formData = new FormData()
            formData.append(
                'Image',
                blobData,
                image.image
            );

            try {
                await apiService.post('files', formData);             
            } catch (ex) {
                throw new Error('cannot save operation details image: ' + (ex as Error).message);
            }
            
            const request = `files/${image.image}`;
            await imageCache.add(request, image?.dataUri);
        }

        const housekeepingData:  dom.HousekeepingData = {
            ...data,
            operationImages: (data.operationImages || []).map(i => !i.dataUri ? i : {...i, dataUri: undefined})
        };

        const response = await apiService.post<dom.HousekeepingData>('/housekeeping/update', housekeepingData) as AxiosResponse<dom.HousekeepingData>
        if (response) {
            return response.data
        } else {
            throw new Error('unknown error for saving housekeepoing')
        }
    }
)

const getPhotoData = async (photo: dom.HKPhoto) => {
    // Save photo       
    const blobData = ImageUtils.dataUriToBlob(photo.dataUri!);
    const formData = new FormData()
    formData.append(
        'Image',
        blobData,
        photo.photo
    );

    formData.append(
        'Resize',
        "600x600"
    );

    const request = `files/${photo.photo}`;
    await imageCache.add(request, photo?.dataUri);

    return formData;
}

export const saveHousekeepingReport = createAsyncThunk(
    'housekeeping/saveHousekeepingReport',
    async (data: dom.HKReport, thunkAPI) => {
        const url = '/housekeeping/submitReport';
        const photosUrl = 'files/image';

        const photosToUpload: FormData[] = [];
        const imageDatas: ImageData[] = [];
        for(const photo of [...(data.photos ?? []), ...(data.auditReport?.photos ?? [])].filter(p => !!p.dataUri)) {
            const photoData = await getPhotoData(photo);
            photosToUpload.push(photoData);
            imageDatas.push({
                dataUri: photo.dataUri!,
                fileName: photo.photo,
                resize: "600x600"
            })
        }

        const reportData = {
            ...data, 
            photos: (data.photos ?? []).map(p => ({...p, dataUri: undefined})),
            auditReport: !!data.auditReport
                ? {
                    ...data.auditReport,
                    photos: (data.auditReport.photos ?? []).map(p => ({...p, dataUri: undefined}))
                }
                : undefined
        };


        thunkAPI.dispatch(updateRoomHistory(data));
        try { 
            // Upload new photos
            for(const photoData of photosToUpload) {
                await apiService.post(photosUrl, photoData);
            }

            const response = await apiService.post<dom.HKReport>(url, reportData) as AxiosResponse<dom.HKReport>
            if (response) {                
                return response.data
            } else {
                throw new Error('unknown error housekeeping report')
            }
        } catch (error) {
            log.error("Couldn't save housekeeping report", reportData, error);
        }

        for(const imageData of imageDatas) {
            thunkAPI.dispatch(enqueuOfflineAction(i18n.t("Upload Housekeeping report photo"), {
                effect: {
                    url: photosUrl,
                    json: imageData,
                    method: "POST"
                }
            }));
        }
        
        thunkAPI.dispatch(enqueuOfflineAction(i18n.t("Save Housekeeping report"), {
            effect: {
                url: url,
                json: reportData,
                method: "POST"
            }
        }));

        // Optimistically update store and queue offline actions
        return reportData;
    }    
)


export const saveAuditMarkingReport = createAsyncThunk(
    'housekeeping/saveAuditMarkingReport',
    async (data: dom.HKAuditMarkingReport, thunkAPI) => {
        const url = '/housekeeping/submitmarkingreport';

        thunkAPI.dispatch(updateLastMarkingReport(data));
        try { 
            const response = await apiService.post<dom.HKAuditMarkingReport>(url, data) as AxiosResponse<dom.HKAuditMarkingReport>
            if (response) {                
                return response.data
            } else {
                throw new Error('unknown error audit marking report')
            }
        } catch (error) {
            log.error("Couldn't save audit marking report", data, error);
        }       
        
        thunkAPI.dispatch(enqueuOfflineAction(i18n.t("Save Audit Marking report"), {
            effect: {
                url: url,
                json: data,
                method: "POST"
            }
        }));

        // Optimistically update store and queue offline actions
        return data;
    }    
)


export const updateHKReportProps = createAsyncThunk(
    'housekeeping/updateHKReportProps',
    // if you type your function argument here
    async (data: dom.HKReportSupervisorProps) => {
        const response = await apiService.post<dom.HKReportSupervisorProps>('/housekeeping/updatereportprops', data) as AxiosResponse<dom.HKReportSupervisorProps>
        if (response) {
            return response.data
        } else {
            throw new Error('could not update housekeeping report data')
        }
    }
)

export const loadHousekeepingReports = createAsyncThunk(
    'housekeeping/loadHousekeepingReports',
    async (query?: dom.HousekeepingReportQuery) => {
        const response = await apiService.post('/housekeeping/reports', query) as AxiosResponse<dom.HKReport[]>
        if (response) {
            if (response.data && response.data.length > 0)
                return response.data.reduce((obj, cur, i) => { return { ...obj, [cur.id]: cur } }, {})
            else return initialState.reports
        } else {
            throw new Error('unknown error loading housekeeping report data')
        }
    }
)

export const loadHousekeepingCleans = createAsyncThunk(
    'housekeeping/loadHousekeepingCleans',
    async (query?: dom.HousekeepingCleansRequest) => {        
        const response = await apiService.post('/housekeeping/cleans', query) as AxiosResponse<dom.HKClean[]>
        if (response) {
            return response.data;
        } else {
            throw new Error('unknown error loading housekeeping clean data')
        }
    }
)

export const loadAuditMarkingReports = createAsyncThunk(
    'housekeeping/loadAuditMarkingReports',
    async (query?: dom.HKAuditMarkingReportQuery) => {
        const response = await apiService.post('/housekeeping/markingreports', query) as AxiosResponse<dom.HKAuditMarkingReport[]>
        if (response) {
            return response.data;
        } else {
            throw new Error('unknown error loading marking report data')
        }
    }
)

export const loadOverdueReports = createAsyncThunk(
    'housekeeping/loadOverdueReports',
    async (query?: dom.HKOverdueReportQuery) => {
        const response = await apiService.post('/housekeeping/overduereports', query) as AxiosResponse<dom.HKOverdueReport[]>
        if (response) {
            return response.data;
        } else {
            throw new Error('unknown error loading overdue report data')
        }
    }
)


export const loadDailyStaffReports = createAsyncThunk(
    'housekeeping/loadDailyStaffReports',
    async (query?: dom.DailyStaffReportQuery) => {
        const response = await apiService.post('/housekeeping/staffreports', query) as AxiosResponse<dom.DailyStaffReport[]>
        if (response) {
            return response.data;
        } else {
            throw new Error('unknown error loading daily staff reports')
        }
    }
)

export const saveDailyStaffReport = createAsyncThunk(
    'housekeeping/saveDailyStaffReport',
    // if you type your function argument here
    async (data: dom.DailyStaffReport) => {
        const response = await apiService.post<dom.DailyStaffReport>('/housekeeping/savestaffreport', data) as AxiosResponse<dom.DailyStaffReport>
        if (response) {
            return response.data
        } else {
            throw new Error('could not save daily staff report')
        }
    }
)

const sliceReducer = createSlice({
    name: 'housekeeping',
    initialState,
    reducers: {
    },
    extraReducers: builder => {
        // load
        builder.addCase(loadHousekeepingConfig.fulfilled, (state, action) => {
            return {
                ...state,
                config: { ...action.payload },
                loading: 'succeeded',
                error: null,
            }
        })
        builder.addCase(loadHousekeepingConfig.rejected, (state, action) => {
            return { ...state, loading: 'failed', error: action.error }
        })

        // update
        builder.addCase(updateHousekeepingConfig.fulfilled, (state, action) => {
            return {
                ...state,
                config: { ...action.payload },
                loading: 'succeeded',
                error: null,
            }
        })
        builder.addCase(updateHousekeepingConfig.rejected, (state, action) => {
            return { ...state, loading: 'failed', error: action.error }
        })

        // saveReport
        builder.addCase(saveHousekeepingReport.fulfilled, (state, action) => {
            
            const cleanRoutine = _.first(action.payload.roomType.routines);
            const hkClean: HKClean = {
                id: action.payload.id,
                status: action.payload.status,
                startDate: action.payload.startDate,
                reportDate: action.payload.reportDate,
                cleanType: cleanRoutine?.cleanType,
                priority: cleanRoutine?.priority,
                color: cleanRoutine?.color,
                room: {
                    id: action.payload.room.id,
                    number: action.payload.room.number
                },
                facility: action.payload.facility,
                resident: !action.payload.resident ? undefined : { 
                        id: action.payload.resident!.id!, 
                        name: `${action.payload.resident!.firstName} ${action.payload.resident!.lastName}`,
                        residentId: action.payload.resident!.residentId!
                    },
                staffMember: !action.payload.staffMember ? undefined : { 
                        staffRefId: action.payload.staffMember!.id!, 
                        name: `${action.payload.staffMember!.firstName} ${action.payload.staffMember!.lastName}`,
                        role: action.payload.staffMember!.role
                    },
            }

            return {
                ...state,
                reports: { ...state.reports, [action.payload.id]: action.payload },
                cleans: [...state.cleans.filter(c => c.id !== action.payload.id), hkClean]
            }
        })

        // update report props
        builder.addCase(updateHKReportProps.fulfilled, (state, action) => {
            const report = state.reports[action.payload.id];
            return {
                
                ...state,
                reports: { ...state.reports, [action.payload.id]: {...report, supervisorNote: action.payload.supervisorNote}}
            }
        })
        builder.addCase(updateHKReportProps.rejected, (state, action) => {
            return { ...state, loading: 'failed', error: action.error }
        })

        // load reports
        builder.addCase(loadHousekeepingReports.fulfilled, (state, action) => {
            return {
                ...state,
                reports: action.meta.arg?.reload 
                    ? action.payload
                    : { ...state.reports, ...action.payload },
                loading: 'succeeded',
                error: null,
            }
        })
        builder.addCase(loadHousekeepingReports.rejected, (state, action) => {
            return { ...state, loading: 'failed', error: action.error }
        })

        // load cleans
        builder.addCase(loadHousekeepingCleans.fulfilled, (state, action) => {            
            return {
                ...state,
                cleans: action.payload,
                loading: 'succeeded',
                error: null,
            }
        })
        builder.addCase(loadHousekeepingCleans.rejected, (state, action) => {
            return { ...state, loading: 'failed', error: action.error }
        })

        // load audit marking reports
        builder.addCase(loadAuditMarkingReports.fulfilled, (state, action) => {            
            return {
                ...state,
                auditMarkingReports: action.payload,
                loading: 'succeeded',
                error: null,
            }
        })
        builder.addCase(loadAuditMarkingReports.rejected, (state, action) => {
            return { ...state, loading: 'failed', error: action.error }
        })

        // load overdue reports
        builder.addCase(loadOverdueReports.fulfilled, (state, action) => {            
            return {
                ...state,
                overdueReports: action.payload,
                loading: 'succeeded',
                error: null,
            }
        })
        builder.addCase(loadOverdueReports.rejected, (state, action) => {
            return { ...state, loading: 'failed', error: action.error }
        })

        // load daily staff reports
        builder.addCase(loadDailyStaffReports.fulfilled, (state, action) => {            
            return {
                ...state,
                dailyStaffReports: action.payload,
                loading: 'succeeded',
                error: null,
            }
        })
        builder.addCase(loadDailyStaffReports.rejected, (state, action) => {
            return { ...state, loading: 'failed', error: action.error }
        })

        // save daily staff report
        builder.addCase(saveDailyStaffReport.fulfilled, (state, action) => {            
            return {
                
                ...state,
                dailyStaffReports: [...state.dailyStaffReports.filter(r => r.id !== action.payload.id), action.payload]
            }
        })
        builder.addCase(saveDailyStaffReport.rejected, (state, action) => {
            return { ...state, loading: 'failed', error: action.error }
        })

        // save audit marking report
        builder.addCase(saveAuditMarkingReport.fulfilled, (state, action) => {            
            return {
                
                ...state,
                auditMarkingReports: [...state.auditMarkingReports.filter(r => r.id !== action.payload.id), action.payload]
            }
        })
        builder.addCase(saveAuditMarkingReport.rejected, (state, action) => {
            return { ...state, loading: 'failed', error: action.error }
        })
    }
}).reducer
export default sliceReducer

//https://redux-toolkit.js.org/usage/usage-with-typescript/
