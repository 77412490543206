abstract class ColorUtils {
    public static getRandomColor() {
        const randomColor = Math.floor(Math.random()*16777215).toString(16);
        return "#" + randomColor;
    }

    public static getContrastColor(color: string) {
        const matches = color.match(/^#([0-9a-f]{6})$/i);
        if(!!matches && matches.length > 0) {
            const m = matches[1];            
            const r =parseInt(m.substr(0,2),16);
            const g = parseInt(m.substr(2,2),16);
            const b = parseInt(m.substr(4,2),16);

            if ((r*0.299 + g*0.587 + b*0.114) <=  186)
                return '#ffffff';
        }
        return '#000000';
    }    
}

export default ColorUtils;
