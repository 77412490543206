import * as React from 'react';
import { withFormik, FormikProps } from 'formik';
import * as Yup from 'yup';
import { withTranslation } from 'react-i18next';
import {Row, Col, Form } from 'react-bootstrap';
import 'react-bootstrap-typeahead/css/Typeahead.css';
import QrReader from 'react-qr-reader'

export interface FormValues {
    qrCode?: string;
}

const QRReaderForm = (props: any & FormikProps<FormValues>) => {
    const {
        // Formik HOC props
        values,
        touched,
        errors,
        isSubmitting,
        handleSubmit,
        getFieldProps,
        setFieldValue,


        // Form props
        submitLabel,
        cancelLabel,
        onCancel,        
        cantCancel,

        // Translate props
        t,
    }: any = props;

    const handleScan = (code: string) => {
        if (code && code !== null) setFieldValue('qrCode', code)
    }

    return (
        <Row className="justify-content-md-center">
            <Col md={12} lg={10}>
                <Form id="qrCodeForm" onSubmit={(e) => { handleSubmit(e as React.FormEvent<HTMLFormElement>) }} className="p-1">
                    <Form.Group>
                        <Form.Control type="text" name="qrCode" id="qrCode"
                            className={`form-control mx-auto ${errors.qrCode && touched.qrCode && 'is-invalid'}`}
                            style={{ width: '60%' }}
                            {...getFieldProps('qrCode')} isInvalid={!!errors.qrCode} />
                        <Form.Control.Feedback type="invalid" className="text-left">{t(errors.qrCode)}</Form.Control.Feedback>
                    </Form.Group>

                    <div className="mx-auto" style={{ width: '60%' }}>
                        <QrReader
                            delay={300}
                            onScan={handleScan}
                        />
                    </div>
                    
                    
                    {/* Mimik sweetalert2 buttons */}
                    <div className="swal2-actions" style={{'fontSize': '16px'}}>
                        <button className="swal2-confirm swal2-styled" type="submit" >{submitLabel}</button>
                        <button className="swal2-cancel swal2-styled" type="button" onClick={() => { onCancel(values) }} disabled={isSubmitting || cantCancel}>{cancelLabel}</button>
                    </div>
                </Form>
            </Col>
        </Row>
    );
};

const validate = withFormik<any, FormValues>({
    mapPropsToValues: props => ({
        qrCode: props?.values?.qrCode
    }),

    validationSchema: (props: any) => {
        return Yup.object().shape({
            qrCode: Yup.string().nullable()
    })},

    handleSubmit: (values, { props }) => {
        props.onSubmit(values);
    },
});

const translate = withTranslation();
export default translate(validate(QRReaderForm));
