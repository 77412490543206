import React from 'react';
import ComponentLoader from './App/components/ComponentLoader'
import { PolicyRoles } from './common/auth/AuthProvider'

//Static import housekeeping app to try and prevent lazy loading issues
//const HousekeepingApp = React.lazy(() => ComponentLoader(() => import('./App/pages/Housekeeping'));
import HousekeepingApp from './App/pages/Housekeeping'

const SystemAdminTenantOnboard = React.lazy(() => ComponentLoader(() => import('./App/pages/SystemAdmin/Onboarding/OnboardingTenantWizard')));
const OrganizationList = React.lazy(() => ComponentLoader(() => import('./App/pages/SystemAdmin/OrganizationList')));
const TenantEdit = React.lazy(() => ComponentLoader(() => import('./App/pages/SystemAdmin/OrganizationList/EditTenant')));

const ManageSystemUsers = React.lazy(() => ComponentLoader(() => import('./App/pages/SystemAdmin/SystemUsers/index')));
const EditSystemUser = React.lazy(() => ComponentLoader(() => import('./App/pages/SystemAdmin/SystemUsers/EditSystemUser')));


const ManageFacilities = React.lazy(() => ComponentLoader(() => import('./App/pages/TenantAdmin/Facility/ManageFacilities')));
const CreateFacilityWizard = React.lazy(() => ComponentLoader(() => import('./App/pages/TenantAdmin/Facility/CreateFacilityWizard')));
const EditFacility = React.lazy(() => ComponentLoader(() => import('./App/pages/TenantAdmin/Facility/EditFacility')));
const SyncDataSources = React.lazy(() => ComponentLoader(() => import('./App/pages/TenantAdmin/Facility/SyncDataSources')));
const EditSyncDataSource = React.lazy(() => ComponentLoader(() => import('./App/pages/TenantAdmin/Facility/EditSyncDataSource')));

const ManageStaff = React.lazy(() => ComponentLoader(() => import('./App/pages/TenantAdmin/Staff/ManageStaff')));
const EditStaff = React.lazy(() => ComponentLoader(() => import('./App/pages/TenantAdmin/Staff/EditStaff')));

const ManageRooms = React.lazy(() => ComponentLoader(() => import('./App/pages/FacilityAdmin/Room')));
const ManageCleanTypes = React.lazy(() => ComponentLoader(() => import('./App/pages/FacilityAdmin/Housekeeping/ManageCleanTypes')));
const ManageCleanShortcuts = React.lazy(() => ComponentLoader(() => import('./App/pages/FacilityAdmin/Housekeeping/ManageCleanShortcuts')));
const ManageRoomStates = React.lazy(() => ComponentLoader(() => import('./App/pages/FacilityAdmin/Housekeeping/ManageRoomStates')));
const ManageRoomEvents = React.lazy(() => ComponentLoader(() => import('./App/pages/FacilityAdmin/Housekeeping/ManageRoomEvents')));
const ManageResidents = React.lazy(() => ComponentLoader(() => import('./App/pages/FacilityAdmin/Resident')));
const EditResident = React.lazy(() => ComponentLoader(() => import('./App/pages/FacilityAdmin/Resident/EditResident')));
const ImportResidents = React.lazy(() => ComponentLoader(() => import('./App/pages/FacilityAdmin/Resident/ImportResidents')));
const StaffSchedule = React.lazy(() => ComponentLoader(() => import('./App/pages/FacilityAdmin/StaffSchedule')));
const Reports = React.lazy(() => ComponentLoader(() => import('./App/pages/Reporting/Reports')));
const ReportPortal = React.lazy(() => ComponentLoader(() => import('./App/pages/Reporting/Portal')));
const PowerBIDashboard = React.lazy(() => ComponentLoader(() => import('./App/pages/Reporting/PowerBIDashboard')));
const DailyBriefings = React.lazy(() => ComponentLoader(() => import('./App/pages/FacilityAdmin/DailyBriefings')));
const Acknowledgements = React.lazy(() => ComponentLoader(() => import('./App/pages/FacilityAdmin/Acknowledgements')));
const StaffDashboard = React.lazy(() => ComponentLoader(() => import('./App/pages/StaffDashboard')));
const CalendarSync = React.lazy(() => ComponentLoader(() => import('./App/pages/FacilityAdmin/CalendarSync')));
const PbiReportsConfig = React.lazy(() => ComponentLoader(() => import('./App/pages/FacilityAdmin/PbiReports')));

const routes = [
    { path: '/admin/onboardtenant', exact: true, name: 'Onboard Tenant', component: SystemAdminTenantOnboard, role: PolicyRoles.SiteAdministrator, facilityIndep: true },
    { path: '/admin/tenant', exact: true, name: 'Organizations', component: OrganizationList, role: PolicyRoles.SiteAdministrator, facilityIndep: true },
    { path: '/admin/tenant/edit/:id', exact: true, name: 'Edit Tenant', component: TenantEdit, role: PolicyRoles.SiteAdministrator, facilityIndep: true },

    { path: '/admin/systemusers', exact: true, name: 'Manage System Users', component: ManageSystemUsers, role: PolicyRoles.SiteAdministrator },
    { path: '/admin/systemusers/edit/:id', exact: true, name: 'Edit System User', component: EditSystemUser, role: PolicyRoles.SiteAdministrator },
    { path: '/admin/systemusers/new', exact: true, name: 'New System User', component: EditSystemUser, role: PolicyRoles.SiteAdministrator },

    { path: '/admin/facilities', exact: true, name: 'Manage Sites', component: ManageFacilities, role: PolicyRoles.MultiFacilityAdministrator, facilityIndep: true },
    { path: '/admin/facilities/new', exact: true, name: 'Create New Site', component: CreateFacilityWizard, role: PolicyRoles.MultiFacilityAdministrator, facilityIndep: true },
    { path: '/admin/facilities/edit/:id', exact: true, name: 'Edit Site', component: EditFacility, role: PolicyRoles.MultiFacilityAdministrator, facilityIndep: true },
    { path: '/admin/facilities/:facilityid/syncdatasources', exact: true, name: 'Facility Sync Data Sources', component: SyncDataSources, role: PolicyRoles.MultiFacilityAdministrator, facilityIndep: true },
    { path: '/admin/facilities/:facilityid/syncdatasources/:id', exact: true, name: 'Edit Sync Data Source', component: EditSyncDataSource, role: PolicyRoles.MultiFacilityAdministrator, facilityIndep: true },


    { path: '/admin/rooms', exact: true, name: 'Manage Rooms', component: ManageRooms, role: PolicyRoles.FacilityAdministrator },
    { path: '/admin/housekeepingconfig', exact: true, name: 'Housekeeping Configuration', component: ManageCleanTypes, role: PolicyRoles.FacilityAdministrator },
    { path: '/admin/housekeepingshortcuts', exact: true, name: 'Housekeeping Shortcuts', component: ManageCleanShortcuts, role: PolicyRoles.FacilityAdministrator },
    { path: '/admin/roomstateconfig', exact: true, name: 'Room States', component: ManageRoomStates, role: PolicyRoles.FacilityAdministrator },
    { path: '/admin/roomeventconfig', exact: true, name: 'Room Events', component: ManageRoomEvents, role: PolicyRoles.FacilityAdministrator },    

    { path: '/admin/staff', exact: true, name: 'Manage Staff Members', component: ManageStaff, role: PolicyRoles.FacilityAssistant },
    { path: '/admin/staff/edit/:id', exact: true, name: 'Edit Staff Member', component: EditStaff, role: PolicyRoles.FacilityAssistant },
    { path: '/admin/staff/new', exact: true, name: 'New Staff Member', component: EditStaff, role: PolicyRoles.FacilityAssistant },
    { path: '/admin/resident', exact: true, name: 'Manage Residents', component: ManageResidents, role: PolicyRoles.Nursing },
    { path: '/admin/resident/edit/:id', exact: true, name: 'Edit Resident', component: EditResident, role: PolicyRoles.Nursing },
    { path: '/admin/resident/new', exact: true, name: 'New Resident', component: EditResident, role: PolicyRoles.Nursing },
    { path: '/admin/resident/import', exact: true, name: 'Import Residents', component: ImportResidents, role: PolicyRoles.Nursing },
    { path: '/admin/dailybriefings', exact: true, name: 'Daily Briefings', component: DailyBriefings, role: PolicyRoles.MultiFacilityAdministrator },
    { path: '/admin/acknowledgements', exact: true, name: 'Acknowledgements', component: Acknowledgements, role: PolicyRoles.Nursing },
    { path: '/admin/pbireportsconfig', exact: true, name: 'PbiReportsConfig', component: PbiReportsConfig, role: PolicyRoles.SiteAdministrator },

    { path: '/facility/staffschedule', exact: true, name: 'Staff Schedule', component: StaffSchedule, role: PolicyRoles.Nursing },
    { path: '/facility/calendarsync', exact: true, name: 'Calendar Synchronization', component: CalendarSync, role: PolicyRoles.Nursing },
    
    { path: '/reporting/reports', exact: true, name: 'Reports', component: Reports, role: PolicyRoles.Nursing },    
    { path: '/reporting/portal', exact: true, name: 'Report Portal', component: ReportPortal, role: PolicyRoles.FacilityAssistant },
    { path: '/facilitydashboard', exact: true, name: 'Power BI Dashboard', component: PowerBIDashboard, role: PolicyRoles.FacilityAssistant },
    

    //Housekeeping App
    { path: '/housekeeping', exact: true, name: 'Housekeeping Dashboard', component: HousekeepingApp, role: PolicyRoles.Housekeeper, appMode: true },
    { path: '/staffdashboard', exact: true, name: 'Staff Dashboard', component: StaffDashboard, role: PolicyRoles.Housekeeper, appMode: false  },
];

export const DefaultRoutes = [
    { path: '/housekeeping', role: PolicyRoles.Housekeeper },
    { path: '/facility/staffschedule', default: true }
];

export default routes
