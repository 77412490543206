import { setLocale } from 'yup';

const setYupLocale = () => setLocale({
    // use constant translation keys for messages without values
    mixed: {
        required: 'Yup.Required',
    },
    string: {
        email: 'Yup.Email'
    },
    // use functions to generate an error object that includes the value from the schema
    number: {
        min: ({ min }) => ({ key: 'Yup.TooShort', values: { min } }),
        max: ({ max }) => ({ key: 'Yup.TooBig', values: { max } }),
    },
});

export default setYupLocale;
