import { SerializedError } from '@reduxjs/toolkit'
import * as residentDomain from "../../models/domain/resident";
import {HKReportStatus, HKAuditMarkingReportRef} from "../../models/domain/housekeeping";
import { Dictionary } from '../../../common/utilityTypes';


export interface FacilityState {
    facilities: Array<FacilityData>
    importResidents: residentDomain.ImportResident[],
    pendingUpdates: Dictionary<UpdateNotification[]>,
    loading: 'idle' | 'pending' | 'succeeded' | 'failed'
    error: SerializedError | null    
}

export interface HousekeepingHistory {
    reportId: string;
    cleanType: string;
    priority?: number;
    startDate?: string;
    dateComplete?: string;
    status?: HKReportStatus;
    staffMember?: string;
    comment?: string;
}

export enum RoomTaskType {
    Clean="Clean"
}

export interface RoomTask {
    id: string;    
    taskType: RoomTaskType;
    description?: string;
    dueDate?: string;
    queueDate?: string;
    cleanType?: string;
    startDate?: string;
    endDate?: string;
    assignmentId?: string;    
    roomEventId?: string;    
}

/** Extends RoomTask with fields needed for saving */
export interface RoomTaskEx extends RoomTask {
    roomId: string;    
}

export interface HKRoomEventTime {
    roomEventId: string;
    /** Sunday - 0,...Saturday - 6 */
    dayOfWeek: number;
    startTime: string;
    endTime: string;
    timeZoneName?: string
}

export interface Room {
    id: string
    roomNumber: string
    facilityArea: string[]
    description: string
    roomType: string
    isArchived?: boolean
    housekeepingHistory?: HousekeepingHistory[]
    outstandingTasks?: RoomTask[]
    qrCode?: string
    roomState?: string,
    stateCleanType?: string,
    roomEvents?: HKRoomEventTime[],
    lastMarkingReport?: HKAuditMarkingReportRef,
    syncId?: string,
    syncFacilityId?: string;
}


export interface AssignmentTemplate {
    id: string,
    description: string,
    type: string,
    color?: string,
    /** 
     * Week days when task is active - Sunday - 0,...Saturday - 6
     * currently is applied only for Rooms Dashboard filtering 
    */
    scheduledDays?: number[],
    roomType?: string,
    cleanType?: string
    facilityAreas?: string[][],
    roomNumbers?: string[]
}

export interface ApiKey {
    id: string;
    role: string;
    key: string;
    created: string;
}

export interface PbiReportRef {
    url: string;
    name: string;
    isDefault: boolean;
    roles: string[];
}

export interface CalendarImportProfile {
    id: string;
    name: string;
    fieldMappings: CalendarFieldMapping[];
    importFilters: CalendarImportFilter[];
    valueMappings: CalendarValueMapping[];
}

export interface CalendarFieldMapping {
    sourceField?: string;
    targetField: string;
}

export interface CalendarImportFilter {
    id: string;
    fieldName?: string;
    regEx?: string;
}

export interface CalendarValueMapping {
    fieldName: string;
    originalValue?: string;
    replaceValue?: string;
}

export interface FacilityData {
    id: string
    name: string,
    rooms?: Room[],
    residents?: residentDomain.Resident[],
    assignmentTemplates?: AssignmentTemplate[],
    jurisdictions?: string[],
    timeZoneName?: string,
    apiKey?: ApiKey,
    dataSourceApiKey?: ApiKey,
    pbiReports?: PbiReportRef[],
    calendarImportProfiles?: CalendarImportProfile[],
    syncId?: string
}

export interface SaveFacilityRequest {
    data: {        
        id?: string,
        name: string,
        jurisdictions?: string[]      
    }
}

export interface GenerateApiKeyRequest {
    data: {        
        id: string   
    }
}

export interface SaveRoomsRequest {
    data: {        
        id: string
        rooms: Room[]        
    }
}

export interface SaveAssignmentTemplatesRequest {
    data: {        
        id: string
        assignmentTemplates?: AssignmentTemplate[]       
    }
}

export interface SavePbiReportsRequest {
    data: {        
        id: string
        pbiReports?: PbiReportRef[]       
    }
}

export interface SaveCalendarImportProfilesRequest {
    data: {        
        id: string
        calendarImportProfiles?: CalendarImportProfile[]     
    }
}

export interface UpdateNotification {
    message: string
}

export interface PendingUpdatesNotification{
    notifications: UpdateNotification[],
    facilityId: string
}

