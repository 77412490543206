
import cronparser from "cron-parser";

abstract class CronUtils {  

    public static isExpressionValid(schedule?: string) {
        if(!schedule) {
            return true;
        }
    
        try {
            cronparser.parseExpression(schedule);
        } catch (error) {
            return false;
        }
        return true;
    }
}

export default CronUtils;
