import * as React from 'react';
import { withFormik, FormikProps } from 'formik';
import * as Yup from 'yup';
import { withTranslation, WithTranslation } from 'react-i18next';
import {Row, Col, Form, Card} from 'react-bootstrap';
import "react-bootstrap-typeahead/css/Typeahead.css";
import styled from 'styled-components'

import Image from '../../components/Image';
import {CleanOperationImage} from '../../../store/models/domain'


const Styles = styled.div`   
    $imageMargin: 2px;

    .imageBox {
        min-width: 100% !important;
        max-width: 100% !important;
        min-height: 85vh !important;
        max-height: 85vh !important;
        border: none !important;
        padding: $padding;
        margin: $imageMargin;           

        img {
            width: 100% !important;
            height: 100% !important;
            min-width: 100% !important;
            max-width: 100% !important;
            min-height: 100% !important;
            max-height: 100% !important;
            object-fit: contain;
        }
    }



    .imageGallery {
        overflow-y: auto;
        max-height: 90vh;
    }
    `;

interface Props extends WithTranslation {        
}

export interface FormValues {      
    images: CleanOperationImage[]
}

const OperationTaskDetails = (props: Props & FormikProps<FormValues>) => {
        const {
            // Formik HOC props
            values,
            handleSubmit,
    
            // Form props
            onCancel,  
    
            // Translate props
            t,
        }: any = props;

        const [loaded, setLoaded] = React.useState<boolean>(false);
        React.useEffect(() => setLoaded(true), []);
        
        
        const PhotoRenderItem: React.FunctionComponent<{photo: CleanOperationImage, onClick(photo: CleanOperationImage): void}> = 
            ({ photo, onClick }) => {                
    
            return (
                <Card key={"ph_" + photo.image} className="imageBox">
                    <Image dataUri={photo.dataUri} srcFile={photo.image} hideBorder={true} style={{width: '100%', height: '100%'}} onClick={() => onClick(photo)} title={t('Click to close')} />                    
                </Card>
            );
        };     
    
        return (
            <Row className="justify-content-md-center" >
                <Col sm={12} md={12}>
                    <Styles>
                        <Form onSubmit={(e) => { handleSubmit(e as React.FormEvent<HTMLFormElement>) }} className="p-1">                            
        
                            <Form.Group>                                
                                <Row xs={1} md={1} className="imageGallery">
                                    {values.images
                                        .map((photo: CleanOperationImage) => (
                                            <Col key={"col_" + photo.image}>
                                                <PhotoRenderItem key={"ph_" + photo.image} photo={photo} onClick={() => {if(loaded) onCancel(values);}}  />
                                            </Col>
                                        ))}
                                </Row>                               
                            </Form.Group>                           
                        </Form>
                    </Styles>   
                </Col>
            </Row>
        );
    };
    
    const validate = withFormik<any, FormValues>({
        mapPropsToValues: props => ({            
            images:  props.values?.images || []
        }),
    
        validationSchema: (props: any) => {
            return Yup.object().shape({               
        })},
    
        handleSubmit: (values, { props }) => {
            props.onSubmit(values);
        },
    });
    
    const translate = withTranslation();
    export default translate(validate(OperationTaskDetails));
