import mime from 'mime-types';
import { Base64 } from 'js-base64';

abstract class ImageUtils {

    public static binaryToDataUri(data: any, imageType: string) {
        const mediaType = mime.lookup(imageType);
        return `data:${mediaType};base64,${Buffer.from(data, 'binary').toString('base64')}`;
    }

    public static dataUriToBlob(dataUrl: string) {
        const arr = dataUrl.split(',') as string[];
        const match = arr[0].match(/:(.*?);/);
        const mime = match![1];
        const bstr = atob(arr[1]);
        let n = bstr.length;
        const u8arr = new Uint8Array(n);
    
        while (n--) {
            u8arr[n] = bstr.charCodeAt(n);
        }
    
        return new Blob([u8arr], {type: mime});
    }

    /** Calculate sha256 for the data */
    public static async hashForDataUrl(dataUrl: string) : Promise<string> {
        const blob = ImageUtils.dataUriToBlob(dataUrl);
        const hash = ImageUtils.hashForBlob(blob);

        return hash;
    }
    
    /** Calculate sha256 for the data */
    public static async hashForBlob(blob: Blob) : Promise<string> {        
        const data = await blob.arrayBuffer();
        const hashBuffer = await crypto.subtle.digest('SHA-256', data);
        
        const base64Str = Base64.fromUint8Array(new Uint8Array(hashBuffer));
        return base64Str;
    }
}

export default ImageUtils;
