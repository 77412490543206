import { createAsyncThunk } from '@reduxjs/toolkit'
import { AxiosResponse } from "axios";

import apiService from "../../../common/api.service";
import * as dom from "../../models/domain";


export interface SaveScheduleRequest {
    staffSchedules: dom.StaffSchedule[],
    roomTasks: dom.RoomTaskEx[],
    removedAssignmentIds: string[],
    facilityId: string
}

export interface AddAssignmentRequest {
    facilityId: string,
    staffMemberId: string,
    assignment: dom.Assignment
}

export interface GetSchedulesRequest {
    tenantId?: string;
    facilityId?: string;
    staffMemberId?: string;
    year?: number;
    months?: number[];
    dateRange?: {
        start: string;
        end: string;
    }
    reload?: boolean
}

export const saveSchedule = createAsyncThunk(
    'staffSchedules/saveSchedule',   
    async (data: SaveScheduleRequest) => {                
        const response = await apiService.post<SaveScheduleRequest>('/staffSchedule/update', data) as AxiosResponse<dom.StaffSchedule[]>
        if (response) {
            return response.data
        } else {
            throw new Error('unknown error saving StaffSchedules');
        }
    }
)


export const getSchedules = createAsyncThunk(
    'staffSchedules/getSchedules',
    async (data: GetSchedulesRequest) => {
        const response = await apiService.post('/staffSchedule', data) as AxiosResponse<dom.StaffSchedule[]>
        if (response) {
            return response.data
        } else {
            throw new Error('unknown error loading StaffSchedules');
        }
    }
)

export const addAssignment = createAsyncThunk(
    'staffSchedules/addAssignment',   
    async (data: AddAssignmentRequest) => {                
        const response = await apiService.post<AddAssignmentRequest>('/staffSchedule/addassignment', data) as AxiosResponse<dom.StaffSchedule>
        if (response) {
            return response.data
        } else {
            throw new Error('unknown error adding assignment');
        }
    }
)
