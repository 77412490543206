import * as React from 'react';
import { withFormik, FormikProps } from 'formik';
import * as Yup from 'yup';
import { withTranslation } from 'react-i18next';
import {Row, Col, Form} from 'react-bootstrap';
import * as actions from './reducer'
import { HKReport } from '../../../store/models/domain';


interface Props {
    state: actions.CompState
    dispatch: actions.HkDispatch
    t: any
}

export interface FormValues extends Partial<HKReport> {      
    readonly?: boolean
}

const SupervisorNoteForm = (props: Props & FormikProps<FormValues>) => {
        const {
            // Formik HOC props
            values,
            errors,
            isSubmitting,
            handleSubmit,
            getFieldProps,
    
            // Form props
            cancelLabel,
            saveLabel,
            onCancel,        
            canCancel,
    
            // Translate props
            t,
        }: any = props;
    
        return (
            <Row className="justify-content-md-center">
                <Col sm={12} md={9}>                    
                    <Form onSubmit={(e) => { handleSubmit(e as React.FormEvent<HTMLFormElement>) }} className="p-1">
    
                        <Form.Group>                            
                            <Form.Control as="textarea" rows={5} id="supervisorNote" readOnly={values.readonly}                             
                                {...getFieldProps('supervisorNote')} isInvalid={!!errors.supervisorNote} />
                            <Form.Control.Feedback type="invalid" className="text-left">{t(errors.supervisorNote)}</Form.Control.Feedback>
                        </Form.Group>                   
                      
                        {/* Mimik sweetalert2 buttons */}
                        <div className="swal2-actions" style={{'fontSize': '16px'}}>
                            <button className="swal2-confirm swal2-styled" type="submit" disabled={values.readonly} >{saveLabel}</button>
                            <button className="swal2-cancel swal2-styled" type="button" onClick={() => { onCancel(values) }} disabled={isSubmitting || !canCancel}>{cancelLabel}</button>
                        </div>   
                    </Form>
                </Col>
            </Row>
        );
    };
    
    const validate = withFormik<any, FormValues>({
        mapPropsToValues: props => ({
            id: props.values?.id,
            supervisorNote: props.values?.supervisorNote || '',
            room: props.values?.room,
            readonly: props.values?.readonly,
        }),
    
        validationSchema: (props: any) => {
            return Yup.object().shape({               
        })},
    
        handleSubmit: (values, { props }) => {
            props.onSave(values);
        },
    });
    
    const translate = withTranslation();
    export default translate(validate(SupervisorNoteForm));
