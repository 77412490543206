
export class DataCache {
    private readonly supportCache: boolean;

    constructor(public cacheName: string) {
        this.supportCache = 'caches' in window;
    }

    public add = async (key: string, item: any) => {
        if(this.supportCache){            
            const cache = await window.caches.open(this.cacheName);
            await cache.delete(key);
            await cache.put(key, new Response(item));
        }
    }

    public getText = async (key: string) => {
        if(this.supportCache){            
            const cache = await window.caches.open(this.cacheName);
            const response = await cache.match(key);
            if(!!response){
                return await response.text();
            }            
        }
        return undefined;
    }

    public getBlob = async (key: string) => {
        if(this.supportCache){
            const cache = await window.caches.open(this.cacheName);
            const response = await cache.match(key);
            if(!!response){
                return await response.blob();
            }            
        }
        return undefined;
    }

    public getArrayBuffer = async (key: string) => {
        if(this.supportCache){
            const cache = await window.caches.open(this.cacheName);
            const response = await cache.match(key);
            if(!!response){
                return await response.arrayBuffer();
            }            
        }
        return undefined;
    }

    public delete = async (key: string) => {
        if(this.supportCache){
            const cache = await window.caches.open(this.cacheName);
            await cache.delete(key);
        }
        return undefined;
    }

    public clear = async () => {
        if(this.supportCache){            
            await window.caches.delete(this.cacheName);
        }
        return undefined;
    }    
}

const imageCache = new DataCache("images");
export {imageCache};


