import * as React from 'react'
import { useSelector } from 'react-redux'
import { useAppDispatch } from '../../../store/store'
import { withTranslation, WithTranslation  } from 'react-i18next'
import { authProvider } from "../../../common/auth/AuthProvider";
import { Form, Dropdown } from 'react-bootstrap'
import cn from "classnames"
import _ from "lodash"
import { HKRoomType, HKRoomState, HKCleanType, Room, RoomTaskType, FacilityData } from '../../../store/models/domain'
import { saveRooms } from '../../../store/reducers/domain/facilities'
import ColorUtils from '../../../utils/ColorUtils'
import classnames from 'classnames';
import moment from "moment";
import Swal, { runWithProgress, SwalForm } from '../Alert'
import 'react-bootstrap-typeahead/css/Typeahead.css'
import './RoomStateComponent.scss'

const CustomToggle = React.forwardRef(({ children, className, style, onClick }: any, ref: any) => (
    <div     
      ref={ref}
      className={className}
      style={style}
      onClick={(e) => {
        e.preventDefault()
        onClick(e)
      }}
    >
      <span className="align-middle">{children}</span>
    </div>
));

export const UpdateRoomState = (room: Room, stateName: string, cleanType?: string) => {
    room.roomState = stateName;
    room.stateCleanType = cleanType;
    // Assign clean by selected state
    if (!!room.roomState && !!cleanType) {
        room.outstandingTasks = (room.outstandingTasks || []).filter(t => t.id !== stateCleanTaskId)
            .concat([{
                id: stateCleanTaskId,
                taskType: RoomTaskType.Clean,
                description: `Room clean assigned by ${room.roomState} state.`,
                cleanType: cleanType,
                startDate: moment().startOf('date').toJSON()
            }]);
    } else {
        // remove state clean 
        room.outstandingTasks = (room.outstandingTasks || []).filter(t => t.id !== stateCleanTaskId);
    }
}

interface OwnProps {
    room: Room
    roomTypes: HKRoomType[]
    roomState?: string
    cleanType?: string
    onRoomStateChange?(newState: HKRoomState, newClean?: HKCleanType): void
    center?: boolean
}

type Props = OwnProps & WithTranslation

const stateCleanTaskId = "State Room Clean";

interface CompState {
    roomStates: HKRoomState[]
    roomType?: HKRoomType
    currentCleanType?: string
    currentState?: HKRoomState
}

const RoomStateComponent = (props: Props) => {
    const {
        room,
        roomTypes,
        onRoomStateChange,
        center,

        // Translate props
        t,
    } = props;

    const appDispatch = useAppDispatch()
    const facilityId = authProvider.getAccountInfo()?.facility?.id
    const facility: FacilityData = useSelector(state => (state as any).facilities.facilities.find((f: any) => f.id === facilityId));
    const [state, setState] = React.useState<CompState>({ roomStates: [] })

    React.useMemo(() => {
        const roomType = roomTypes.find((t: HKRoomType) => t.roomType === room.roomType) as HKRoomType
        const roomStates = !roomType?.roomStates ? [] : roomType!.roomStates!.filter(s => !!s.stateName && s.tags?.length > 0)
        const currentCleanType = props.cleanType ?? (room.outstandingTasks ?? []).find(t => t.id === stateCleanTaskId)?.cleanType
        const currentStateName = props.roomState ?? room.roomState
        const currentState = !currentStateName ? undefined : roomStates.find(r => r.stateName === currentStateName)

        setState({
            roomStates: roomStates,
            roomType: roomType,
            currentCleanType: currentCleanType,
            currentState: currentState
        });
    }, [room, roomTypes, props.cleanType, props.roomState])

    function _onRoomStateChange(newState: HKRoomState, newClean?: HKCleanType) {
        if (onRoomStateChange) onRoomStateChange(newState, newClean)
        else if (!!appDispatch && !!facility?.rooms) {
            const rooms = [...facility.rooms]
            const index = rooms.findIndex(r => r.id === room.id)
            if (index > 0) {
                rooms[index] = { ...rooms[index] }
                UpdateRoomState(rooms[index], newState.stateName, newClean?.cleanType)

                runWithProgress(t("Saving room state..."), () => {
                    appDispatch(saveRooms({
                        data: { id: facility.id, rooms: rooms }
                    })).then(r => {
                        if (!saveRooms.fulfilled.match(r)) {
                            throw r.error;
                        }
                        Swal.fire({
                            icon: 'info',
                            title: t('Room state has been updated.'),
                            showConfirmButton: true
                        })
                    }).catch(error => {
                        Swal.fire({
                            icon: 'error',
                            title: t('Failed to update room state.'),
                            showConfirmButton: true
                        })
                    })
                });
            }
        }
    }

    if (state.roomStates.length === 0) return null
    return (
        <Form.Group className="d-flex flex-column">
            <Form.Label className={!center ? "state-align-right" : ""}>{t('Room States')}</Form.Label>
            <div className={classnames("d-flex flex-row flex-wrap", !center ? "justify-content-start" : "justify-content-center")}>
                {[{ stateName: '', tags: [], order: -1 } as HKRoomState].concat(_.sortBy(state.roomStates, s => s.order)).map((s: HKRoomState) => {

                    const cleanTypes = _.sortBy(state.roomType?.routines.filter(r => (r.tags ?? []).some(t => (s.tags ?? []).includes(t))) ?? [], c => c.cleanType);
                    const isResident = !s.stateName;

                    return (<div key={s.order} 
                        className={cn("state-button-container", {
                            "state-button-container-selected": (state.currentState?.stateName ?? '') === (s.stateName ?? '')
                            })}>
                        <Dropdown>
                            <Dropdown.Toggle as={CustomToggle} className={cn("state-button d-flex flex-row align-items-center justify-content-center", {
                                "state-button-resident": !s.stateName
                                })} 
                            onSelect={(e) => _onRoomStateChange(s)}
                            title={s.stateName || t('Resident')}
                            style={{
                                padding: "5px",
                                color: !!s.color ? ColorUtils.getContrastColor(s.color) : "black",
                                backgroundColor: s.color, 
                                borderColor: s.borderColor || "black", 
                                borderStyle: s.style || 'solid',
                                borderWidth: "4px"
                            }}>
                                {s.stateName || t('Resident')}
                            </Dropdown.Toggle>

                            <Dropdown.Menu>
                                {isResident &&
                                    <Dropdown.Item onClick={(e) => _onRoomStateChange(s)}>{t('Reset state to Resident')}</Dropdown.Item>
                                }

                                {!isResident &&
                                    (cleanTypes.length > 0 
                                        ? (<>
                                        <Dropdown.Header>{t('Select associated Clean Type')}</Dropdown.Header>
                                        {cleanTypes.map(c =>
                                        (<Dropdown.Item key={c.cleanType} className={cn({ "state-clean-selected": s.stateName === state.currentState?.stateName && c.cleanType === state.currentCleanType })}
                                                onClick={(e) => _onRoomStateChange(s, c)} 
                                                style={{backgroundColor: c.color, color: !c.color ? 'black' : ColorUtils.getContrastColor(c.color)}}>
                                                {c.cleanType}
                                            </Dropdown.Item>))}
                                        </>)
                                        : (<>
                                            <Dropdown.Item onClick={(e) => _onRoomStateChange(s)}>{t('Set the room to {{state}} state', {state: s.stateName})}</Dropdown.Item>
                                        </>)
                                    )
                                }
                                                                                        
                            </Dropdown.Menu>
                        </Dropdown>
                    </div>)
                })}                            
            </div>
        </Form.Group>
    )
}

const translate = withTranslation()
export default translate(RoomStateComponent)
