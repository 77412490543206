import { SerializedError } from '@reduxjs/toolkit'

export enum AssignmentType {
    Housekeeping = "housekeeping"
}

export interface Assignment {
    id: string,
    type: string;
    description: string;    
    day: number;
    startDate: string;
    endDate: string;
    roomType?: string;
    cleanType?: string;
    facilityAreas?: string[][];
    roomNumbers?: string[];
    sendOverdueNotification?: boolean;    
    sendOverdueNotificationDate?: string;
}

export interface StaffSchedule {
    id: string, 
    tenantId: string,
    year: number;
    month: number;
    monthName: string;
    facility: {
        id: string,
        name: string
    };
    staffMember: {
        staffRefId: string,
        role: string,
        name: string,
        tenantRefId: string
    };
    assignments: Assignment[];
}

export interface StaffScheduleState {
    schedules: Array<StaffSchedule>
    loading: 'idle' | 'pending' | 'succeeded' | 'failed'
    error: SerializedError | null
}
