import moment from "moment";

import { ScheduleInfo } from './ScheduleInfo'
import { HKRelativeSchedule, HKScheduleType } from '../../store/models/domain';
import i18n from '../../locales/i18n';
import MomentUtils from '../../utils/MomentUtils';

export abstract class RelativeScheduleCalculator {

    /**
     * Calculate relative schedule - it sets only relativeDuration and scheduleBucket start/end 
     * which is independent of dateTime is additionally calculated for each room
     * @param schedule - schedule config
     * @param dateTime date time
     * @returns relativeDuration and start/end active range
     */
    public static calculateSchedule(schedule: HKRelativeSchedule, dateTime: moment.Moment) : ScheduleInfo | undefined {
        
        return {
            scheduleBucket: {
                start: moment(schedule.startTime, [moment.ISO_8601, 'HH:mm']),
                end: moment(schedule.endTime, [moment.ISO_8601, 'HH:mm']),
            },
            relativeDuration: moment.duration(schedule.durationSeconds, "seconds"),
            scheduleType: HKScheduleType.Relative 
        };
    }

    static getScheduleDescription(relativeSchedule: HKRelativeSchedule): string | undefined {
        return `${MomentUtils.humanizeDuration(moment.duration(relativeSchedule.durationSeconds, "second"))} ${i18n.t('after last clean, at')} ${relativeSchedule.startTime} - ${relativeSchedule.endTime}`
    }  
}