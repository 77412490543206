import * as React from 'react';
import { withFormik, FormikProps } from 'formik';
import * as Yup from 'yup';
import { withTranslation } from 'react-i18next';
import {Row, Col, Form } from 'react-bootstrap';
import "react-bootstrap-typeahead/css/Typeahead.css";
import { TenantData, StaffMember } from '../../../../store/models/domain'

export interface FormValues {
    selectedEmails?: string
}

interface OtherProps{
    tenant: TenantData
    submitLabel: string
    cancelLabel: string
    onCancel: (value?: any) => void
    cantCancel: boolean
    t: any
}

const EmailRecipientForm = (props: FormikProps<FormValues> & OtherProps) => {
    const {

        // Formik HOC props
        values,
        tenant,
        touched,
        errors,
        isSubmitting,
        handleSubmit,
        getFieldProps,
        setFieldValue,

        // Form props
        submitLabel,
        cancelLabel,
        onCancel,        
        cantCancel,

        // Translate props
        t,
    } = props;
    
    const supervisors = (tenant.supervisors || []);
    const supervisorOptions = [
            ...supervisors, 
            ...(tenant.staffMembers || [])
                .filter((r: StaffMember) => !!r.email && !r.isArchived &&
                            !supervisors.some((s: StaffMember) => s.email?.toLowerCase() === r.email?.toLowerCase()))
        ]        
        .map((r: StaffMember) => (
            <Form.Check type="checkbox" id={"su_" + r.id} label={r.email} defaultChecked className={'d-flex form-control pl-4'} />
    ));

    return (
        <Row className="justify-content-md-center">
            <Col sm={12} md={9}>               
                <Form onSubmit={(e) => { handleSubmit(e as React.FormEvent<HTMLFormElement>) }} className="p-1">

                    <Form.Group>
                        {supervisorOptions}
                    </Form.Group>

                    {/* Mimik sweetalert2 buttons */}
                    <div className="swal2-actions" style={{'fontSize': '16px'}}>
                        <button className="swal2-confirm swal2-styled" type="submit">{submitLabel}</button>
                        <button className="swal2-cancel swal2-styled" type="button" onClick={() => { onCancel() }} disabled={isSubmitting || cantCancel}>{cancelLabel}</button>
                    </div>                    
                </Form>
            </Col>           
        </Row>
    );
};

const validate = withFormik<any, FormValues>({
    mapPropsToValues: props => ({
        selectedEmails: props.tenant?.supervisors?.map((r: StaffMember) => r.email)?.join(';')
    }),

    validationSchema: (props: any) => {
        return Yup.object().shape({
        })
    },

    handleSubmit: (values, { props }) => {

        //for (let [key, values] of Object.entries<HKCleanOperation[]>(stateOpGroup))
        var test = props.tenant?.supervisors?.map((r: StaffMember) => r.email)?.join(';')
        values.selectedEmails = "";
        for (let supervisor of props.tenant.supervisors) {
            if ((document.getElementById("su_" + supervisor.id) as HTMLInputElement)?.checked) {
                values.selectedEmails += ";" + supervisor.email;
            }
        }
        if (values.selectedEmails.length > 0)
            values.selectedEmails = values.selectedEmails.substring(1)

        props.onSave(values);
    },
});

const translate = withTranslation();
export default translate(validate(EmailRecipientForm));
