import { combineReducers } from '@reduxjs/toolkit'

import template from "./template"
import tenants from "./domain/tenants"
import facilities from "./domain/facilities"
import housekeeping from "./domain/housekeeping"
import staffSchedules from "./domain/staffSchedule"
import facilitySchedules from "./domain/facilitySchedule"
import chsSystem from "./domain/chsSystem"
import sync from "./domain/sync"


const appReducer = combineReducers({
    template, tenants, facilities, housekeeping, staffSchedules, facilitySchedules, chsSystem, sync
})

const rootReducer = (state: any, action: any) => {
    // when a redux save is sent with an empty payload, reset the redux store to initial
    if (action?.type === 'REDUX_STORAGE_SAVE' && Object.keys(action?.payload ?? {}).length === 0) {
        state = undefined;
    }
    return appReducer(state, action);
};


export default rootReducer

export type RootState = ReturnType<typeof rootReducer>
