import { createSlice } from '@reduxjs/toolkit'

import * as dom from "../../models/domain";
import { getSchedules, saveSchedule, addAssignment } from "../../actions/domain/staffSchedule";


const initialState: dom.StaffScheduleState = {
    schedules: [],
    loading: 'idle',
    error: null
}

const sliceReducer = createSlice({
    name: 'staffSchedules',
    initialState,
    reducers: {
    },
    extraReducers: builder => {

        // load 
        builder.addCase(getSchedules.fulfilled, (state, action) => {
            return {
                ...state,
                schedules: action.meta.arg.reload !== true
                    ? [...state.schedules.filter(f => !action.payload.some(s => s.id === f.id)), ...action.payload]
                    : action.payload,
                loading: 'succeeded',
                error: null,
            }
        })
        builder.addCase(getSchedules.rejected, (state, action) => {
            return { ...state, loading: 'failed', error: action.error }
        })

        
        // save
        builder.addCase(saveSchedule.fulfilled, (state, action) => {
            return {
                ...state,
                schedules: [...state.schedules.filter(f => !action.payload.some(ss => ss.id === f.id)), ...action.payload],
                loading: 'succeeded',
                error: null,
            }
        })
        builder.addCase(saveSchedule.rejected, (state, action) => {
            return { ...state, loading: 'failed', error: action.error }
        })

        // add assignment
        builder.addCase(addAssignment.fulfilled, (state, action) => {
            return {
                ...state,
                schedules: [...state.schedules.filter(f => f.id !== action.payload.id), action.payload],
                loading: 'succeeded',
                error: null,
            }
        })
        builder.addCase(addAssignment.rejected, (state, action) => {
            return { ...state, loading: 'failed', error: action.error }
        })
    }
}).reducer
export default sliceReducer

//https://redux-toolkit.js.org/usage/usage-with-typescript/
