import simpleMerger from './simpleMerger';
import { LOAD } from './constants';

export default (reducer: any, merger: any = simpleMerger) => {
    return (state: any, action: any) => reducer(
        action.type === LOAD
            ? merger(state, action.payload)
            : state,
        action
    );
};
