import { createSlice } from '@reduxjs/toolkit'

import * as dom from "../../models/domain";
import { getFacilitySchedules, saveFacilitySchedule, addTransaction } from "../../actions/domain/facilitySchedule";


const initialState: dom.FacilityScheduleState = {
    schedules: [],
    loading: 'idle',
    error: null
}

const sliceReducer = createSlice({
    name: 'facilitySchedules',
    initialState,
    reducers: {
    },
    extraReducers: builder => {

        // load 
        builder.addCase(getFacilitySchedules.fulfilled, (state, action) => {
            return {
                ...state,
                schedules: action.payload,
                loading: 'succeeded',
                error: null,
            }
        })
        builder.addCase(getFacilitySchedules.rejected, (state, action) => {
            return { ...state, loading: 'failed', error: action.error }
        })

        
        // save
        builder.addCase(saveFacilitySchedule.fulfilled, (state, action) => {
            return {
                ...state,
                schedules: [...state.schedules.filter(f => action.payload.id !== f.id), action.payload],
                loading: 'succeeded',
                error: null,
            }
        })
        builder.addCase(saveFacilitySchedule.rejected, (state, action) => {
            return { ...state, loading: 'failed', error: action.error }
        })

        // add assignment
        builder.addCase(addTransaction.fulfilled, (state, action) => {
            return {
                ...state,
                schedules: [...state.schedules.filter(f => f.id !== action.payload.id), action.payload],
                loading: 'succeeded',
                error: null,
            }
        })
        builder.addCase(addTransaction.rejected, (state, action) => {
            return { ...state, loading: 'failed', error: action.error }
        })
    }
}).reducer
export default sliceReducer

//https://redux-toolkit.js.org/usage/usage-with-typescript/
