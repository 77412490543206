import * as React from 'react';
import { v4 as uuid } from 'uuid';
import moment from "moment";
import _ from "lodash";
import * as signalR from "@microsoft/signalr";

import { authProvider } from "../../../common/auth/AuthProvider";
import RoomService, { RoomSchedule } from "../../../services/RoomService";
import AssignmentService from "../../../services/AssignmentService";
import { Resident, FacilityData, Room, HousekeepingData, HKRoomType, HKReport, HKReportStatus, TenantData, 
    StaffMember, HKCleanOperation, StaffSchedule, Assignment, HKPhoto, HKClean, HousekeepingSettings, AssignmentTemplate,
    CleanOperationImage, HKAuditReport, HKAuditOperationStatus, HKAuditMarkingReport } from '../../../store/models/domain'
import log from "../../../common/logging";
import store from "../../../store/store"
import { getFacility } from "../../../store/reducers/domain/facilities"
import Swal, {runWithProgress } from '../../components/Alert';
import { loadHousekeepingReports } from "../../../store/reducers/domain/housekeeping"
import i18n from "../../../locales/i18n"
import { Dictionary } from "../../../common/utilityTypes";
import * as Filter from "./Dashboard/AreaFilterRenderItem"
import { imageCache } from "../../../common/dataCache";
import ImageUtils from '../../../utils/ImageUtils';
import apiService from '../../../common/api.service';

export const DATETIME_FORMAT = 'YYYY-MM-DDTHH:mm:ss';

interface AreaFilter {
    level: number
    selected: string
    options: string[]
}

interface RoomTypeFilter {
    selected: string
    options: string[]
}

export type AssignmentsFilterOption = Filter.AssignmentsFilterOption

export type CompState = {
    tenant: TenantData,
    staffMember: StaffMember,
    rooms: {
        byId: Dictionary<Room>
        allIds: string[]
        filteredIds: string[]
    }
    roomSchedules: {
        byRoomId: Dictionary<RoomSchedule>
        allIds: string[]        
    }
    residents: {
        byId: Dictionary<Resident>
        allIds: string[]
    }
    roomTypes: {
        byId: Dictionary<HKRoomType>
        allIds: string[]
        allTypes: string[]
        typeMap: Dictionary<string[]>
    }
    residentRoomLookup: Dictionary<string>
    areaFilters: AreaFilter[]
    roomTypeFilter: RoomTypeFilter,
    assignmentsFilter: Filter.AssignmentsFilter,
    cleaningRecord: HKReportExt | undefined,    
    hkReports: HKReport[],
    markingReports: HKAuditMarkingReport[],
    hkCleans: HKClean[],
    assignments: Assignment[],
    updatesWatcher: {
        connection?: signalR.HubConnection,
        requestedUpdates?: {
            facilityId: string,
            reportId: string
        }
    },
    showShortcuts: boolean,
    settings?: HousekeepingSettings,
    operationImages: CleanOperationImage[],
    lastMarkingReport?: HKAuditMarkingReport,
}

interface LoadData {
    type: 'LOAD_DATA'
    payload: {
        facility: FacilityData
        config: HousekeepingData
        tenant: TenantData,
        staffSchedules: StaffSchedule[]
        hkReports: HKReport[],
        markingReports: HKAuditMarkingReport[],
        hkCleans: HKClean[]
    }
}

export function loadData(facility: FacilityData, config: HousekeepingData, tenant: TenantData, 
    staffSchedules: StaffSchedule[], hkReports: HKReport[], hkCleans: HKClean[], markingReports: HKAuditMarkingReport[]): LoadData {
    return {
        type: 'LOAD_DATA',
        payload: {
            facility: facility,
            config: config,
            tenant: tenant,
            staffSchedules: staffSchedules,
            hkReports,
            markingReports,
            hkCleans
        }
    }
}

interface ApplyAreaFilter {
    type: 'APPLY_AREA_FILTER'
    payload: {
        level: number,
        filter: string
    }
}

export function applyAreaFilter(level: number, filter: string): ApplyAreaFilter {
    return {
        type: 'APPLY_AREA_FILTER',
        payload: {
            level: level,
            filter: filter
        }
    }
}

interface ApplyRoomTypeFilter {
    type: 'APPLY_ROOMTYPE_FILTER'
    payload: string
}

export function applyRoomTypeFilter(filter: string): ApplyRoomTypeFilter {
    return {
        type: 'APPLY_ROOMTYPE_FILTER',
        payload: filter
    }
}

interface ApplyAssignmentsFilter {
    type: 'APPLY_ASSIGNMENTS_FILTER'
    payload: {
        selected: Filter.AssignmentsFilterOption,
        selectedSchedule?: AssignmentTemplate
    }
}

export function applyAssignmentsFilter(filter: Filter.AssignmentsFilterOption, selectedSchedule?: AssignmentTemplate): ApplyAssignmentsFilter {
    return {
        type: 'APPLY_ASSIGNMENTS_FILTER',
        payload: {
            selected: filter,
            selectedSchedule
        }
    }
}

interface SelectRoom {
    type: 'SELECT_ROOM'
    payload: {
        roomId: string | undefined
        qrCode: string | undefined
        continueCleaning?: boolean
        auditMarking?: boolean
    }
}

export function selectRoom(roomId: string | undefined, qrCode: string | undefined, continueCleaning?: boolean, auditMarking?: boolean): SelectRoom {
    return {
        type: 'SELECT_ROOM',
        payload: {
            roomId,
            qrCode,
            continueCleaning,
            auditMarking
        }
    }
}

interface SelectCleanType {
    type: 'SELECT_CLEAN_TYPE'
    payload: string
}

export function selectCleanType(cleanType: string): SelectCleanType {
    return {
        type: 'SELECT_CLEAN_TYPE',
        payload: cleanType
    }
}

interface SelectCleanOpGroup {
    type: 'SELECT_CLEAN_OP_GROUP'
    payload: string | undefined
}

export function selectCleanOpGroup(opGroup: string | undefined): SelectCleanOpGroup {
    return {
        type: 'SELECT_CLEAN_OP_GROUP',
        payload: opGroup
    }
}

interface ToggleCleanOperation {
    type: 'TOGGLE_CLEAN_OPERATION'
    payload: {
        index: number
        value: boolean
    }
}

export function toggleCleanOperation(index: number, value: boolean): ToggleCleanOperation {
    return {
        type: 'TOGGLE_CLEAN_OPERATION',
        payload: {
            index: index,
            value: value
        }
    }
}

interface ToggleCleanOperationGroup {
    type: 'TOGGLE_CLEAN_OPERATION_GROUP'
    payload: {
        group: string
        value: boolean
    }
}

export function toggleCleanOperationGroup(group: string, value: boolean): ToggleCleanOperationGroup {
    return {
        type: 'TOGGLE_CLEAN_OPERATION_GROUP',
        payload: {
            group: group,
            value: value
        }
    }
}

interface StartWatchUpdate {
    type: 'START_WATCH_UPDATES'
    payload: {
        dispatch: any       
    }
}

export function startWatchUpdate(dispatch: any): StartWatchUpdate {
    return {
        type: 'START_WATCH_UPDATES',
        payload: {
            dispatch            
        }
    }
}

interface StopWatchUpdate {
    type: 'STOP_WATCH_UPDATES'
    payload: {        
    }
}

export function stopWatchUpdate(): StopWatchUpdate {
    return {
        type: 'STOP_WATCH_UPDATES',
        payload: {            
        }
    }
}

interface PerformUpdate {
    type: 'PERFORM_UPDATE'
    payload: {   
        facilityId: string,
        reportId: string  
    }
}

export function performUpdate(facilityId: string, reportId: string): PerformUpdate {
    return {
        type: 'PERFORM_UPDATE',
        payload: {      
            facilityId,
            reportId 
        }
    }
}

interface UpdateCommentAndPhotos {
    type: 'UPDATE_COMMENT_AND_PHOTOS'
    payload: {
        patch?: Partial<HKReport>
        photos?: HKPhoto[]
    }
}

export function updateCommentAndPhotos(patch: Partial<HKReport>, photos?: HKPhoto[]): UpdateCommentAndPhotos {
    return {
        type: 'UPDATE_COMMENT_AND_PHOTOS',
        payload: {
            patch,
            photos
        }
    }
}

interface UpdateSupervisorNote {
    type: 'UPDATE_SUPERVISOR_NOTE'
    payload: {
        supervisorNote?: string
    }
}

export function updateSupervisorNote(supervisorNote?: string): UpdateSupervisorNote {
    return {
        type: 'UPDATE_SUPERVISOR_NOTE',
        payload: {
            supervisorNote
        }
    }
}

interface ViewHistoryReport {
    type: 'VIEW_HISTORY_REPORT'
    payload: {
        hkReport: HKReportExt        
    }
}

export function viewHistoryReport(hkReport: HKReport): ViewHistoryReport {
    return {
        type: 'VIEW_HISTORY_REPORT',
        payload: {
            hkReport: {
                ...hkReport,
                readonly: true,
                selectedCleanType: _.first(hkReport.roomType.routines)!.cleanType,
                selectedGroup: undefined
            }
        }
    }
}

interface ToggleShortcuts {
    type: 'TOGGLE_SHORTCUTS'
    payload: {
        show: boolean
    }
}

export function toggleShortcuts(show: boolean): ToggleShortcuts {
    return {
        type: 'TOGGLE_SHORTCUTS',
        payload: {
            show: show
        }
    }
}

interface AuditReport {
    type: 'AUDIT_REPORT'
    payload: {
        hkReport: HKReportExt        
    }
}

export function auditReport(hkReport: HKReport): AuditReport {
    return {
        type: 'AUDIT_REPORT',
        payload: {
            hkReport: {
                ...hkReport,
                auditMode: true,
                selectedCleanType: _.first(hkReport.roomType.routines)!.cleanType,
                selectedGroup: undefined
            }
        }
    }
}

interface UpdateAudit {
    type: 'UPDATE_AUDIT'
    payload: {
        auditReport: Partial<HKAuditReport>        
    }
}

export function updateAudit(auditReport: Partial<HKAuditReport>): UpdateAudit {
    return {
        type: 'UPDATE_AUDIT',
        payload: {
            auditReport: auditReport
        }
    }
}


interface SetOperationAuditStatus {
    type: 'SET_OPERATION_AUDIT_STATUS'
    payload: {
        index: number
        auditStatus: HKAuditOperationStatus
    }
}

export function setOperationAuditStatus(index: number, auditStatus: HKAuditOperationStatus): SetOperationAuditStatus {
    return {
        type: 'SET_OPERATION_AUDIT_STATUS',
        payload: {
            index: index,
            auditStatus: auditStatus
        }
    }
}

interface SetOperationAuditMarked {
    type: 'SET_OPERATION_AUDIT_MARKED'
    payload: {
        index: number
        isMarked: boolean
    }
}

export function setOperationAuditMarked(index: number, isMarked: boolean): SetOperationAuditMarked {
    return {
        type: 'SET_OPERATION_AUDIT_MARKED',
        payload: {
            index: index,
            isMarked: isMarked
        }
    }
}

type CompAction = ReturnType<typeof loadData | typeof applyAreaFilter | typeof applyRoomTypeFilter | typeof selectRoom
    | typeof selectCleanType | typeof toggleCleanOperation | typeof selectCleanOpGroup | typeof startWatchUpdate | typeof stopWatchUpdate 
    | typeof performUpdate | typeof applyAssignmentsFilter | typeof updateCommentAndPhotos | typeof updateSupervisorNote | typeof viewHistoryReport
    | typeof toggleShortcuts | typeof toggleCleanOperationGroup
    | typeof auditReport | typeof updateAudit | typeof setOperationAuditStatus | typeof setOperationAuditMarked>

export const initialState: CompState = {
    tenant: {} as TenantData,
    staffMember: {} as StaffMember,
    rooms: {
        byId: {},
        allIds: [],
        filteredIds: []
    },
    roomSchedules: {
        byRoomId: {},
        allIds: []        
    },
    residents: {
        byId: {},
        allIds: []
    },
    roomTypes: {
        byId: {},
        allIds: [],
        allTypes: [],
        typeMap: {}
    },
    residentRoomLookup: {},
    areaFilters: [],
    roomTypeFilter: { selected: '', options: [] },
    assignmentsFilter: { 
        selected: Filter.AssignmentsFilterOption.All, 
        options: [
            Filter.AssignmentsFilterOption.All, 
            Filter.AssignmentsFilterOption.DailyList, 
            Filter.AssignmentsFilterOption.OtherSchedules, 
            Filter.AssignmentsFilterOption.Incomplete, 
            Filter.AssignmentsFilterOption.ToDo],
        otherSchedules: []
    },
    cleaningRecord: undefined,    
    hkReports: [],
    markingReports: [],
    hkCleans: [],
    assignments: [],
    updatesWatcher: {
        connection: undefined,
        requestedUpdates: undefined
    },
    showShortcuts: true,
    settings: undefined,
    operationImages: []
}

export interface HKReportExt extends HKReport {
    selectedCleanType: string
    selectedGroup: string | undefined,
    selectedGroupTime?: string,
    readonly?: boolean,
    auditMode?: boolean,
    auditMarking?: boolean,
}

export interface HKCleanOperationExt extends HKCleanOperation {
    index?: number
}

const reducer: React.Reducer<CompState, CompAction> = (state, action) => {

    switch (action.type) {
        case 'LOAD_DATA':
            let roomTypes = ([...new Set(action.payload.config.roomTypes.map(r => r.roomType))])
            let staffId = authProvider.getAccountInfo()?.staffMember?.staffRefId
            let rooms = action.payload.facility.rooms?.filter(r => !r.isArchived) ?? []
            let filteredRooms = rooms;
            const assignments = AssignmentService.filterAssignmentsByDate(action.payload.staffSchedules, moment());

            const scheduledCleans = RoomService.calculateRoomsRequiringCleaning(rooms, action.payload.config.roomTypes);
            const roomSchedules = {
                byRoomId: scheduledCleans.reduce((obj, cur, i) => { return { ...obj, [cur.room.id]: cur } }, {}) ?? {},
                allIds: scheduledCleans.map(r => r.room.id)
            };

            const staffMember = action.payload.tenant.staffMembers?.find(r => r.id === staffId) ?? state.staffMember;


            const otherSchedules = _.sortBy((action.payload.facility.assignmentTemplates ?? []), s => s.description);
            let assignmentFilter = state.assignmentsFilter.selected
            const selectedFilter = localStorage.getItem('Dashboard.AssignmentFilter');
            if (selectedFilter) assignmentFilter = selectedFilter as Filter.AssignmentsFilterOption;
            if(!!staffMember.restrictedArea) assignmentFilter = staffMember.restrictedArea as Filter.AssignmentsFilterOption;

            let selectedSchedule: AssignmentTemplate | undefined = undefined;
            const selectedScheduleId = localStorage.getItem('Dashboard.AssignmentFilterSchedule');
            if(selectedScheduleId) selectedSchedule = otherSchedules.find(s => s.id === selectedScheduleId);
            const staffSelectedSchedule = otherSchedules.find(s => s.id === staffMember.restrictedAreaAssignmentId);
            if(!!staffSelectedSchedule) selectedSchedule = staffSelectedSchedule

            filteredRooms = Filter.filterRoomsByAssignments(rooms, assignmentFilter, assignments, roomSchedules, selectedSchedule);         
            
            cacheCleanTaskDetails(action.payload.config.operationImages ?? []);

            return {
                ...initialState,
                tenant: action.payload.tenant,
                staffMember: staffMember,
                rooms: {
                    byId: rooms.reduce((obj, cur, i) => { return { ...obj, [cur.id]: cur } }, {}) ?? {},
                    allIds: (rooms.map(r => r.id)) ?? [],
                    filteredIds: (filteredRooms.map(r => r.id)) ?? [],
                },
                roomSchedules: roomSchedules,
                residents: {
                    byId: action.payload.facility.residents?.filter(r => r.id).reduce((obj, cur, i) => { return { ...obj, [cur.id ?? '']: cur } }, {}) ?? {},
                    allIds: (action.payload.facility.residents?.filter(r => r.id).map(r => r.id ?? '')) ?? []
                },
                roomTypes: {
                    byId: action.payload.config.roomTypes.reduce((obj, cur, i) => { return { ...obj, [cur.id ?? '']: cur } }, {}) ?? {},
                    allIds: (action.payload.config.roomTypes.map(r => r.id ?? '')) ?? [],
                    allTypes: roomTypes,
                    typeMap: roomTypes.reduce((obj, cur, i) => { return { ...obj, [cur]: action.payload.config.roomTypes.filter(r => r.roomType === cur).map(r => r.id) } }, {}) ?? {},
                },
                residentRoomLookup: action.payload.facility.residents?.filter(r => r.id && r.room?.id).reduce((obj, cur, i) => { return { ...obj, [cur.room?.id ?? '']: cur.id } }, {}) ?? {},
                areaFilters: Filter.getAreaFilters(filteredRooms ?? [], state.areaFilters),
                roomTypeFilter: Filter.getRoomTypeFilters(filteredRooms ?? [], state.roomTypeFilter),
                assignmentsFilter: { 
                    ...state.assignmentsFilter, 
                    selected: assignmentFilter,
                    otherSchedules: otherSchedules,
                    selectedSchedule
                },
                cleaningRecord: state.cleaningRecord,                
                hkReports: action.payload.hkReports,
                markingReports: action.payload.markingReports,
                hkCleans: action.payload.hkCleans,
                assignments: assignments,
                updatesWatcher: {
                    ...state.updatesWatcher,
                    needsUpdates: undefined
                },
                settings: action.payload.config.settings,
                operationImages: action.payload.config.operationImages || []
            }
        case 'APPLY_AREA_FILTER':
            return {
                ...state,
                areaFilters: Filter.getAreaFilters(state.rooms.filteredIds.map(id => state.rooms.byId[id]), state.areaFilters.map(r => r.level === action.payload.level ? { ...r, selected: action.payload.filter } : r))
            }
        case 'APPLY_ROOMTYPE_FILTER':
            return {
                ...state,
                roomTypeFilter: { ...state.roomTypeFilter, selected: action.payload }
            }
        case 'APPLY_ASSIGNMENTS_FILTER':            
            localStorage.setItem('Dashboard.AssignmentFilter', action.payload.selected);
            localStorage.setItem('Dashboard.AssignmentFilterSchedule', action.payload.selectedSchedule?.id ?? '');
            const filtered = Filter.filterRoomsByAssignments(
                state.rooms.allIds.map(id => state.rooms.byId[id]), 
                action.payload.selected, state.assignments, state.roomSchedules, action.payload.selectedSchedule);

            return {
                ...state,
                rooms: {
                    byId: state.rooms.byId,
                    allIds: state.rooms.allIds,
                    filteredIds: (filtered.map(r => r.id)) ?? [],
                },
                areaFilters: Filter.getAreaFilters(filtered ?? [], state.areaFilters),
                roomTypeFilter: Filter.getRoomTypeFilters(filtered ?? [], state.roomTypeFilter),
                assignmentsFilter: { ...state.assignmentsFilter, selected: action.payload.selected, selectedSchedule: action.payload.selectedSchedule }
            }
        case 'SELECT_ROOM':
            keepAuthAlive();
            return initializeCleaningRecord(state, action.payload.roomId, action.payload.qrCode, action.payload.continueCleaning, action.payload.auditMarking)
        case 'SELECT_CLEAN_TYPE':
            keepAuthAlive();
            return {
                ...state,
                showShortcuts: false,
                cleaningRecord: copyAuditMarking((state.cleaningRecord 
                    ? { ...state.cleaningRecord, selectedCleanType: action.payload, selectedGroup: undefined } 
                    : state.cleaningRecord),
                    state.lastMarkingReport)
            }
        case 'TOGGLE_CLEAN_OPERATION':
            keepAuthAlive();
            return toggleOperation(state, action.payload.index, action.payload.value)
        case 'TOGGLE_CLEAN_OPERATION_GROUP':
            keepAuthAlive();
            return toggleOperation(state, -1, action.payload.value, action.payload.group)
        case 'SELECT_CLEAN_OP_GROUP':
            keepAuthAlive();
            return {
                ...state,
                cleaningRecord: state.cleaningRecord ? {
                    ...state.cleaningRecord,
                    selectedGroup: action.payload,
                    selectedGroupTime: moment().format(DATETIME_FORMAT)
                } : state.cleaningRecord
            }
        case 'START_WATCH_UPDATES':
            return {
                ...state,
                updatesWatcher: {
                    connection: connectUpdateWatcher(action.payload.dispatch),
                    needsUpdates: false
                }
            }  
        case 'STOP_WATCH_UPDATES':
            if(!!state.updatesWatcher.connection) {
                stopUpdateWatcher(state.updatesWatcher.connection!);
            }
            return {
                ...state,
                updatesWatcher: {
                    connection: undefined,
                    needsUpdates: false
                }
            } 
         case 'PERFORM_UPDATE': 
            const postponeUpdate = !!state.cleaningRecord;
            if(!postponeUpdate) {
                getUpdates(action.payload.facilityId, action.payload.reportId);
            }           
            return {
                ...state,
                updatesWatcher: {
                    ...state.updatesWatcher,
                    requestedUpdates: postponeUpdate ? {...action.payload} : undefined
                }
            }
         case 'UPDATE_COMMENT_AND_PHOTOS': 
            keepAuthAlive();                       
            return {
                ...state,
                cleaningRecord: {
                    ...state.cleaningRecord!, 
                    ...action.payload.patch,
                    photos: action.payload.photos,
                }
            }
        case 'UPDATE_SUPERVISOR_NOTE':
            keepAuthAlive();                        
            return {
                ...state,
                cleaningRecord: {
                    ...state.cleaningRecord!, 
                    supervisorNote: action.payload.supervisorNote
                }
            }
        case 'VIEW_HISTORY_REPORT':
            keepAuthAlive();                        
            return {
                ...state,
                cleaningRecord: action.payload.hkReport
            }
        case 'TOGGLE_SHORTCUTS':
            keepAuthAlive();
            return {
                ...state,
                showShortcuts: action.payload.show
            }
        case 'AUDIT_REPORT':
            keepAuthAlive();
            
            let report = action.payload.hkReport;
            if(!report.auditReport) {
                report = {
                    ...report,
                    auditReport: {
                        startDate: moment().format(DATETIME_FORMAT),
                        auditor: {...state.staffMember}
                    }
                }
            }
            else{
                report = {
                    ...report,
                    auditReport: {
                        ...report.auditReport,
                        startDate: moment().format(DATETIME_FORMAT)
                    }
                }
            }            

            return {
                ...state,
                cleaningRecord: report
            }
        case 'UPDATE_AUDIT':
            keepAuthAlive();
            
            if(!state.cleaningRecord || state.cleaningRecord.auditMode !== true)
                return state;

            const auditReport: HKAuditReport = {
                ...state.cleaningRecord.auditReport!, 
                ...action.payload.auditReport,                 
                // Change auditor if some other staff change report
                auditor: state.cleaningRecord!.auditReport!.auditor?.id === state.staffMember?.id
                    ? state.cleaningRecord!.auditReport!.auditor
                    : {...state.staffMember}
            };

            return {
                ...state,
                cleaningRecord: {
                    ...state.cleaningRecord,
                    auditReport: auditReport
                }
            }
        case 'SET_OPERATION_AUDIT_STATUS':
            keepAuthAlive();            
            if(!state.cleaningRecord || state.cleaningRecord.auditMode !== true)
                return state;

            const modifiedAudit: HKAuditReport = {
                ...state.cleaningRecord, 
                // Reset date on change
                reportDate: undefined,
                // Change auditor if some other staff change report
                auditor: state.cleaningRecord!.auditReport!.auditor?.id === state.staffMember?.id
                    ? state.cleaningRecord!.auditReport!.auditor
                    : {...state.staffMember}
            };

            return {
                ...setAuditStatusForOperation(state, action.payload.index, action.payload.auditStatus),
                auditReport: modifiedAudit
            }
        case 'SET_OPERATION_AUDIT_MARKED':
            keepAuthAlive();            
            if(!state.cleaningRecord || state.cleaningRecord.auditMode !== true)
                return state;

            const modifiedAudit2: HKAuditReport = {
                ...state.cleaningRecord, 
                // Reset date on change
                reportDate: undefined,
                // Change auditor if some other staff change report
                auditor: state.cleaningRecord!.auditReport!.auditor?.id === state.staffMember?.id
                    ? state.cleaningRecord!.auditReport!.auditor
                    : {...state.staffMember}
            };

            return {
                ...setAuditStatusForOperation(state, action.payload.index, undefined, action.payload.isMarked),
                auditReport: modifiedAudit2
            }
        default:
            return initialState
    }
}

const toggleOperation = (state: CompState, operationIndex: number, value: boolean, group?: string): CompState => {

    let newState: CompState = { ...state }
    if (newState.cleaningRecord) {

        let record = { ...newState.cleaningRecord, selectedGroupTime: moment().format(DATETIME_FORMAT) }
        newState.cleaningRecord = record

        let roomType = { ...record.roomType }
        record.roomType = roomType

        let found = false
        roomType.routines = roomType.routines.map(routine => routine.cleanType !== record.selectedCleanType ? routine : {
            ...routine,
            operations: routine.operations.map((op: HKCleanOperationExt, index: number) => {
                if (index === operationIndex || op.group === group) {
                    found = true
                    return { ...op, checked: value, skipped: false, timeStamp: value ? moment().format(DATETIME_FORMAT) : undefined }
                } else if (!found && op.checked === undefined) {
                    //If its optional just make it ignored (grey), otherwise make it skipped (orange)
                    return !!op.optional ? { ...op, checked: false } : { ...op, skipped: true }
                } else {
                    return op
                }
            })
        })
    }
    return newState
}

const setAuditStatusForOperation = (state: CompState, operationIndex: number, auditStatus?: HKAuditOperationStatus, isMarked?: boolean): CompState => {
    
    if (!state.cleaningRecord) return state;


    let record = { ...state.cleaningRecord }    
    let roomType = { ...record.roomType }
    record.roomType = roomType
    
    roomType.routines = roomType.routines.map(routine => routine.cleanType !== record.selectedCleanType ? routine : {
        ...routine,
        operations: routine.operations.map((op: HKCleanOperationExt, index: number) => index === operationIndex 
            ? { 
                ...op, 
                auditStatus: auditStatus ?? op.auditStatus, 
                isMarked: isMarked ?? op.isMarked
            } 
            : op)
    })
    
    if(!roomType.routines.find(routine => routine.cleanType === record.selectedCleanType)?.operations
        .some(o => !o.auditStatus || o.auditStatus === HKAuditOperationStatus.NotObserved)) {
        record = {
            ...record,
            auditReport: {
                ...record.auditReport!,
                reportDate: moment().format(DATETIME_FORMAT)
            }
        }
    }
    return {
        ...state,
        cleaningRecord: record
    }
}

const initializeCleaningRecord = (state: CompState, roomId?: string, qrCode?: string, continueCleaning?: boolean, auditMarking?: boolean): CompState => {

    let newState: CompState = { 
        ...state, 
        showShortcuts: true,
        lastMarkingReport: undefined
    }
    if (!roomId) {        
        if(!!newState.updatesWatcher.requestedUpdates) {
            getUpdates(newState.updatesWatcher.requestedUpdates.facilityId, newState.updatesWatcher.requestedUpdates.reportId);
        }
        newState.cleaningRecord = undefined;
        newState.updatesWatcher.requestedUpdates = undefined;
    } else {

        let room = state.rooms.byId[roomId ?? ""]
        let residentId = state.residentRoomLookup[room.id] ?? ""
        let resident: Resident | undefined = state.residents.byId[residentId]
        let roomTypeIds: string[] | undefined = state.roomTypes.typeMap[room.roomType]
        let roomType: HKRoomType | undefined = state.roomTypes.byId[roomTypeIds?.find(r => true) ?? ""]
        let routines = roomType?.routines ?? [];
        let selectedRoutine = routines.find(r => qrCode === r.qrCode) 
            ?? routines.find(r => r.isDefault === true)
            ?? routines.find(r => true);
        
        // Select scheduled clean        
        if(!qrCode) {           
            const scheduledClean = state.roomSchedules.byRoomId[room.id];
            if(!!scheduledClean) {
                selectedRoutine = scheduledClean.cleanType;
                newState.showShortcuts = false
            }            
        }

        const startDate = moment().format(DATETIME_FORMAT);

        let lastMarkingReport = _.last(state.markingReports.filter(r => r.room.id === roomId));
        if(!!lastMarkingReport && 
            room.housekeepingHistory?.some(h => moment(lastMarkingReport?.startDate) < moment(h.dateComplete))) {
            lastMarkingReport = undefined;
        }

        let cleaningRecord: HKReportExt =  {
            id: uuid(),
            startDate: startDate,
            reportDate: startDate,
            status: HKReportStatus.Initial,
            resident: { ...resident },
            staffMember: { ...state.staffMember },
            roomType: { ...roomType },
            room: {
                id: room.id,
                number: room.roomNumber
            },
            selectedCleanType: selectedRoutine?.cleanType ?? "",
            selectedGroup: undefined,
            selectedGroupTime: moment().format(DATETIME_FORMAT),
            facility: state.staffMember.facility!,
            auditMode: auditMarking,
            auditMarking: auditMarking,
            auditReport: auditMarking 
                ? {                    
                    markingDate: startDate,
                    auditor: {...state.staffMember},
                    fluorescentGelMethod: true
                }
                : lastMarkingReport?.auditReport
        };

        if(continueCleaning === true) {

            if(auditMarking) {
                if(!!lastMarkingReport) {               
                    cleaningRecord = {
                        ...cleaningRecord,
                        roomType: lastMarkingReport.roomType,
                        selectedCleanType: lastMarkingReport.roomType?.routines?.find((c) => true)?.cleanType ?? "",
                        auditReport: lastMarkingReport.auditReport,
                        startDate: lastMarkingReport.startDate,
                        facility: state.staffMember.facility!
                    }
                }
            }
            else {

                let lastReport = _.last(state.hkReports.filter(r => r.room.id === roomId))!;
                cleaningRecord = {
                    ...cleaningRecord,
                    roomType: lastReport.roomType,
                    selectedCleanType: lastReport.roomType?.routines?.find((c) => true)?.cleanType ?? "",
                    comment: lastReport.comment,
                    residentFamilyConcern: lastReport.residentFamilyConcern,
                    maintenance: lastReport.maintenance,
                    pestControl: lastReport.pestControl,
                    photos: lastReport.photos,
                    facility: state.staffMember.facility!,
                    auditReport: lastReport.auditReport
                }
            }
        }

        if(!!lastMarkingReport && !auditMarking) {
            cleaningRecord = {
                ...cleaningRecord,
                markingReport: {
                    id: lastMarkingReport.id,
                    startDate: lastMarkingReport.startDate,
                    cleanType: lastMarkingReport.roomType?.routines?.find((c) => true)?.cleanType ?? "",
                    auditor: `${lastMarkingReport.auditReport?.auditor?.firstName} ${lastMarkingReport.auditReport?.auditor.lastName}`,
                }
            };

            cleaningRecord = copyAuditMarking(cleaningRecord, lastMarkingReport)!;
        }

        newState.cleaningRecord = cleaningRecord
        newState.lastMarkingReport = lastMarkingReport
    }

    return newState
}

const copyAuditMarking = (cleaningRecord?: HKReportExt, markingReport?: HKAuditMarkingReport): HKReportExt | undefined => {
    
    const cleanType = markingReport?.roomType?.routines?.find((c) => true);
    if(!!cleaningRecord && !!markingReport && 
        cleaningRecord.selectedCleanType === cleanType?.cleanType) {
        
            return {
            ...cleaningRecord,
            roomType: {
                ...cleaningRecord.roomType,
                routines: cleaningRecord.roomType.routines
                    .map(r => r.cleanType !== cleaningRecord.selectedCleanType
                        ? r
                        : {
                            ...r,
                            operations: r.operations.map(o => {
                                return {
                                    ...o,
                                    isMarked: cleanType.operations.find(mo => mo.group === o.group && mo.item === o.item)?.isMarked
                                }
                            }),
                        })
            }
        }
    }

    return cleaningRecord;
}


const connectUpdateWatcher = (dispatch: HkDispatch): signalR.HubConnection => {
    const connection = new signalR.HubConnectionBuilder()
        .withUrl(`${process.env.REACT_APP_API_URI}/hubs/updates`, 
            { accessTokenFactory: async () => (await authProvider.getAccessToken()) ?? "" })
        .withAutomaticReconnect()
        .configureLogging(signalR.LogLevel.Error)
        .build();

    connection.on("UpdateHousekeepingReport", function (facilityId: string, reportId: string) {
        log.info('Housekeeping report requires update', facilityId);
        dispatch(performUpdate(facilityId, reportId));
    });
    
    
    connection.start().then(function () {        
        log.info('UpdatesHub connected.')
    }).catch(function (err) {                
        log.error('Failed to connect to UpdatesHub', err);
    });

    return connection;        
}

const stopUpdateWatcher = (connection: signalR.HubConnection): void => {    
    connection.stop().then(function () {
        log.info('UpdatesHub disconnected.')
    }).catch(function (err) {
        log.error('Failed to disconnect  UpdatesHub', err);
    });        
}

const getUpdates = (facilityId: string, reportId:  string) => {

    runWithProgress(i18n.t("Getting updates..."), () => {
        // TODO: implements query which gets only needed report
        store.dispatch(loadHousekeepingReports({ LatestRoomHistory: true, Status: 'Incomplete' })).then(r => {
            if (!loadHousekeepingReports.fulfilled.match(r)) {
                throw r.error;
            }

            return store.dispatch(getFacility(facilityId))
        })
        .then(r => {
            Swal.close()
        }).catch((error: any) => {
            Swal.fire({
                icon: 'error',
                title: 'Error connecting to server.',
                showConfirmButton: true
            });
    })},  { toast: true, position: 'top-end' });
   
}

const keepAuthAlive = () => {
    authProvider.getAccessToken().catch(e => console.error(e));
}

const cacheCleanTaskDetails = (operationImages: CleanOperationImage[]) => {

    operationImages.forEach((p) => {
        const request = `files/${p.image}`;

        return imageCache.getText(request)
            .then(r => {                    
                if(!!r) {
                    return r;
                } else {                        
                    apiService.request<any, any>(request, 'GET', undefined, {responseType: 'arraybuffer'})
                        .then((r: any) => {
                            const parts = p.image.split(".");
                            const dataUri = ImageUtils.binaryToDataUri(r.data, parts.length === 2 ? parts[1] : "jpeg");                                                                
                            imageCache.add(request, dataUri);
                        })
                        .catch(e => {
                            log.error("Couldn't load photo", p.image, e)
                        })
                }
            });
    });    
}

export default reducer
export type HkDispatch = React.Dispatch<React.ReducerAction<typeof reducer>>
