import * as React from 'react';
import { withFormik, FormikProps } from 'formik';
import * as Yup from 'yup';
import { withTranslation } from 'react-i18next';
import {Row, Col, Form, Card, CardGroup, Button} from 'react-bootstrap';
import { v4 as uuid } from 'uuid';
import * as actions from './reducer'
import { HKReport, HKPhoto } from '../../../store/models/domain';
import Image from '../../components/Image';
import ImageSelect from '../../components/ImageSelect';
import { authProvider } from "../../../common/auth/AuthProvider";


interface Props  {
    state: actions.CompState
    dispatch: actions.HkDispatch
    t: any,
    hideCategories?: boolean
}

export interface FormValues extends Partial<HKReport> {      
    readonly?: boolean
}

const CommentAndPhotosForm = (props: Props & FormikProps<FormValues>) => {
        const {
            // Formik HOC props
            values,
            errors,
            isSubmitting,
            handleSubmit,
            getFieldProps,
            setFieldValue,
    
            // Form props
            cancelLabel,
            saveLabel,
            onCancel,        
            canCancel,
            imageCache,
            onSendEmail,
    
            // Translate props
            t,
        }: any = props;
            
        const [selectedPhoto, setSelectedPhoto] = React.useState<HKPhoto | undefined>(undefined);
        
     
        function _handleTakePhoto (dataUri: any, type: string) {       
            const id = uuid();
            const newPhoto: HKPhoto = {
                id: id,
                photo: `Room_${values.room.number}_${uuid()}.jpeg`,
                dataUri: dataUri,
                createdDate: new Date().toJSON(),
                createdBy: authProvider?.getAccountInfo()?.staffMember?.name
            } 
            
            setFieldValue('photos', [...values.photos, newPhoto]);
        }

        const _deletePhoto = (photo: HKPhoto) => {        
            
            const photos = !photo.dataUri
                ? values.photos.map((p: HKPhoto) => p.id !== photo.id ? p : {...p, isDeleted: true})
                : values.photos.filter((p: HKPhoto) => p.id !== photo.id);

            setFieldValue('photos', photos);
        }
        
        
        const PhotoRenderItem: React.FunctionComponent<{photo: HKPhoto, onClick(photo: HKPhoto): void, onDelete(photo: HKPhoto): void }> = 
            ({ photo, onClick, onDelete }) => {                
    
            return (
                <Card key={"ph_" + photo.id} className="photoBox">
                    <Image dataUri={photo.dataUri ?? imageCache[photo.id]} srcFile={photo.photo} style={{width: '100%', height: '100%'}} onClick={() => onClick(photo)} title={t('Click to expand')} />
                    {values.readonly !== true &&<i className="fa fa-trash fa-lg deletePhotoButton" onClick = {() => onDelete(photo)} title={t('Delete Photo')} />}
                </Card>
            );
        };

        if(!!selectedPhoto) {
            return (<Image dataUri={selectedPhoto.dataUri} srcFile={selectedPhoto.photo} onClick={(e) => setSelectedPhoto(undefined)}
                style={{width: '100%', maxWidth: '100%', maxHeight: '100%'}} title={t('Click to close')}  />);
        }
        
    
        return (
            <Row className="justify-content-md-center">
                <Col sm={12} md={12}>                    
                    <Form onSubmit={(e) => { handleSubmit(e as React.FormEvent<HTMLFormElement>) }} className="p-1">
                        {!props.hideCategories &&
                        <><Form.Row className="mb-2"><Col><Form.Label className="pull-left">{t('Categories - select all applicable')}</Form.Label></Col></Form.Row>
                        <Form.Group>                        
                            <Row>
                                <Col sm={12} md={4}>
                                    <div className="checkbox d-inline checkbox-fill">
                                        <Form.Control type="checkbox" name="residentFamilyConcern" id="residentFamilyConcern" checked={values.residentFamilyConcern} 
                                            onChange={(e: any) => setFieldValue("residentFamilyConcern", e.target.checked)} 
                                            disabled={values.readonly} />
                                        <Form.Label htmlFor="residentFamilyConcern" className="cr">{t('resident, family concern')}</Form.Label>
                                    </div>
                                </Col>

                                <Col sm={12} md={4}>
                                    <div className="checkbox d-inline checkbox-fill">
                                        <Form.Control type="checkbox" name="maintenance" id="maintenance" checked={values.maintenance} 
                                            onChange={(e: any) => setFieldValue("maintenance", e.target.checked)} 
                                            disabled={values.readonly} />
                                        <Form.Label htmlFor="maintenance" className="cr">{t('maintenance')}</Form.Label>
                                    </div>
                                </Col>

                                <Col sm={12} md={4}>
                                    <div className="checkbox d-inline checkbox-fill">
                                        <Form.Control type="checkbox" name="pestControl" id="pestControl" checked={values.pestControl} 
                                            onChange={(e: any) => setFieldValue("pestControl", e.target.checked)}
                                            disabled={values.readonly} />
                                        <Form.Label htmlFor="pestControl" className="cr">{t('pest control')}</Form.Label>
                                    </div>
                                </Col>
                            </Row> 
                        </Form.Group></>}
    
                        <Form.Group>                            
                            <Form.Control as="textarea" rows={5} id="comment" readOnly={values.readonly}                             
                                {...getFieldProps('comment')} isInvalid={!!errors.comment} />
                            <Form.Control.Feedback type="invalid" className="text-left">{t(errors.comment)}</Form.Control.Feedback>
                        </Form.Group>                   
    
                        <Form.Group>
                            <div className="d-flex justify-content-start align-items-center mt-1 text-nowrap align-middle">                            
                                {values.readonly !== true &&<ImageSelect handleTakePhoto={_handleTakePhoto} dissableClear={true} dissableFileSelect={true} />}
                                {onSendEmail &&<Button className="mt-1" variant="primary" onClick={() => onSendEmail(values)}>{t('Email')}</Button>}
                            </div>
                            <CardGroup className="photoGallery">
                                {values.photos.filter((photo: HKPhoto) => photo.isDeleted !== true).map((photo: HKPhoto) => (
                                    <PhotoRenderItem key={"ph_" + photo.id} photo={photo} onClick={setSelectedPhoto} onDelete={(photo: HKPhoto) => _deletePhoto(photo)} />
                                ))}
                            </CardGroup>
                        </Form.Group>   

                      
                        {/* Mimik sweetalert2 buttons */}
                        <div className="swal2-actions" style={{'fontSize': '16px'}}>
                            <button className="swal2-confirm swal2-styled" type="submit" disabled={values.readonly} >{saveLabel}</button>
                            <button className="swal2-cancel swal2-styled" type="button" onClick={() => { onCancel(values) }} disabled={isSubmitting || !canCancel}>{cancelLabel}</button>
                        </div>   
                    </Form>
                </Col>
            </Row>
        );
    };
    
    const validate = withFormik<any, FormValues>({
        mapPropsToValues: props => ({
            id: props.values?.id,
            comment: props.values?.comment || '',
            residentFamilyConcern: props.values?.residentFamilyConcern,
            maintenance: props.values?.maintenance,
            pestControl: props.values?.pestControl,
            photos:  props.values?.photos || [],
            room: props.values?.room,
            readonly: props.values?.readonly,
            facility: props.values?.facility
        }),
    
        validationSchema: (props: any) => {
            return Yup.object().shape({               
        })},
    
        handleSubmit: (values, { props }) => {
            props.onSave(values);
        },
    });
    
    const translate = withTranslation();
    export default translate(validate(CommentAndPhotosForm));
