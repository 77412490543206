import * as React from 'react';
import { withFormik, FormikProps } from 'formik';
import * as Yup from 'yup';
import { withTranslation } from 'react-i18next';
import {Row, Col, Form, ButtonGroup, Button} from 'react-bootstrap';

import { Resident } from '../../../store/models/domain';
import Image from '../../components/Image';
import ResidentService from '../../../services/ResidentService';


export interface FormValues extends Partial<Resident> {   
    preCleanNote?: string,
    postCleanNote?: string
}

const ResidentNoteForm = (props: any & FormikProps<FormValues>) => {
    const {
        // Formik HOC props
        values,
        errors,
        isSubmitting,
        handleSubmit,
        getFieldProps,
        // Form props        
        submitLabel,
        onCancel,
        cancelLabel,        
        canCancel,
        showCancel,
        forPreClean,

        // Translate props
        t,
    }: any = props;


    return (
        <Row className="justify-content-md-center">
            <Col sm={12} md={9}>                              
                <Form onSubmit={(e) => { handleSubmit(e as React.FormEvent<HTMLFormElement>) }} className="p-1">

                    {!!forPreClean &&<><Form.Group>
                        <Form.Row><Col>
                            <Form.Label className="pull-left">{t('Resident') + ': '}&nbsp;<b>{ResidentService.displayName(values)}</b></Form.Label> 
                        </Col></Form.Row>                                                          
                    </Form.Group>

                    <Form.Group>                        
                        <Form.Row><Col><Image srcFile={values.photo} hideBorder={true} /></Col></Form.Row>                       
                    </Form.Group>

                   <Form.Group>
                        <Form.Label htmlFor="preCleanNote" className="pull-left">{t('Pre-Clean Note')}</Form.Label>
                        <Form.Control as="textarea" rows={6} id="preCleanNote" readOnly                             
                            {...getFieldProps('preCleanNote')} isInvalid={!!errors.preCleanNote} />
                        <Form.Control.Feedback type="invalid" className="text-left">{t(errors.preCleanNote)}</Form.Control.Feedback>
                    </Form.Group></>}

                    {!forPreClean &&<Form.Group>
                        <Form.Label htmlFor="postCleanNote" className="pull-left">{t('Post-Clean Note')}</Form.Label>
                        <Form.Control as="textarea" rows={6} id="postCleanNote" readOnly                            
                            {...getFieldProps('postCleanNote')} isInvalid={!!errors.postCleanNote} />
                        <Form.Control.Feedback type="invalid" className="text-left">{t(errors.postCleanNote)}</Form.Control.Feedback>
                    </Form.Group>}    

                    <ButtonGroup aria-label="Wizard Buttons">
                        {!!showCancel &&<Button variant="secondary" onClick={() => { onCancel(values) }} disabled={isSubmitting || !canCancel}>{cancelLabel}</Button>}
                        <Button variant="primary" type="submit" >{submitLabel}</Button>
                    </ButtonGroup>
                </Form>
            </Col>
        </Row>
    );
};

const validate = withFormik<any, FormValues>({
    mapPropsToValues: props => ({
        id: props.values?.id,
        namePrefix: props.values?.namePrefix || '',
        firstName: props.values?.firstName || '',
        lastName: props.values?.lastName || '',      
        nickName: props.values?.nickName || '',      
        photo:  props.values?.photo,        
        preCleanNote:  props.values?.preCleanNote,
        postCleanNote:  props.values?.postCleanNote        
    }),

    validationSchema: (props: any) => {
        return Yup.object().shape({            
    })},

    handleSubmit: (values, { props }) => {
        props.onSubmit(values);
    },
});

const translate = withTranslation();
export default translate(validate(ResidentNoteForm));
