import i18n, { InitOptions } from "i18next";
import { initReactI18next } from "react-i18next";
import LanguageDetector from "i18next-browser-languagedetector"
//import Backend from 'i18next-http-backend';
import moment from "moment";
import 'moment/locale/fr-ca'
import 'moment/locale/en-ca'
import 'moment/locale/es-us'


import setYupLocale from './yupLocale'
import languageEN from './en/common.json'
import languageENCustom from './en/custom_translation.json'
import languageFR from './fr/common.json'
import languageFRCustom from './fr/custom_translation.json'
import languageES from './es/common.json'
import languageESCustom from './es/custom_translation.json'
import languageZH from './zh/common.json'
import languageZHCustom from './zh/custom_translation.json'
import languageZHhK from './zh-HK/common.json'
import languageZHhKCustom from './zh-HK/custom_translation.json'

const allEn = {...languageEN, ...languageENCustom};
const allFr = {...languageFR, ...languageFRCustom};
const allSp = { ...languageES, ...languageESCustom };
const allZh = { ...languageZH, ...languageZHCustom };
const allZhHk = { ...languageZHhK, ...languageZHhKCustom };

setYupLocale();

const lookupLocalStorage = 'Beetons_i18nextLng';
if (!localStorage.getItem(lookupLocalStorage)) {

    let initialLang = 'en';
    if (navigator.language && navigator.language.startsWith('fr'))
        initialLang = 'fr';
    if (navigator.language && navigator.language.startsWith('es'))
        initialLang = 'es';
    if (navigator.language && navigator.language.startsWith('zh'))
        initialLang = 'hz';
    if (navigator.language && navigator.language.startsWith('zh-Hant'))
        initialLang = 'zh-Hant';

    localStorage.setItem(lookupLocalStorage, initialLang);
}

let settings: InitOptions = {
    // There is some problem with dynamic loading by backend so load all statically
    resources: {
        en: {
            translations: allEn
        },
        fr: {
            translations: allFr
        },
        es: {
            translations: allSp
        },
        zh: {
            translations: allZh
        },
        "zh-Hant": {
            translations: allZhHk
        }
    },
    load: 'currentOnly',
    /* default language when load the website in browser - dont use with languagedetector */
    //lng: "en",
    /* When react i18next not finding any language to as default in browser */
    fallbackLng: "en",
    /* debugger For Development environment */
    debug: false,
    ns: ["translations"],
    defaultNS: "translations",
    keySeparator: false,
    nsSeparator: false,
    interpolation: {
        escapeValue: false,
        formatSeparator: ","
    },
    react: {
        wait: true,
        bindI18n: 'languageChanged loaded',
        //bindStore: 'added removed',
        nsMode: 'default'
    },
    backend: {
        loadPath: '.locales/{{lng}}.json',
    },
    detection: {
        lookupLocalStorage: lookupLocalStorage,
    }
}

if (process.env.NODE_ENV === 'development') {
    settings['debug'] = true
}

i18n
    .use(initReactI18next) // passes i18n down to react-i18next
    //.use(Backend)
    .use(LanguageDetector)
    .use(initReactI18next)
    .init(settings);

const CustomLanguageHeader = "UI-Language";

export const getLanguageHeader = () : any => {
    return {
        [CustomLanguageHeader]: i18n.language
    };
}

function setMomentLocale(lang: string) {  
    if(lang === 'es') {
        moment.locale('es-us'); 
    } else if(lang === 'fr' || lang === 'en') {
        moment.locale(lang + '-ca'); 
    } else {
        moment.locale(lang); 
    }
}

setMomentLocale(i18n.language); 

export { setMomentLocale };
export default i18n;
