import * as React from 'react';
import { withTranslation, WithTranslation } from 'react-i18next';
import {Row, Col, Form} from 'react-bootstrap';
import _ from "lodash";
import moment from "moment";
import classnames from "classnames";
import { Provider } from 'react-redux';
import { Room, HousekeepingHistory, HKReportStatus, HKRoomType, HKRoomState, HKCleanType } from '../../../store/models/domain';
import Table, {ActionLink} from "../../components/Table";
import "./housekeeping.scss"
import RoomStateComponent from "../../components/room/RoomStateComponent"
import { authProvider } from "../../../common/auth/AuthProvider";


interface Props extends WithTranslation {
    room: Room
    roomTypes: HKRoomType[]
    onClose(): void
    onNewReport(room: Room): void
    onReportOpen(reportId: string): void,
    onAuditReport(reportId: string): void
    onAuditMarking(room: Room): void
    store?: any //Sweetforms renders above Provider so it must be re-wrapped
}

const RoomHistoryForm = (props: Props) => {
    const {
        room,
        roomTypes,
        onClose,
        onNewReport,
        onReportOpen,
        onAuditReport,
        onAuditMarking,

        //Store
        store,

        // Translate props
        t,
    } = props;

    const cleans = React.useMemo(() => {
        return _.orderBy(room.housekeepingHistory ?? [], c => c.dateComplete).reverse();
    }, [room.housekeepingHistory])


    const columns = React.useMemo(
        () => [                
            {                
                Header: t('Room Cleaning History'),
                accessor: (d: any) => d,
                Cell: ({ value }: {value: HousekeepingHistory}) => (
                    <div className="d-flex align-items-center flex-wrap justify-content-center">
                        <span className={classnames("text-truncated")} onClick={() => onReportOpen(value.reportId)} >
                            {`${value.cleanType}${value.status !== HKReportStatus.Complete ? (', ' + t(value.status ?? '')) : ''} (${moment(value.dateComplete).fromNow()})`}
                        </span>

                        <span className="ml-2">
                        {'('}<ActionLink className="swal2-content" onClick={() => onAuditReport(value.reportId)}>{t('Audit Cleaning')}</ActionLink>{')'}
                        </span>      
                    </div>
                ),                                 
                align: "center",
            }
        ],
        [onReportOpen, t]
    );
    
    return (
        <Provider store={store}>
            <div className="roomHistoryForm">

                <Form className="p-1">
                    <RoomStateComponent room={room} roomTypes={roomTypes} center />
                </Form>

                <Row className="justify-content-md-center">
                    <Col sm={12} md={9} className="p-3">                                            
                        <div className="swal2-actions" style={{'fontSize': '16px'}}>                                
                            <button className="swal2-confirm swal2-styled" type="button" onClick={() => onNewReport(room)}>{t('Start new cleaning report')}</button>
                            {cleans.length > 0 && <button className="swal2-confirm swal2-styled" type="button" onClick={() => onAuditReport(cleans[0].reportId)}>{t('Audit the last cleaning report')}</button>}
                            {authProvider.hasRole(authProvider.Roles.FacilityAssistant) &&<button className="swal2-confirm swal2-styled" type="button" onClick={() => onAuditMarking(room)}>{t('Start Pre-Audit Marking')}</button>}
                        </div>  
                    </Col> 
                </Row>

                <Row className="justify-content-md-center roomHistoryTable">
                    <Col sm={12} md={9}>                    
                    {cleans.length > 0 && <Table 
                                columns={columns} 
                                data={cleans}                                 
                                tableSize="sm"                                
                                disableSearch={true} />}
                        {cleans.length === 0 && <span className="text-muted">{t('There is no cleaning data for the room')}</span>}
                    </Col>
                </Row>

                <Row className="justify-content-md-center">
                    <Col sm={12} md={9}>                    
                        <Form className="p-1">
                            {/* Mimik sweetalert2 buttons */}
                            <div className="swal2-actions" style={{'fontSize': '16px'}}>                                
                                <button className="swal2-cancel swal2-styled" type="button" onClick={() => { onClose() }}>{t("Close")}</button>
                            </div>   
                        </Form>
                    </Col>
                </Row>
            </div>
        </Provider>
    );
};
    
const translate = withTranslation();
export default translate(RoomHistoryForm);
