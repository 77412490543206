import React, { PropsWithChildren, CSSProperties, ReactNode } from 'react'
import BTable from 'react-bootstrap/Table';
import { Row, Col, Form, Button } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import classnames from 'classnames';
import {
    Column,
    Row as TableRow,
    useSortBy,
    useTable,
    useGlobalFilter,
    useFlexLayout,
    useRowSelect,
    useAsyncDebounce,
    useFilters,
    useBlockLayout    
} from 'react-table';

interface ActionLinkProps {    
    onClick: () => void,
    className?: string
    disabled?: boolean
}

const ActionLink = ({ onClick, children, className, disabled }: PropsWithChildren<ActionLinkProps>) => {
    return (<Button variant="link" disabled={disabled} style={{ margin: 0, paddingTop: 0, paddingBottom: 0 }} className={classnames("px-1", className)} onClick={e => onClick()}>
        {children}
        </Button>);
}

// Define a default UI for filtering
const GlobalFilter = ({
    preGlobalFilteredRows,
    globalFilter,
    setGlobalFilter,
    searchPlaceholder    
}: any) => {
        const [value, setValue] = React.useState(globalFilter);
        const onChange = useAsyncDebounce(value => {
                setGlobalFilter(value || undefined);
            },
            200);

        const { t } = useTranslation();

        return (
            <span>
                <Form.Control type="text" className="form-control"
                            value={value || ""}
                            onChange={e => {
                        setValue(e.target.value);
                        onChange(e.target.value);
                    }}
                            placeholder={searchPlaceholder || t("Table.Search")}/>
            </span>
        );
}

export interface RowProps {
    row: TableRow<object>;
    index: Number
    rowEvent?: (event: string, args: any) => void
}

interface TableProps {
    columns: Column<any>[],
    data: any[],
    onAdd?: () => void,
    addContent?: () => ReactNode,
    tableSize?: "sm" | undefined,
    rowRender?: React.FC<RowProps & any>,
    rowEvent?: (event: string, args: any) => void,
    selectedRows?: any[],
    onSelectedRows?: (data: any) => void,
    disableSearch?: boolean,
    tableClass?: string,
    headerClass?: string,
    bodyClass?: string
    rowClass?: (index: Number, dataItem: any) => string,
    rowClick?: (index: Number, dataItem: any) => void,
    responsive?: boolean,
    blockLayout?: boolean,
    searchPlaceholder?: string
}

const INITIAL_SELECTED_ROW_IDS = {};

const Table = (
    { 
        columns, data, onAdd, addContent, tableSize, rowRender, rowEvent,
        selectedRows, onSelectedRows, disableSearch, 
        tableClass, headerClass, bodyClass, rowClass, rowClick,
        responsive = true, blockLayout = false, searchPlaceholder
    }: TableProps) => {
    const defaultColumn = React.useMemo(
        () => ({            
            minWidth: 0
        }),
        []
    );

    const sortBy = columns.filter(c => typeof c.sortDesc !== 'undefined').map(c => { return {
        id: c.id || c.accessor as string,
        desc: c.sortDesc,
        sortIndex: c.sortIndex
    }});
    sortBy.sort((a, b) => (a.sortIndex || 0) - (b.sortIndex || 0))
    
    const initialSelection = selectedRows ? Object.assign({}, data.map(r => selectedRows.indexOf(r) >= 0)) : INITIAL_SELECTED_ROW_IDS

    // Use the state and functions returned from useTable to build your UI
    const {
        getTableProps,
        getTableBodyProps,
        headerGroups,
        rows,
        prepareRow,
        state,
        preGlobalFilteredRows,
        setGlobalFilter,
        state: { selectedRowIds }
    } = useTable({
        columns,
        data,
        defaultColumn,
        initialState: {
            sortBy: sortBy,
            selectedRowIds: initialSelection
        }
    },
        useFilters,
        useGlobalFilter,
        useSortBy,
        (blockLayout ? useBlockLayout : useFlexLayout),
        useRowSelect
    );

    React.useEffect(() => {
        if (onSelectedRows) {
            const selectedIds = Object.keys(selectedRowIds).filter(x => selectedRowIds[x]);
            const selectedRowsData = selectedIds
                .map(x => data[+x])
                .filter(function (x) {
                    return x != null;
                });
            onSelectedRows(selectedRowsData);
        }
    }, [selectedRowIds, onSelectedRows, data]);

    const { t } = useTranslation();

    const DefaultRow: React.FC<RowProps> = ({ row, index }) => {
        return (<tr {...row.getRowProps()} 
            className={classnames((!!rowClass ? rowClass(index, row.original) : ""))} 
            onClick={(e) => { if(!!rowClick) rowClick(index, row.original); } }>
            {row.cells.map(cell => {
                const style = {
                    textAlign: cell.column.align ? cell.column.align : 'left ',
                    overflowX: responsive ? 'hidden' : 'visible',
                    textOverflow: 'ellipsis'
                } as CSSProperties;

                return (
                    <td {...cell.getCellProps((prop) => [prop, {style}])} >
                        {cell.render('Cell')}
                    </td>
                );
            })}
        </tr>)
    }

    const FinalRow = React.useMemo(() => rowRender || DefaultRow, [rowRender]);

    // Render the UI for your table
    return (
        <React.Fragment>
            <Row className="mb-2">
                {disableSearch !== true && <Col>
                    <GlobalFilter
                        preGlobalFilteredRows={preGlobalFilteredRows}
                        globalFilter={state.globalFilter}
                        setGlobalFilter={setGlobalFilter}
                        searchPlaceholder={searchPlaceholder}
                        />
                </Col>}
                <Col>
                    {onAdd && <Button variant="primary" className="pull-right" onClick={e => onAdd()}>{!!addContent ? addContent() : t('Table.Add')}</Button>}
                </Col>
            </Row>
            <Row>
                <Col>
                    <BTable striped bordered hover responsive={responsive} size={tableSize} {...getTableProps()} className={classnames(tableClass)} >
                        <thead>
                        {headerGroups.map(headerGroup => (
                            <tr {...headerGroup.getHeaderGroupProps()} className={classnames(headerClass)}>
                                {headerGroup.headers.map(column => {
                                    const style = {
                                        textAlign: column.align ? column.align : 'left ',
                                        overflow: 'hidden', 
                                        textOverflow: 'ellipsis'
                                    } as CSSProperties;
                                    return (
                                    <th {...column.getHeaderProps(column.getSortByToggleProps())}>
                                        <div style={style}>
                                            {column.render('Header')}
                                            <span className="ml-2">
                                                {column.isSorted
                                                ? column.isSortedDesc
                                                ? <i className="fa fa-arrow-down"/>
                                                : <i className="fa fa-arrow-up"/>
                                                : '  '}
                                                </span>                                               
                                        </div>
                                        <div className='m-1'>{column.canFilter && column.Filter ? column.render('Filter') : null}</div>                                        
                                    </th>
                                )})}
                            </tr>
                        ))}
                        </thead>
                        <tbody {...getTableBodyProps()} className={bodyClass}>
                        {rows.map((row: TableRow<object>, index: number) => {
                            prepareRow(row);
                            return (
                                <FinalRow row={row} index={index} key={index} rowEvent={rowEvent} />                                                                
                            );
                        })}
                        </tbody>
                    </BTable>
                </Col>
            </Row>
        </React.Fragment>
    );
}

const IndeterminateCheckbox = React.forwardRef(
    ({ indeterminate, ...rest }: any, ref) => {
        const defaultRef = React.useRef()
        const resolvedRef: any = ref || defaultRef

        React.useEffect(() => {
            resolvedRef.current.indeterminate = indeterminate
        }, [resolvedRef, indeterminate])

        return (
            <>
                <input type="checkbox" ref={resolvedRef} {...rest} />
            </>
        )
    }
)

export { IndeterminateCheckbox }
export { ActionLink };
export default Table;   
