import { SerializedError } from '@reduxjs/toolkit'


export interface SyncState {
    dataSources: Array<SyncDataSource>
    loading: 'idle' | 'pending' | 'succeeded' | 'failed'
    error: SerializedError | null
}


export enum SyncAuthType {
    OAuth2 = "OAuth2",
    TwoLegged = "TwoLegged"
}

export enum SyncAppType {
    PointClickCare = "PointClickCare"
}

export interface SyncDataSource {
    id: string;
    facility?: {
        id: string,
        name: string
    };
    name: string;
    syncAppType: SyncAppType;
    syncAuthType: SyncAuthType;
    syncAppSettings: string;
    enableSynchronization?: boolean;
    syncFacilityId?: string;
}

export interface SyncFacilityId {
    orgUuid: string;
    facId: string;
    orgName?: string;
    facName?: string;
}

export interface SyncDataSourceWithFacility extends SyncDataSource {
    syncFacilityRef?: SyncFacilityId;
}

export interface ApiQuotaInfo {
    remaining: number;
    limit: number;
    isExceeded: boolean;
    resetTimeUtc?: string;
}

export interface SyncFacility {
    id: string;
    name: string;
    facilityCode: string;
    orgName: string;
    orgUuid: string;
    timeZone: string;
}
export interface SyncFacilitiesResponse {
    facilities: SyncFacility[];
    apiQuotaInfo: ApiQuotaInfo
} 

export interface SyncTokenDes {
    id: string;
}

export interface OrganizationDes {
    orgUuid: string;
}

export interface OrganizationRequest {
    syncSourceId?: string;
    code: string;
    callbackUrl: string;
    defaultSource?: boolean;
    syncAppType?: SyncAppType;
}

export interface PointClickCareSettings {
    applicationName?: string;
    orgUuid: string;
}

export interface SaveFacilityDataSource {
    dataSource: SyncDataSource;
    syncFacilityId: SyncFacilityId;
} 